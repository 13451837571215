import React, { useEffect, useState } from 'react'
import ls from 'localstorage-slim'
import { useIdleTimer } from "react-idle-timer";

import { BrandAside } from '../components/brand';
import instance from '../helpers/axios';
import { useTypedSelector } from '../hooks/useSelector';
import { useDispatch } from 'react-redux';
import { ActionType } from '../store/actions';
interface LayoutProps {
  Element: React.ReactNode;
}

const BrandLayout: React.FC<LayoutProps> = ({ Element }) => {
  const [state, setState] = useState("Active");
  const [current, setCurrent] = useState(0)
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const dispatch = useDispatch();

  const { loggedInBrand, loggedIn } = useTypedSelector((state) => state.appReducer);

  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 600000,
    throttle: 500,
  });


  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    const timeSpentInterval = setInterval(async () => {
      setCurrent(current + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
      clearInterval(timeSpentInterval);
    };
  });


  useEffect(() => {
    if (current == 600) {
      setCurrent(0);
      let token = ls.get("refreshToken", { decrypt: true }) || null;

      if (!token) {
        return;
      }
       const headers = {
        'refresh_token': `${token}`,
      }
      console.log(token)

      instance({
        method: "GET",
        url: `/auth/refresh`,
        headers: headers
      }).then((response) => {
        var brand = response.data.data.token;

        ls.set("refreshToken", response.data.data.refresh_token, {encrypt : true})
        dispatch({
          type: ActionType.LOGIN_BRAND,
          payload: brand
        })

      }).catch((err) => {

      })

    }
  }, [current]);
  return (
    <div className='flex relative font-primary bg-[#F0F0F4] min-h-[100vh] p-0 m-0 w-full'>
      <BrandAside />
      <div className='flex-1 w-full'>
        {Element}
      </div>
    </div>
  )
}

export default BrandLayout


