import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import { slugify } from "../helpers/helpers";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";

export function Qr({ qrCode, brandName, source = 1, title = "", description = "" }: any) {
    const [isDownload, setIsDownload] = useState(false);

    const downloadQrCode = (id: string) => {
        setIsDownload(true)
        var input = document.getElementById(id);
        html2canvas(input!)
            .then((canvas) => {
                var imgData = canvas.toDataURL('image/jpg');
                console.log(imgData)
                const a = document.createElement("a")
                a.href = imgData
                a.download = `${id}.jpg`
                a.click()
                setIsDownload(false)
            })
    }

    return (
        <>
            <div className={`text-center ${source == 1 ? "w-[100px]" : "w-[380px]"}`}>
                <div
                    id={`dowload${qrCode}`}
                    className='qrcode bg-white'>
                    <div className="text-center mb-3">
                        {source == 2 && <><h1 className="text-blue font-bold text-[20px]">{title}</h1>
                            <p>{description}</p></>}
                    </div>
                    <div className="mx-auto w-full text-center flex justify-center">
                        <QRCode
                            style={{ height: "auto", maxWidth: "300px", width: "300px" }}
                            viewBox={`0 0 256 256`}
                            value={`https://${window.location.host}/${slugify(brandName)}/${qrCode}`} />
                    </div>
                    {source ==2 && <div className="h-[15px]"></div>}
                </div>
                <small>
                    <a onClick={() => {
                        if (source > 1) downloadQrCode(`dowload${qrCode}`)
                    }
                    } href='#?' >Download</a>
                </small>
               
            </div>

        </>
    )
}