import React from 'react'

import { Carousel } from 'flowbite-react';

// Import Swiper styles
import 'swiper/css';
import { Logo5 } from '../images';
function InfluencerSuccess() {
  const success = [
    {
      name: "Emily Turner",
      description: "PetPals, a pet care brand, turned to Beentos to strengthen its connection with pet-loving communities. Through heartwarming collaborations with pet influencers, PetPals witnessed a 35% rise in brand loyalty and engagement. Beentos' platform enabled PetPals to tap into niche audiences, fostering genuine connections and transforming the brand into a beloved name among pet owners.",
      post: "Founder and CEO as PetPals"

    },
    {
      name: "Alex Johnson",
      description: "echTrend, a cutting-edge technology brand, joined forces with Beentos to launch its latest innovation. Beentos facilitated collaborations with tech-savvy influencers, resulting in a 25% increase in pre-orders and a widespread buzz across social media platforms. The strategic influencer partnerships not only showcased TechTrend's product features but also positioned the brand as a leader in the tech industry."
      ,
      post: "Chief Innovation Officer at echTrend"
    },
    {
      name: "Michelle Chen",
      description: "itFuel, a fitness and nutrition brand, partnered with Beentos to promote its new product line. Through targeted collaborations with fitness influencers, FitFuel saw a 40% rise in product awareness and a surge in customer testimonials. The influencer marketing strategy not only drove sales but also positioned FitFuel as a go-to brand for health enthusiasts seeking effective and reputable fitness solutions.",
      post: " Head of Marketing at itFuel"

    },
    {
      name: "David Thompson",
      description: "EcoEssentials, a sustainable lifestyle brand, leveraged Beentos to amplify its message of environmental consciousness. Collaborating with influencers passionate about eco-friendly living, EcoEssentials achieved a 50% increase in social media engagement and a notable rise in sales. Beentos helped connect the brand with influencers who shared its values, resulting in a successful campaign that resonated with a global audience.",
      post: "CEO at EcoEssentials"
    },
    {
      name: "Anna Rodriguez",
      description: "LuxeFits, a high-end fashion brand, partnered with Beentos to enhance its online presence. Through strategic influencer collaborations facilitated by Beentos, LuxeFits experienced a 30% increase in online sales and a significant boost in brand visibility. Influencers showcased the brand's products in authentic, engaging ways, reaching a wider audience and establishing LuxeFits as a trendsetter in the fashion industry.",
      post: "Marketing Director at LuxeFits"
    }

  ]
  return (
    <div className='py-[60px] bg-blue' id='SuccessStories_section'>
      <div className="container mx-auto">
        <div className="text-center mb-2">
          <h3 className='text-[36px] font-primarybold text-graylight'>Brands Success Stories</h3>
        </div>
        <br />
        <div className="h-96" style={{ minHeight: "70vh" }}>
          <Carousel  >
            {
              success.map((item, i) => (
                <section className="p-6">
                  <div className="container w-[820px] max-w-[95%]  mx-auto">
                    <div className="flex flex-col items-center w-full p-6 space-y-8 rounded-md lg:h-full lg:p-8 bg-graylight dark:bg-gray-900 dark:text-gray-100">
                      <img src="https://source.unsplash.com/random/100x100?4" alt="" className="w-20 h-20 rounded-full dark:bg-gray-500" />
                      <blockquote className="max-w-lg text-lg italic font-medium text-center">"{item.description}"</blockquote>
                      <div className="text-center dark:text-gray-400">
                        <p>{item.name}</p>
                        <p>{item.post}</p>
                      </div>
                    </div>
                  </div>
                </section>
              ))
            }


          </Carousel>
        </div>

      </div>
    </div>
  )
}

export default InfluencerSuccess