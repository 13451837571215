import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../../helpers/pageTitle";
import { useTypedSelector } from "../../../hooks/useSelector";
import { ActionType } from "../../../store/actions";
import { toast, ToastContainer } from "react-toastify";
import Input from "../../../components/input";
import Button from "../../../components/button";
import {
  challengeTypes,
  rewardTypes,
  votingCategories,
  locations,
} from "../../../data";
import { Choose } from "../../../components/brand";
import {
  capitalizeFirstLetter,
  getUserLocation,
  position,
  sharePrize,
} from "../../../helpers/helpers";
import instance from "../../../helpers/axios";
import DropDown from "../../../components/dropdown";
import { BiPencil } from "react-icons/bi";
import ls from "localstorage-slim";
import { Divider } from "rsuite";

const CreateChallenge: React.FC = () => {
  const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [showTitleErr, setShowTitleErr] = useState(false);
  const [titleERr, setTitleERr] = useState("");

  const [loading, setLoading] = useState(false);
  const [budget, setBudget] = useState("");

  const [location_, setLocation] = useState("");
  const [locationErr, setLocationErr] = useState("");
  const [showLocationErr, setShowLocationErr] = useState(false);

  const [winner, setWinner] = useState("");
  const [maxLength, setMaxLength] = useState("");
  const [category, setCategory] = useState("");
  const { challengeData }: any = useTypedSelector((state) => state.appReducer);
  const [ctype, setCtype] = useState("");
  const [showCtypeErr, setShowCtypeErr] = useState(false);
  const [ctypeErr, setCtypeErr] = useState("");

  const [valid, setValid] = useState("");
  const [reward, setReward] = useState<any[]>([]);
  const [backgroundMusic, setBackgroundMusic] = useState("");
  const [description, setDescription] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [shares, setShares] = useState<any[]>([]);
  const [remaining, setRemaining] = useState(0);
  const [criteria, setCriteria] = useState("");
  const [budgetErr, setBudgetErr] = useState("");
  const [showBudgetErr, setShowBudgetErr] = useState(false);
  const [isBudget, setIsBudget] = useState(true);
  const [postBUdget, setPostBUdget] = useState(0);
  const [userloc, setUserloc] = useState<any[]>([]);
  const [userrate, setUserrate] = useState(1);
  const [rewardValues, setRewardValues] = useState("");
  const [prizeDisabled, setPrizeDisabled] = useState(false);
  const [rewardErr, setRewardErr] = useState("");
  const [showRewardErr, setShowRewardErr] = useState(false);
  const [Coupon, setCoupon] = useState(false);
  const [currency, setCurrency] = useState("")
  const [prizeNarration, setPrizeNarration] = useState("")

  useEffect(() => {
    if (!loggedInBrand || loggedInBrand === "") {
      // nobody is logged in
      navigate("/brand/login");
    }
  }, [loggedInBrand, navigate]);

  useEffect(() => {
    dispatch({
      type: ActionType.UPDATE_LAYOUT,
      payload: "brand",
    });
  }, []);

  const { pathname, search } = useLocation();
  const [challengeId, setChallengeId] = useState("");
  const [challenge, setChallenge] = useState<any>({});
  const [userSub, setUserSub] = useState<any>({});
  const [guidelinesArray, setGuidelinesArray] = useState<any[]>([]);

  const setDistribution = (bud: number, win: number) => {
    // setMaxLength();
    const shares_ = sharePrize(bud, Number(win), 3);
    var remaining_ = Number(
      Number(bud) -
      shares_.reduce(function (a, b) {
        return a + b;
      }, 0)
    ).toFixed(2);
    setShares(shares_);
    setRemaining(Number(remaining_));
  };
  useEffect(() => {
    // get sub
    var sub = ls.get("bsub", { decrypt: true });
    setUserSub(sub ? sub : {});
    console.log("s", sub);
  }, []);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${loggedInBrand}`,
    };
    setLoading(true);
    const search_ = search.replace("?", "").split("&");
    if (search_.length > 0) {
      search_.forEach((s) => {
        if (s.includes("challenge=") !== false) {
          const cha = s.replace("challenge=", "");
          setChallengeId(cha);
          instance({
            method: "GET",
            url: `/challenge/draft/single/${cha}`,
            headers: headers,
          })
            .then(async (response) => {
              var cha = response.data.data;
              setChallenge(cha);
              setLocation(
                cha.audienceLocation ? cha.audienceLocation : "Select"
              );
              setCtype(
                cha.challengeType
                  ? capitalizeFirstLetter(cha.challengeType)
                  : "Select"
              );
              setWinner(cha.noOfWinners);
              setBudget(cha.totalPrizeBudget);
              setValid(cha.closingDate);
              setTitle(cha.title);
              setPrizeNarration(cha.prizeNarration)
              if (cha.currency) setCurrency(cha.currency)
              // window.alert(cha.challenge_visibility)
              const bud = cha.totalPrizeBudget;
              const win = cha.noOfWinners;

              let userLoc_: any[] =
                ls.get("blocation", { decrypt: true }) ?? [];
              let usrate: any = ls.get("busrate", { decrypt: true });
              if (!userLoc_ || userLoc_.length === 0) {
                try {
                  setLoading(true);
                  userLoc_ = await getUserLocation();
                  ls.set("blocation", userLoc_, { encrypt: true });
                } catch (error) {
                  console.log("Error fetching rate", error);
                }
              }

              if (!usrate) {
                try {
                  setLoading(true);
                  const response = await instance({
                    method: "GET",
                    url: `payment/rate/${userLoc_[3]}`,
                    headers: headers,
                  });
                  usrate = response.data.data.rate;
                  ls.set("busrate", usrate, { encrypt: true });
                } catch (error: any) {
                  console.log("Error getting exchange rate", error.message);
                }
              }
              setLoading(false);

              //setUserloc(userLoc_);
              setUserloc(userLoc_);

              if (userLoc_[1] !== "US") {
                setDistribution(Number(bud) * usrate, win);
                setUserrate(usrate);
              } else {
                setDistribution(Number(bud), win);
              }

              //set rate from user location

              setDescription(cha.description);
              setCriteria(cha.judgingCriteria);

              if (cha.winnerSelectionType) {
                var sel = cha.winnerSelectionType;
                if (sel.length == 1) {
                  setCategory("Audience Choice");
                } else {
                  setCategory("Brand Choice");
                }
              }
              setBackgroundMusic(cha.backgroundMusic);

              const r = cha.rewardType;
              var r_value = "";
              r.forEach((element: any) => {
                if (r_value === "") {
                  r_value = element;
                } else {
                  r_value = r_value + "," + element;
                }
              });

              if (!r_value.includes("cash")) {
                setIsBudget(false);
              }
              if (cha.rewardType) {
                setReward(cha.rewardType);
                var rewar = "";
                cha.rewardType.forEach((r: string) => {
                  rewar =
                    rewar === ""
                      ? capitalizeFirstLetter(r)
                      : `${capitalizeFirstLetter(r)},`;
                });
                setRewardValues(rewar);
              }

              // setCriteria(cha.guidelines);
              setGuidelinesArray(cha.guidelines.split("~end-of-a-step~"));
            })
            .catch((err) => {
              if (err.code == "ERR_NETWORK") {
                alert("No internet connection");
              }
              if (err.response.data.statusCode === 403) {
                navigate("/brand/login");
                return false;
              }

              toast.error(err.response.data.message);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      });
    } else {
      navigate("/brand/dashboard");
    }
  }, []);
  useEffect(() => {
    // if (!challengeData) {
    //     navigate("/brand/dashboard");
    // } else {
    //     var data = JSON.parse(challengeData);
    //     const r = data.rewardType[0]
    //     var r_value = ""
    //     r.forEach((element: any) => {
    //         if (r_value === "") {
    //             r_value = element.value;
    //         } else {
    //             r_value = r_value + "," + element.value;
    //         }
    //     });
    //     setReward(r_value);
    //     if(r.length === 1){
    //         if(r[0].label === "Deal"){
    //             setIsBudget(false)
    //         }
    //     }
    // }
  }, [challengeData]);
  const setChallengeTitle = (value: string) => {
    if (value.length <= 40) {
      setTitle(value);
    } else {
      setShowTitleErr(true);
      setTitleERr("Tittle cannot be more than 40 characters");
    }
  };

  const updateBudget = (value: string) => {
    setShowBudgetErr(false);
    if (value == "") {
      setBudget("");
      setPostBUdget(0);
      return;
    }
    var val = parseInt(value);

    if (userloc[1] != "US") {
      setBudget(value);
      setPostBUdget(parseFloat((val / userrate).toFixed(4)));
    } else {
      setPostBUdget(val);
      setBudget(value);
    }
  };
  useDocumentTitle("CREATE CHALLENGE ");

  const proceed = () => {
    var ispassed = true;
    // setLoading(true)
    if (title == "") {
      setShowTitleErr(true);
      setTitleERr("Title cannot be empty");
      ispassed = false;
    }

    // return false;

    setLoading(true);
    setIsSaved(true);
    // navigate("/brand/challenge/create-step-payment");
    setLoading(false);
  };
  const handleInputChange2 = (inputValue: any, actionMeta: any) => {
    // console.log("handleInputChange", inputValue, actionMeta);
    // setChallengeCat(inputvalu)
  };
  const editReward = () => {
    setReward([]);
    setRewardValues("");
    setPrizeDisabled(false);
    setShowBudgetErr(false);
    setBudgetErr("");
    setBudget("");
  };
  const handleChange2 = (selectedOption: any, actionMeta: any) => {
    if (selectedOption.length === 1) {
      if (selectedOption[0].label === "Deal") {
        setPrizeDisabled(true);
        setShowBudgetErr(true);
        setBudget("");
        setBudgetErr(
          "This field is not availble when Reward Type is Deal only"
        );
      } else {
        setPrizeDisabled(false);
      }
    } else {
      setPrizeDisabled(false);
    }
    setReward(selectedOption);
  };
  const unProceed = () => {
    setIsSaved(false);
  };

  // getcoupon
  useEffect(() => {
    var c: any = ls.get("bcoupon", { decrypt: true });
    var couponType = c?.couponType;
    if (c?.isValid) {
      if (couponType?.includes("challenge")) {
        if (c?.maxNoOfChallenges > c?.noOfChallenges) {
          setCoupon(true);
          toast.success("Hurray, You have a valid coupon!");
        }
      }
    }
  }, []);

  // const a = []
  return (
    <div className="px-[5px] py-[50px]">
      <div className="container mx-auto">
        <div className="flex items-center justify-between">
          <div className="text-blue font-primarybold text-[18px]">
            Preview Campaign
          </div>
          <Link
            to={`/brand/challenge/create-step-3?challenge=${challenge._id}`}
            className="text-blue flex items-center gap-2"
          >
            {" "}
            <BiPencil /> <span className="hidden lg:flex">Edit</span>
          </Link>
        </div>
        <ToastContainer />
        {isSaved ? (
          <Choose
            isBudget={isBudget}
            // hasSub="paid"
            hasSub={
              Coupon
                ? "paid"
                : userSub.paymentStatus
                  ? userSub.paymentStatus
                  : "pending"
            }
            challenge={challengeId}
            close={unProceed}
          />
        ) : (
          ""
        )}
        <div className="py-[20px] bg-white shadow-lg rounded ">
          <div className="text-censter font-primary">
            <div className="mt-3 w-full">
              <h3 className="text-blue  text-center text-[24px] m-0 font-bold font-primary">
                One last check!
              </h3>
              <p className="text-center text-gray2 mt-4">
                Take one more glance at the information you’ve provided and edit
                where neccessary
              </p>
            </div>
            <div className="mt-4 w-[80%] mx-auto">
              <div className="my-2">
                <label className="font-primary text-blue" htmlFor="">
                  What will you call this campaign?
                </label>
                <Input
                  isDisabled={true}
                  showError={showTitleErr}
                  err={titleERr}
                  value={title}
                  setValue={setTitle}
                  containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                  placeholder="Title"
                />
              </div>
              <div className="my-2 mt-4">
                <label className="font-primary text-blue mb-0" htmlFor="">
                  Tell People more about your campaign
                </label>
                {/* <Input isDisabled={true} err='' textareaH={"100px"} placeholder="Give more details or additional instructions" type='textarea' value={description} setValue={setDescription} containerClass="bg-[#F0F0F4] p-3 border-graylight border-0 shadow-0" /> */}
                <div className="min-h-[100px] rounded font-600 text-[14px] text-blue p-3 mt-[12px] bg-[#F0F0F4] border-graylight border-0">
                  {description}
                </div>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Challenge type
                  </label>
                  {/* <Input isDisabled pos='top-[50%]' posErr='top-[30%]' prevErr={false} showError={showCtypeErr} err={ctypeErr} isDropDown={true} items={challengeTypes} placeholder="Select and option" value={ctype} setValue={setCtype} containerClass="mt-[4px] border-graylight border-0 shadow-0 mt-0 bg-[#F0F0F4]" /> */}
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={ctype}
                    setValue={setCtype}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div>
                {/* <div className="my-2">
                                    <label className='font-primary text-blue' htmlFor="">Reward Type</label>
                                    <Input pos='top-[40%]' posErr='top-[30%]' prevErr={false} isDropDown={true} items={rewardTypes} placeholder="Selec an option" type="readonly" value={reward} setValue={setReward} containerClass="mt-[0px] border-graylight border-0 shadow-0 mt-0 bg-[#F0F0F4]" />
                                </div> */}
                <div className="my-2">
                  <label
                    className="text-blue flex items-center gap-3 justify-between"
                    htmlFor=""
                  >
                    {" "}
                    <span>Reward type*</span>{" "}
                  </label>
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={rewardValues}
                    setValue={setRewardValues}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />

                  {/* {
                                        rewardValues === "" ?
                                        <DropDown showError={showRewardErr} err={rewardErr} isMulti={true} options={rewardTypes} handleChange={handleChange2} handleInputChange={handleInputChange2} />
                                        :
                                        <div >
                                            <Input isDisabled={true} showError={false} err={""} placeholder="" items={challengeTypes} value={rewardValues} setValue={()=>{}} containerClass="border-graylight border-2 shadow-sm mt-[5px] bg-[#F0F0F4]" />
                                        </div>
                                    } */}
                </div>
              </div>
              {isBudget ? (
                <div className="my-[15px]">
                  <p className="font-bold text-blue font-primary">
                    Distribution
                  </p>
                  <div className="mt-3 p-2 text-blue bg-[#F0F0F4] text-sm shadow-0 shadow-t rounded-[10px]">
                    <p>{challenge.prizeNarration}</p>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Winner determination*
                  </label>
                  {/* <Input pos='top-[40%]' posErr='top-[30%]' prevErr={false} isDropDown={true} items={votingCategories} placeholder="Selec an option" type="text" value={category} setValue={setCategory} containerClass="mt-[0px] border-graylight border-0 shadow-0 mt-0 bg-[#F0F0F4]" />
                   */}
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={category}
                    setValue={setCategory}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div>

                {/* <div className="my-2 hidden">
                  <label className="text-blue" htmlFor="">
                    Audience Location*
                  </label>
                  <Input pos='top-[40%]' posErr='top-[30%]' prevErr={false} showError={showLocationErr} err={locationErr} isDropDown={true} items={locations} placeholder="Select and option" type="text" value={location_} setValue={setLocation} containerClass="mt-[0px] border-graylight border-0 shadow-0 mt-0 bg-[#F0F0F4]" />
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={location_}
                    setValue={setLocation}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div> */}

                {/* <div className="my-2">
                                    <label className='font-primary text-blue mb-2' htmlFor="">Total Budget</label>
                                    <Input value={budget} setValue={setBudget} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 border-0" placeholder='Title' />
                                </div> */}

                {/* <div className="my-2">
                                    <label className='font-primary text-blue' htmlFor="">Numbers of winners</label>
                                    <Input value={winner} setValue={setWinner} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 border-0" placeholder='Title' />
                                </div> */}

                <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Valid Through
                  </label>
                  {/* <Input value={valid} type="date" setValue={setValid} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 border-0" placeholder='12/12/2024' /> */}
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={valid}
                    setValue={setValid}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div>

                <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Music
                  </label>
                  {/* <Input value={backgroundMusic} setValue={setBackgroundMusic} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 border-0" placeholder='Select a file' /> */}
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={backgroundMusic}
                    setValue={setBackgroundMusic}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div>

                <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Prize Narration
                  </label>
                  {/* <Input value={backgroundMusic} setValue={setBackgroundMusic} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 border-0" placeholder='Select a file' /> */}
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={prizeNarration}
                    setValue={setBackgroundMusic}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div>


                {currency && <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Prize Narration
                  </label>
                  {/* <Input value={backgroundMusic} setValue={setBackgroundMusic} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-0 border-0" placeholder='Select a file' /> */}
                  <Input
                    isDisabled={true}
                    showError={showTitleErr}
                    err={titleERr}
                    value={currency}
                    setValue={setBackgroundMusic}
                    containerClass="mt-[4px] h-[50px] bg-[#F0F0F4] border-graylight shadow-0 shadow-none border-0"
                    placeholder="Title"
                  />
                </div>}
              </div>

              <div className="my-2">
                <label className="font-primary text-blue" htmlFor="">
                  Guidelines
                </label>
                <div className="mt-[12px] bg-[#F0F0F4] border-graylight border-2 p-3 rounded-[10px] shadow-sm">
                  {guidelinesArray.map((item, i) => (
                    <>
                      <div className="text-blue font-bold flex gap-3" key={i}>
                        <div className="flex-initial w-full">
                          <p>{item}</p>
                        </div>
                        <div className="flex-initial w-[80px]"></div>
                      </div>
                      <Divider />
                    </>
                  ))}
                </div>
              </div>
              <div className="justify-center flex gap-4 flex-col lg:flex-row">
                <Button
                  loading={loading}
                  text="Looks Good"
                  onclick={() => proceed()}
                  containerClass="w-full max-w-[250px] bg-blue text-yellow mt-[30px]"
                />

                <Button
                  loading={false}
                  text="Save and exit"
                  onclick={() => navigate("/brand/dashboard")}
                  containerClass="w-full max-w-[250px] bg-yellow text-blue mt-[30px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateChallenge;
