import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import Button from '../../components/button';
import { checkBox, checkBoxChecked, exporticon, who } from '../../components/images';
import { graph1, graph2, trendup } from '../../components/super_images';
import { CChart } from '@coreui/react-chartjs';
import { BsCaretDownSquare, BsCircleFill } from 'react-icons/bs';
import { BiCaretDown, BiDotsVertical } from 'react-icons/bi';
import { AiOutlineCheckSquare, AiOutlineEye } from 'react-icons/ai';
import { formatCreatedAtDate, formatDate } from '../../helpers/helpers';
import { CoreDropdown } from '../../components';
import { resultSort } from '../../data';
import { FiSearch } from 'react-icons/fi';
import AppLink from '../../components/link';
import Pagination from '../../components/pagination';
import { RxCaretLeft, RxCaretRight } from 'react-icons/rx';
// import { ShareModal } from '../../components/brand';

const Users: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const [selected, setSelected] = useState<number[]>([])
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)
    const [c, setC] = useState([])
    const [search_, setSearch_] = useState("");
    const [allList, setAllList] = useState<any[]>([])
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const [dropdown_, setDropDown] = useState("")
    const [allRecords, setAllRecords] = useState(0)
    const [sort, setSort] = useState("All")
    const [page, setPage] = useState(1)
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    const chartlegends = [
        { title: "Running", className: "text-[#0B0149]" },
        { title: "Not Running", className: "text-[#0B01491F]" },
    ]
    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin == "") {
            // nobody is logged in
            navigate("/admin/login");
        }
    }, [loggedInAdmin])
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        setIsLoading(true)
        instance({
            method: "GET",
            url: `/user/all/admin?_page=${page}`,
            headers: headers
        }).then((response) => {
            console.log("response", response)
            var docs = response.data.data.docs;
            setAllList(docs);
            setC(docs);
        setIsLoading(false)

            setAllRecords(response.data.data.totalDocs)
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false);
        })
    }, [page])
    
    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if(search_.length > 0){
          search_.forEach(s => {
            if(s.includes('page=') !== false){
              const scrollTo = s.replace('page=','')
                setPage(parseInt(scrollTo))
              return false;
            }
          });
        }
    }, [search])

    const addSelected = (value: number) => {
        var selected_ = selected;
        if (selected_.includes(value)) {
            selected_.splice(selected_.indexOf(value), 1)
        } else {
            selected_.push(value)
        }
        setSelected(selected_);
        setTimeout(() => {
            setAllList(c)
        }, 500);
    }


    // dropdownchanged
    function dropChanged (value : any){
        setPage(1)
        setSort(value)
    }
    // search
    const searchChanged = (value : string) => {
        if(value === "" || value === undefined || value === null) {
            setSearch_("")
            return setAllList(c)
        };
    //   console.log("value", value)
      setSearch_(value)
      var filtered:any[] = [];
      allList.map((list : any) => {
            var fname:string = list.userName;
            if((fname.toLowerCase()).includes(value.toLowerCase())) filtered = [...filtered, list];
      })

      setAllList(filtered)
    
    }
    useDocumentTitle('ADMIN - Users')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px] font-primary">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                            <div className="search flex justify-between flex-col lg:flex-row items-center mb-4">
                                <h3 className="font-primaary font-bold text-blue">Manage Users</h3>
                                <AppLink href='/admin/users/team-members'  text='Team Members' containerClass='bg-blue text-yellow w-[200px]' />
                            </div>
                            {/* Top voted users */}
                            <div className="py-[15px] px-3 rounded shadow bg-white " style={{overflow : 'scroll'}}>
                                    <div className="flex flex-col items-center lg:flex-row justify-between">
                                        {/* filter button */}
                                        <div className="flex-initial w-full max-w-[150px]">
                                            <CoreDropdown value={"Sort : " + sort} placeholder='Sort : All' extendedClasses='dark:text-white text-yellow' containerClass="flex justify-center w-[full] h-[45px] border-graylight border-2 shadow-sm text-[0.8rem] mt-0 bg-blue text-yellow" showError={false} err={""}  items={resultSort} setValue={(value:any)=> dropChanged(value)} />                                    
                                        </div>

                                        {/* export and search */}
                                        <div className="flex gap-4">
                                            {/* search */}
                                            <div className={`flex items-center control border border-blue pl-2 h-[45px] rounded-[45px] w-[250px] mt-[5px] bg-[#F0F0F4] border-graylight shadow-sm border-2`}>
                                                <FiSearch className='text-[22px] text-[#7E799F]' /> <div className="mr-1"></div>
                                                <input placeholder="Search" type="text" value={search_} onChange={(e)=>searchChanged(e.target.value)} className={`bg-transparent font-primary font-semibold ${search_ === '' ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[40px] is-dirty peer `} />
                                            </div>
                                            {/* export */}
                                            <Button text='Export' containerClass="mt-[5px] bg-blue rounded-[10px] font-bold font-primary text-yellow h-[45px] mb-3 w-[150px] mx-auto flex items-center justify-center px-[10px] text-[14px]" img={exporticon} h="45px" />
                                        </div>
                                    </div>

                                    <div className="shadow-[10px] rounded-[10px] w-[800px] md:w-full lg:w-full border-graylight border-2" >
                                     <table className="border-collapse text-sm table-auto w-[800px] md:w-full lg:w-full">
                                            <thead className='text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                                                <tr>
                                                    <th></th>
                                                    <th>Username</th>
                                                    <th className='text-left'>Email</th>
                                                    <th className='text-left'>Permissions</th>
                                                    <th className='text-left'>Date Registered</th>
                                                    <th className='text-left'>Status</th>
                                                    <th className="text-left"><BiDotsVertical /></th>
                                                </tr>
                                            </thead>
                                            <tbody className='p-2'>
                                                {
                                                    allList.map((item : any, i) => (
                                                        <tr key={i} className='p-2 text-[#0B014980] border-spacing-2' style={{ height: '70px' }}>
                                                            <td className='w-[120px] text-center pr-2'>
                                                                <div className=" flex items-center justify-end">
                                                                    {
                                                                        selected.includes(item._id) ?
                                                                            <img onClick={() => addSelected(item._id)} style={{ cursor: 'pointer' }} src={checkBoxChecked} alt="" />
                                                                            :
                                                                            <img onClick={() => addSelected(item._id)} style={{ cursor: 'pointer' }} src={checkBox} alt="" />
                                                                    }
                                                                    <div className="mx-2"></div>
                                                                    <div className="mx-2"></div>
                                                                    <img className='h-[40px] w-[40px] rounded-[100%] object-contain' src={item.avatar ? item.avatar : who} alt="" />
                                                                   
                                                                </div>
                                                            </td>
                                                            <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>
                                                                <div className="flex items-center font-bold font-primary">
                                                                    <div className='pl-3 min-w-[200px]'>
                                                                        <h3 className='text-blue'>{item.userName}</h3>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.email}</td>
                                                            <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}> <span className="flex items-center">Full &nbsp; &nbsp; <BiCaretDown /> </span></td>
                                                            <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{formatDate(formatCreatedAtDate(item.createdAt), 3)}</td>
                                                            <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.status}</td>
                                                            <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} w-[50px]`}>
                                                                <div className='relative'>
                                                                {
                                                                    dropdown_ == item._id ?
                                                                        <div className="rounded-[10px] z-[4444] absolute min-w-[150px] top-[-20px] right-[10px] text-blue font-primary bg-white p-3 shadow-blue shadow-md">
                                                                            <div className='my-2'>
                                                                                <a href="#?" className='my-'>Add Role</a>
                                                                            </div>
                                                                            {/* Add Role, Suspend, Deactivate, React, Delete */}
                                                                            <div className='my-2'>
                                                                                <a href="#?" className='my-2'>Suspend</a>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <a href="#?" className='my-2'>Deactivate</a>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <a href="#?" className='my-2'>Reactivate</a>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <a href="#?" className='text-red-700 my-2'>Delete</a>
                                                                            </div>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                    <BiDotsVertical onClick={()=>{
                                                                        setDropDown(item._id)
                                                                    }} />

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>

                                            {
                                                allList.length === 0 ?
                                                    <p>No record found</p>
                                                    :
                                                    ''
                                            }
                                        </table>
                                    </div>
                                </div>
                                <Pagination limit={10} length={allRecords} page={page} />
                                
                        </div>
                }

            </div>
        </div>
    )
}

export default Users