import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useDocumentTitle from "../../../helpers/pageTitle";
import { useTypedSelector } from "../../../hooks/useSelector";
import { ActionType } from "../../../store/actions";
import { AiModal, ChallengeSteps } from "../../../components/brand";
import Input from "../../../components/input";
import Button from "../../../components/button";
import {
  votingCategories,
  rewardTypes,
  locations,
  challengeTypes,
  challengeVisibilities,
} from "../../../data";
import { iCategoryType, iChallengeCat } from "../../../models/model";
import DropDown from "../../../components/dropdown";
import instance from "../../../helpers/axios";
import {
  capitalizeFirstLetter,
  getUserLocation,
  isAdmin,
  isEdit,
  isValidUrl,
  ucwords,
  ValidDate,
} from "../../../helpers/helpers";
import { beentos_ai, checkBox, checked_2 } from "../../../components/images";
import { CoreDropdown, Hr } from "../../../components";
import { toast, ToastContainer } from "react-toastify";
import { BiCaretDown, BiCaretUp, BiPencil } from "react-icons/bi";
import AddGuidelineModal from "../../../components/brand/add_guideline_modal";
import { Divider } from "rsuite";
import ls from "localstorage-slim";
import BSTooltip from "../../../components/tooltip";

const CreateChallenge2: React.FC = () => {
  const { loggedInBrand, loggedInAdmin } = useTypedSelector((state) => state.appReducer);

  const [category, setCategory] = useState("");
  const [categoryERr, setCategoryERr] = useState("");
  const [showCategoryErr, setShowCategoryErr] = useState(false);

  const [reward, setReward] = useState<any[]>([]);
  // const [rewardTypes, setRewardTypes] = useState<string[]>(['Cash', 'Product', 'Others/Both']);

  const [challengeType, setchallengeType] = useState<string[]>([]);
  const [rewardErr, setRewardErr] = useState("");
  const [showRewardErr, setShowRewardErr] = useState(false);

  const [location_, setLocation] = useState("Global");
  const [locationErr, setLocationErr] = useState("");
  const [showLocationErr, setShowLocationErr] = useState(false);

  const [fileSelected, setfileSelected] = useState("");
  const [fileSelectedErr, setfileSelectedErr] = useState("");
  const [showfileSelectedErr, setShowfileSelectedErr] = useState(false);

  const [criteria, setCriteria] = useState("");
  const [criteriaErr, setCriteriaErr] = useState("");
  const [showCriteriaErr, setShowCriteriaErr] = useState(false);
  const cha_types = ["Video", "Picture", "Both"];
  const cha_types2 = ["Cash", "Product", "Others/Both"];
  const [challengeCat, setChallengeCat] = useState<iChallengeCat[]>();
  const [challengeCatErr, setChallengeCatErr] = useState("");
  const [showChallengeCatErr, setShowChallengeCatErr] = useState(false);
  const [chaTypes, setChaTypes] = useState(["Video", "Picture", "Both"]);
  const [challengeCategories, setChallengeCategories] = useState<
    iCategoryType[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState("");
  const [validErr, setValidErr] = useState("");
  const [showValidErr, setShowValidErr] = useState(false);

  const [budget, setBudget] = useState("");
  const [budgetErr, setBudgetErr] = useState("");
  const [showBudgetErr, setShowBudgetErr] = useState(false);

  const [visibility, setVisibility] = useState("Private");
  const [visibilityErr, setVisibilityErr] = useState("");
  const [showVisibilityErr, setShowVisibilityErr] = useState(false);

  const [ctype, setCtype] = useState("");
  const [ctypeErr, setCtypeErr] = useState("");
  const [showCtypeErr, setShowCtypeErr] = useState(false);
  const [showAddGuideline, setShowAddGuideline] = useState(false);
  const [showEditGuidelineModal, setShowEditGuidelineModal] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  // coming here

  const [winner, setWinner] = useState("10");
  const [winnerErr, setWinnerErr] = useState("");
  const [showWinnerErr, setShowWinnerErr] = useState(false);
  const [musicUrlErr, setMusicUrlErr] = useState("");
  const [showMusicUrlErr, setShowMusicUrlErr] = useState(false);
  const [isMenuOpen, SetisMenuOpen] = useState(false);

  const [showAiModal, setShowAiModal] = useState(false);
  const [userloc, setUserloc] = useState<any[]>([]);
  const [userrate, setUserrate] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [postBUdget, setPostBUdget] = useState(0);
  const [isadvance, setIsAdvance] = useState(false);
  
  const { challengeData }: any = useTypedSelector((state) => state.appReducer);
  const [musicUrl, setMusicUrl] = useState("");
  const [guidelinesArray, setGuidelinesArray] = useState<any[]>([]);
  const [challengeBudget, setChallengeBudget] = useState("");
  const [prizeNarration, setPriceNarration] = useState("");

  useEffect(() => {
    // window.alert("he")
    if (window.location.href.includes("admin")) {
      dispatch({
        type: ActionType.UPDATE_LAYOUT,
        payload: "admin",
      });
    } else {
      dispatch({
        type: ActionType.UPDATE_LAYOUT,
        payload: "brand",
      });
    }

  }, []);

  const headers = {
    'Authorization': `Bearer ${window.location.href.includes("admin") ? loggedInAdmin : loggedInBrand}`
  }
  const { pathname, search } = useLocation();
  const [challengeId, setChallengeId] = useState("");
  const [challenge, setChallenge] = useState<any>({});
  const [rewardValues, setRewardValues] = useState("");
  const [showcurrencyErr, setShowcurrencyErr] = useState(false);
  const [currencyErr, setCurrencyErr] = useState("");
  const [currency, setCurrency] = useState("CAD");
  const currencies: any[] = [
    {
      id: 1,
      key: 1,
      label: "CAD",
      value: "CAD"
    },
    {
      id: 2,
      key: 2,
      label: "USD",
      value: "USD"
    },
    {
      id: 3,
      key: 3,
      label: "NGN",
      value: "NGN"
    },

  ]

  useEffect(() => {
    setLoading(true);
    const search_ = search.replace("?", "").split("&");
    if (search_.length > 0) {
      search_.forEach((s) => {
        if (s.includes("challenge=") !== false) {
          const challengeId = s.replace("challenge=", "");
          const challengeurl = `/challenge/single/${challengeId}/0/1`;

          setChallengeId(challengeId);
          instance({
            method: isEdit() ? "POST" : "GET",
            url: isEdit()
              ? challengeurl
              : `/challenge/draft/single/${challengeId}`,
            headers: headers,
          })
            .then(async (response) => {
              let cha: any;
              if (isEdit()) {
                cha = response.data.data.challenge;
              } else {
                cha = response.data.data;
              }
              setChallenge(cha);
              setfileSelected(cha.backgroundMusic);
              setLocation(
                cha.audienceLocation ? cha.audienceLocation : "Select"
              );
              setCtype(
                cha.challengeType
                  ? capitalizeFirstLetter(cha.challengeType)
                  : "Select"
              );
              if (cha.noOfWinners) setWinner(cha.noOfWinners);
              setVisibility(capitalizeFirstLetter(cha.challengeVisibility))
              if (cha.challenge_visibility) setWinner(cha.challenge_visibility);
              if (cha.closingDate) setValid(cha.closingDate);
              if (cha.backgroundMusicUrl) setMusicUrl(cha.backgroundMusicUrl);
              if (cha.winnerSelectionType) {
                var sel = cha.winnerSelectionType;
                if (sel.length == 1) {
                  setCategory("Audience Choice");
                } else {
                  setCategory("Brand Choice");
                }
              }

              //set value for budget currency with rate
              let userLoc_: any[] =
                ls.get("blocation", { decrypt: true }) ?? [];
              let usrate: any = ls.get("busrate", { decrypt: true });
              if (!userLoc_ || userLoc_.length === 0) {
                try {
                  userLoc_ = await getUserLocation();
                } catch (error) {
                  console.log("Error fetching rate", error);
                }
              }

              if (!usrate) {
                try {
                  const response = await instance({
                    method: "GET",
                    url: `payment/rate/${userLoc_[3]}`,
                    headers: headers,
                  });
                  usrate = response.data.data.rate;
                  ls.set("busrate", usrate, { encrypt: true });
                } catch (error: any) {
                  console.log("Error getting exchange rate", error.message);
                }
              }

              setUserloc(userLoc_);
              // to make it use usd by default, although its not neecessary gain
              if (true) {
                setBudget(cha.totalPrizeBudget);
                setPostBUdget(cha.totalPrizeBudget);
                setChallengeBudget(cha.totalPrizeBudget);
              } else {
                const parsebudget = parseInt(cha.totalPrizeBudget);
                setUserrate(usrate);
                if (cha.totalPrizeBudget !== 0) {
                  setBudget(
                    Math.ceil(Number(cha.totalPrizeBudget) * usrate).toFixed(2)
                  );
                }
                // setBudget((parsebudget*usrate).toString());
                setPostBUdget(Number(cha.totalPrizeBudget));
              }
              console.log(cha)
              if (cha.currency) setCurrency(cha.currency)
              if (cha.prizeNarration) setPriceNarration(cha.prizeNarration)
              // if(cha.totalPrizeBudget){
              setLoading(true);
              if (cha.guidelines) {
                setCriteria(cha.guidelines);
                setGuidelinesArray(cha.guidelines.split("~end-of-a-step~"));
              }
              // setValid(data.valid)
              if (cha.rewardType) {
                setReward(cha.rewardType);
                var rewar = "";
                cha.rewardType.forEach((r: string) => {
                  rewar =
                    rewar === ""
                      ? capitalizeFirstLetter(r)
                      : `${capitalizeFirstLetter(r)},`;
                });
                // console.log(rewar)
                if (!rewar.toLocaleLowerCase().includes("cash") && !rewar.toLocaleLowerCase().includes("flat")) {
                  // window.alert(rewar)
                  setPrizeDisabled(true);
                  // setShowBudgetErr(true);
                  // setBudget("");
                  // setBudgetErr(
                  //   "This field is only availble when Reward type include cash"
                  // );
                } else {
                  setPrizeDisabled(false);
                  setShowBudgetErr(false);
                }
                setRewardValues(rewar);
              }
            })
            .catch((err) => {
              if (err.code == "ERR_NETWORK") {
                alert("No internet connection");
              } else {
                if (err.response.data.statusCode === 403) {
                  navigate("/brand/login");
                  return false;
                } else {
                  navigate("/brand/dashboard");
                }
              }
              toast.error(err.response.data.message);
            })
            .finally(() => {
              setLoading(false);
            });
        } else setLoading(false);
      });
    } else {
      navigate("/brand/dashboard");
    }
    // }
    // )()
  }, []);

  useEffect(() => {
    if (window.location.href.includes("admin")) {

    } else {

      if (!loggedInBrand || loggedInBrand == "") {
        // nobody is logged in
        navigate("/brand/login");
      }
    }

    autosave()
  }, [loggedInBrand]);
  // {
  //     // navigate('/brand/challenge/create-step-3')
  // }

  const challengeSelected = (value: string) => {
    value = value.toLowerCase();
    var selected_ = challengeType;

    if (selected_.includes(value)) {
      selected_.splice(selected_.indexOf(value), 1);
    } else {
      selected_.push(value);
    }

    setchallengeType(selected_);
    setChaTypes(cha_types);
  };
  const params = useParams()

  const proceed = (action = "save") => {
    // window.alert(currency)
    // return false;
    let passed = true;
    var isBudget = true;
    if (reward?.length === 1) {
      if (reward[0].label === "Cash" || reward[0].label === "Product") {
        isBudget = false;
      }
    }
    if (reward?.length === 2) {
      if (reward[0].label !== "Cash" || reward[1].label !== "Cash") {
        isBudget = false;
      }
    }
    // challenge_type, rewardtype, winnerselection, audience location, winners, budget, validthru, music, guidelines
    setShowCtypeErr(false);
    setShowCategoryErr(false);
    setShowRewardErr(false);
    setShowCriteriaErr(false);
    setShowLocationErr(false);
    setShowChallengeCatErr(false);
    setShowMusicUrlErr(false);
    setShowWinnerErr(false);
    setShowVisibilityErr(false)
    if (prizeDisabled) isBudget = false;
    if (isBudget) setShowBudgetErr(false);
    setShowValidErr(false);
    setShowfileSelectedErr(false);

    setLoading(true);
    setTimeout(() => {

      if (!reward || reward?.length === 0) {
        setShowRewardErr(true);
        setRewardErr("Please select at least one from the list");
        passed = false;
      }


      if (criteria === "") {
        setShowCriteriaErr(true);
        setCriteriaErr("Please enter judging criteria");
        passed = false;
      }

      if (musicUrl !== "") {
        if (!isValidUrl(musicUrl)) {
          setShowMusicUrlErr(true);
          setMusicUrlErr("Please enter a valid url");
          passed = false;
        }
      }
      // if (!challengeCat || challengeCat?.length === 0) {
      //     setShowChallengeCatErr(true)
      //     setChallengeCatErr("Please challenge category")
      //     passed = false;
      // }

      if (winner === "" || Number(winner) < 3) {
        setShowWinnerErr(true);
        setWinnerErr("Number of winners must be greater than 3");
        passed = false;
      }

      if (prizeNarration === "") {
        setShowBudgetErr(true);
        setBudgetErr("Plase enter Narration breakdown eg. 20USD per Participant");
        passed = false;
      }

      if (valid === "") {
        setShowValidErr(true);
        setValidErr("Please select date");
        passed = false;
      } else if (!ValidDate(valid)) {
        setShowValidErr(true);
        setValidErr("Date cannot be in the past.");
        passed = false;
      }
      // window.alert(fileSelected)
      // if (fileSelected === "" || !fileSelected) {
      //   setfileSelectedErr("Please enter music name");
      //   setShowfileSelectedErr(true);
      //   passed = false;
      // }
      if (visibility === "") {
        setVisibilityErr("Please select visibility");
        setShowVisibilityErr(true);
        passed = false;
      }

      // setLoading(false)
      var userReward: any[] = [];
      reward.forEach((element: any) => {
        try {
          userReward.push(element.value.toLowerCase());
        } catch (error) {
          userReward.push(element.toLowerCase());
        }
      });
      if (userReward.includes("Cash") || userReward.includes("Flat Rate")) {
        if (currency === "") {
          setCurrencyErr("Please select visibility");
          setShowcurrencyErr(true);
          passed = false;
        }
      }
      if (!passed) {
        setLoading(false);
      } else {
        var winnerSelection;
        if (category == "Audience Choice") {
          winnerSelection = ["public"];
        }

        if (category == "Brand Choice") {
          winnerSelection = ["public", "brand"];
        }

        if (category == "Both") {
          winnerSelection = ["public", "brand"];
        }
        // ctype, winners, budget, valid, fileSelected,

        // var data = JSON.parse(challengeData);
        // data = { ...data, winnerc: category, challenge_category: challengeCat, winnerSelection: winnerSelection, judgingCriteria: criteria, rewardType: [reward], audienceLocation: location_, backgroundMusic: fileSelected, budget: budget, winner: winner, categoryType: ctype, valid: valid, }
        var data1: any = {
          backgroundMusicUrl: musicUrl,
          title: challenge.title,
          lastStep: 3,
          challengeId: challengeId,
          creationStatus: isEdit() ? "complete" : "draft",
          challenge_category: challengeCat,
          winnerSelectionType: winnerSelection,
          guidelines: criteria,
          rewardType: userReward,
          currency: currency,
          prizeNarration: prizeNarration,
          audienceLocation: "Global",
          challengeVisibility: visibility.toLowerCase(),
          backgroundMusic: fileSelected,
          totalPrizeBudget: isBudget ? postBUdget.toString() : 0,
          noOfWinners: parseInt(winner),
          challengeType: "video",
          // challengeType: ctype.toLowerCase(),
          closingDate: valid,
        };


        if (window.location.href.includes("admin")) {
          data1.brandId = params.brandid?.toString()
        }
        let url = window.location.href.includes("admin") ? '/challenge/admin/create' : '/challenge/create'
        let editUrl = isAdmin() ? '/challenge/admin/update' : "/challenge/update";
        instance({
          url: isEdit() ? editUrl : url,
          method: isEdit() ? "PUT" : "POST",
          headers: headers,
          data: data1,
        })
          .then((response) => {
            console.log(response);
            if (isEdit()) {
              toast.success("Campaign updated");
              setTimeout(() => {
                if(isAdmin()) {
                  navigate(`/admin/campaign-management`)
                }else{
                  navigate("/brand/dashboard");
                }
              }, 1000);
            } else {
              setChallengeId(response.data.data._id);
              if (window.location.href.includes("admin")) {
                navigate(`/admin/create-challenge/${params.brandid}/create-step-check?challenge=${(response.data.data._id)}`);
              } else {

                navigate(
                  `/brand/challenge/create-step-check?challenge=${response.data.data._id}`
                );
              }
            }
          })
          .catch((err) => {
            if (err.code == "ERR_NETWORK") {
              alert("No internet connection");
            }
            if (err.response.data.statusCode === 403) {
              navigate("/brand/login");
              return false;
            }
            setLoading(false);
            toast.error(err.response.data.message);
          });
      }
    }, 2000);
  };

  const autosave = () => {
    if (isEdit()) return false;
    return false;
    setInterval(() => {
      console.log("jhere")
      try {

        var userReward: any[] = [];
        reward.forEach((element: any) => {
          try {
            userReward.push(element.value.toLowerCase());
          } catch (error) {
            userReward.push(element.toLowerCase());
          }
        });

        var winnerSelection;
        if (category == "Audience Choice") {
          winnerSelection = ["public"];
        }

        if (category == "Brand Choice") {
          winnerSelection = ["public", "brand"];
        }

        if (category == "Both") {
          winnerSelection = ["public", "brand"];
        }
        var data1 = {
          backgroundMusicUrl: musicUrl,
          title: challenge.title,
          lastStep: 3,
          challengeId: challengeId,
          creationStatus: "draft",
          challenge_category: challengeCat,
          winnerSelectionType: winnerSelection,
          guidelines: criteria,
          rewardType: userReward,
          currency: currency,
          prizeNarration: prizeNarration,
          audienceLocation: "Global",
          challenge_visibility: visibility,
          backgroundMusic: fileSelected,
          noOfWinners: parseInt(winner),
          challengeType: "video",
          closingDate: valid,
        };

        instance({
          url: isEdit() ? "/challenge/update" : "/challenge/create",
          method: isEdit() ? "PUT" : "POST",
          headers: headers,
          data: data1,
        })
          .then((response) => {
            console.log(response);
          }).catch((err) => console.log(err))

      } catch (error) {

      }
    }, 10000)
  }

  const handleChange = (selectedOption: any, actionMeta: any) => {
    setChallengeCat(selectedOption);
  };

  const handleChange1 = (selectedOption: any, actionMeta: any) => {
    // console.log(selectedOption)
    setCurrency(selectedOption.label);
  };

  const [prizeDisabled, setPrizeDisabled] = useState(false);
  const handleChange2 = (selectedOption: any, actionMeta: any) => {
    setPrizeDisabled(false);
    if (selectedOption.length === 1) {
      if (selectedOption[0].label !== "Cash" && !selectedOption[0].label.includes("Flat")) {
        setPrizeDisabled(true);
        // setShowBudgetErr(true);
        // setBudget("");
        // setBudgetErr(
        //   "This field is only availble when Reward type include cash"
        // );
      } else {
        setPrizeDisabled(false);
        setShowBudgetErr(false);
      }
    }
    if (selectedOption.length === 2) {
      if (
        selectedOption[0].label !== "Cash" &&
        selectedOption[1].label !== "Cash" &&
        !selectedOption[0].label.includes("Flat") &&
        !selectedOption[1].label.includes("Flat")
      ) {
        setPrizeDisabled(true);
        // setShowBudgetErr(true);
        // setBudget("");
        // setBudgetErr(
        //   "This field is only availble when Reward type include cash"
        // );
      } else {
        setPrizeDisabled(false);
        setShowBudgetErr(false);
      }
    }

    setReward(selectedOption);
  };

  const handleInputChange = (inputValue: any, actionMeta: any) => {
    console.log("handleInputChange", inputValue);
    // setChallengeCat(inputvalu)
  };
  const handleInputChange2 = (inputValue: any, actionMeta: any) => {
    // console.log("handleInputChange", inputValue, actionMeta);
    // setChallengeCat(inputvalu)
  };

  const editReward = () => {
    setReward([]);
    setRewardValues("");
    setPrizeDisabled(false);
    setShowBudgetErr(false);
    setBudgetErr("");
    setBudget("");
  };
  const updateBudget = (value: string) => {
    setShowBudgetErr(false);
    if (value == "") {
      setBudget("");
      setPostBUdget(0);
      return;
    }
    var val = parseInt(value);

    // if (userloc[1] != "US") {
    //   setBudget(value);
    //   setPostBUdget(parseFloat((val / userrate).toFixed(4)));
    // } else {
    // }
    setPostBUdget(val);
    setBudget(value);
  };
  useDocumentTitle("STEP 2 - CREATE Campaign");
  // const a = []
  return (
    <div className="px-[5px] py-[50px]">
      {showAiModal ? (
        <AiModal
          close={() => {
            setShowAiModal(false);
          }}
        />
      ) : (
        ""
      )}
      {showAddGuideline ? (
        <AddGuidelineModal
          text=""
          close={() => {
            setShowAddGuideline(false);
          }}
          setFeature={(text: string) => {
            setShowAddGuideline(false);
            var g = guidelinesArray;
            g = [...g, text];
            setCriteria(g.join("~end-of-a-step~"));
            setGuidelinesArray(g);
          }}
        />
      ) : (
        ""
      )}

      {showEditGuidelineModal ? (
        <AddGuidelineModal
          isEdit={true}
          text={guidelinesArray[editIndex]}
          index={editIndex}
          close={() => {
            setShowEditGuidelineModal(false);
          }}
          setFeature={(index: number, text: string) => {
            var f = guidelinesArray;
            f[index] = text;
            setGuidelinesArray(f);
            setCriteria(f.join("~end-of-a-step~"));
            setShowEditGuidelineModal(false);
          }}
        />
      ) : (
        ""
      )}

      <div className="container mx-auto">
        <ToastContainer />
        <div className="flex items-center justify-between">
          <div className="text-blue font-primarybold text-[18px]">
            {isEdit() ? "Edit Campaign" : "Create New Campaign"}
          </div>

          <button
            className="w-[43px] hidden lg:flex lg:w-[200px] rounded-[20px] h-[45px] pr-[10px] bg-blue items-center justify-center text-yellow"
            onClick={() => {
              setShowAiModal(true);
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              src={beentos_ai}
              className="object-contain h-[40px]"
              alt=""
            />
            <span>Ask the AI for help</span>
          </button>
          <img
            style={{ cursor: "pointer" }}
            src={beentos_ai}
            onClick={() => {
              setShowAiModal(true);
            }}
            className="object-contain block lg:hidden h-[30px]"
            alt=""
          />
        </div>
        <br />
        <div className="py-[40px] lg:mt-[50px] bg-white shadow-lg rounded ">
          <ChallengeSteps
            challenge={challengeId}
            current={3}
            completed={[1, 2]}
          />
          <br />
          <div className="lg:mt-[50px]">
            <br />
            <div className="text-center my-1 lg:my-[120px] mx-auto w-full max-w-[840px]">
              <h3 className="text-blue text-[24px] font-bold font-primary">
                What are your challenge terms and conditions?
              </h3>
            </div>
            <div className="mt-1 w-[80%] mx-auto">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                {/* <div className="my-2">
                  <label className="font-primary text-blue" htmlFor="">
                    Challenge type{" "}
                  </label>
                  <CoreDropdown
                    source="challenge"
                    showError={showCtypeErr}
                    err={ctypeErr}
                    items={challengeTypes}
                    containerClass="control justify-between border border-blue pl-3 h-[50px] rounded-[45px] w-full mt-[7px] bg-[#F0F0F4]"
                    placeholder="Select Challenge Type"
                    type="text"
                    value={ctype}
                    setValue={setCtype}
                  />
                </div> */}
                <div className=" my-2">
                  <div className="flex ">
                  <label className="text-blue" htmlFor="">
                    Visibility*
                  </label>
                  <BSTooltip tooltip={"Public campaigns will be made available for anybody to participate in the Beentos Mobile App, while Private campaigns only available for selected users only."} />
                  </div>
                 
                  {/* <Input pos='top-[40%]' posErr='top-[30%]' prevErr={false} showError={showLocationErr} err={locationErr} isDropDown={true} items={locations} placeholder="Select and option" type="text" value={location_} setValue={setLocation} containerClass="mt-[0px] border-graylight border-2 shadow-sm mt-0 bg-[#F0F0F4]" /> */}
                  <CoreDropdown
                    source="challenge"
                    showError={showVisibilityErr}
                    err={visibilityErr}
                    items={challengeVisibilities}
                    containerClass="control justify-between border border-blue pl-3 h-[50px] rounded-[45px] w-full mt-[7px] bg-[#F0F0F4]"
                    placeholder="Selet"
                    type="text"
                    value={visibility}
                    setValue={setVisibility}
                  />
                </div>
                <div className="my-2">
                  <div className="flex">
                      <label className="font-primary text-blue" htmlFor="">
                    Valid Thru*
                  </label>
                  <BSTooltip tooltip={"This is use to mark your Campaign's end date"} />

                  </div>
                
                  <div className="mt-[7px]" />

                  <Input
                    showError={showValidErr}
                    err={validErr}
                    value={valid}
                    setValue={setValid}
                    containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2"
                    type="date"
                    placeholder="12/12/2024"
                  />
                </div>

                <div className="col-span-2 mb-2">
                  <div className="flex">
                     <label className="font-primary text-blue" htmlFor="">
                    Prize Narration *
                  </label>
                  <BSTooltip tooltip={"Give short description of your reward"} />

                  </div>
                 
                  <div className="mt-[7px]" />
                  <Input
                    err={budgetErr}
                    showError={showBudgetErr}
                    value={prizeNarration}
                    setValue={setPriceNarration}
                    containerClass="h-[45px] bg-[#F0F0F4] border-graylight shadow-sm border-2"
                    placeholder="Short description of your reward"
                  />
                </div>
                </div>
                <div className="mt-[15px]"></div>
                {showBudgetErr ? <div className="mt-[25px]"></div> : ""}
              <div className="mt-[15px]">
                <div className="flex justify-between">
                  <div className="flex">
                    <label className="text-blue" htmlFor="">
                    Guidelines
                  </label>
                  <BSTooltip tooltip={"Carefully list out all your Campaign's guidelines"} />
                  </div>
                  
                  <button
                    onClick={() => {
                      setShowAddGuideline(true);
                    }}
                  >
                    Add New
                  </button>
                </div>
                <div className="mt-[12px] bg-[#F0F0F4] border-graylight border-2 p-3 rounded-[10px] shadow-sm">
                  {guidelinesArray.length === 0 ? (
                    <>
                      <p>You have not added </p>
                    </>
                  ) : (
                    <>
                      {guidelinesArray.map((item, i) => (
                        <>
                          <div
                            className="text-blue font-bold flex gap-3"
                            key={i}
                          >
                            <div className="flex-initial w-full">
                              <p>{1 + i}. {item}</p>
                            </div>
                            <div className="flex-initial w-[80px]">
                              <div className="flex gap-3 items-center">
                                <p
                                  onClick={() => {
                                    setEditIndex(i);
                                    setShowEditGuidelineModal(true);
                                  }}
                                  className="text-center"
                                >
                                  <BiPencil />
                                </p>
                                <p
                                  onClick={() => {
                                    if (
                                      confirm(
                                        "Are you sure? You cannot revert this action"
                                      )
                                    ) {
                                      var f = guidelinesArray;
                                      var nf: string[] = [];
                                      f.forEach((it: string, index) => {
                                        if (i !== index) {
                                          nf.push(it);
                                        }
                                      });
                                      setGuidelinesArray(nf);
                                      if (nf.length === 0) {
                                        setCriteria("");
                                      } else {
                                        setCriteria(nf.join("~end-of-a-step~"));
                                      }
                                    }
                                  }}
                                  className="cursor-pointer text-center"
                                >
                                  x
                                </p>
                              </div>
                            </div>
                          </div>
                          <Divider />
                        </>
                      ))}
                    </>
                  )}
                </div>
                {showCriteriaErr ? (
                  <div className="w-full flex justify-start mt-2">
                    <p className="m-0 text-red-700">
                      {" "}
                      <small>{criteriaErr}</small>{" "}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {/* <Input placeholder="You can enter up to 5 criteria" type='textarea' showError={showCriteriaErr} err={criteriaErr} value={criteria} setValue={setCriteria} containerClass="mt-[12px] bg-[#F0F0F4] border-graylight border-2 shadow-sm" /> */}
              </div>
                <div onClick={()=> {
                  setIsAdvance(!isadvance)
                }} className="mt-4 cursor-[pointer] py-3 bg-graylight px-3 rounded  flex items-center gap-3">
                  <h3 className="text-blue font-bold font-primarybold">Advance</h3>
                  <Hr h={"h-[3px] rounded"} />
                  {
                    isadvance 
                    ?
                    <BiCaretDown />
                    :
                    <BiCaretUp />
                  }
                </div>
                
              <section className={`${isadvance ? '' : 'hidden'} grid grid-cols-1 px-2 mt-3 lg:grid-cols-2 gap-3`}>
              <div className="my-2">
                <div className="flex justify">
                    <label className="font-primary text-blue" htmlFor="">
                  Numbers of winners*
                </label>
                <BSTooltip tooltip={"Number of participants you are rewarding"} />
                </div>
                
                <div className="mt-[7px]" />

                <Input
                  value={winner}
                  setValue={setWinner}
                  err={winnerErr}
                  showError={showWinnerErr}
                  containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2"
                  placeholder="Min of 3"
                />
              </div>

              <div className={`my-2 `}>
                <label
                  className="text-blue flex items-center gap-3 justify-between"
                  htmlFor=""
                >
                  <div className="flex ">
                  <span>Reward type*</span>
                  <BSTooltip tooltip={"Please select how you want to reward the winners."} />
                  </div>
                  {" "}
                  {!isEdit() ? (
                    rewardValues === "" ? (
                      ""
                    ) : (
                      <a
                        onClick={() => editReward()}
                        className="text-sm text-blue font-bold flex gap-1 items-center"
                        href="#?"
                      >
                        Change <BiPencil />
                      </a>
                    )
                  ) : (
                    ""
                  )}{" "}
                </label>
                <div className="mt-[7px]" />

                {rewardValues === "" ? (
                  <DropDown
                    showError={showRewardErr}
                    err={rewardErr}
                    isMulti={true}
                    options={rewardTypes}
                    handleChange={handleChange2}
                    handleInputChange={handleInputChange2}
                  />
                ) : (
                  <div>
                    <Input
                      isDisabled={true}
                      showError={false}
                      err={""}
                      placeholder=""
                      items={challengeTypes}
                      value={rewardValues}
                      setValue={() => { }}
                      containerClass="border-graylight border-2 shadow-sm mt-[5px] bg-[#F0F0F4]"
                    />
                  </div>
                )}
              </div>

              <div className="my-2 d-none">
                <label className="text-blue" htmlFor="">
                  Audience Location*
                </label>
                <CoreDropdown
                  source="challenge"
                  showError={showLocationErr}
                  err={locationErr}
                  items={locations}
                  containerClass="control justify-between border border-blue pl-3 h-[50px] rounded-[45px] w-full mt-[7px] bg-[#F0F0F4]"
                  placeholder="Select Campaign Type"
                  type="text"
                  value={location_}
                  setValue={setLocation}
                />
              </div>
              
              <div className="my-2">
                <div className="flex">
                    <label className="text-blue" htmlFor="">
                  Winner determination*
                </label>
                <BSTooltip tooltip={"How do you want to select winners. Brand determination uses the winners selected in the brand dashboard. Audience uses a voting system in the Beentos Appp"} />
                </div>
                
                {/* <Input pos='top-[40%]' posErr='top-[30%]' prevErr={false} showError={showCategoryErr} err={categoryERr} isDropDown={true} items={votingCategories} placeholder="Selec an option" type="text" value={category} setValue={setCategory} containerClass="mt-[0px] border-graylight border-2 shadow-sm mt-0 bg-[#F0F0F4]" /> */}
                <CoreDropdown
                  source="challenge"
                  showError={showCategoryErr}
                  err={categoryERr}
                  items={votingCategories}
                  containerClass="control justify-between border border-blue pl-3 h-[50px] rounded-[45px] w-full mt-[7px] bg-[#F0F0F4]"
                  placeholder="Select Campaign Type"
                  type="text"
                  value={category}
                  setValue={setCategory}
                />
              </div>
              <div className="my-2">
                <div className="flex">
                <label className="font-primary text-blue" htmlFor="">
                  Music [optional]
                </label> 
                <BSTooltip tooltip={"If you want your participant to use background music, you can specify the music here"} />
                </div>
                
                <div className="mt-[7px]" />

                {/* <Input  showError={showfileSelectedErr} err={fileSelectedErr} value={fileSelected} setValue={fileUploadChange} type="file" accept='.mp3' containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='Select a file' /> */}
                <Input
                  value={fileSelected}
                  setValue={setfileSelected}
                  err={fileSelectedErr}
                  showError={showfileSelectedErr}
                  containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2"
                  placeholder="Enter the background music title"
                />
              </div>

              <div className="my-2">
                <div className="flex">
                    <label className="font-primary text-blue" htmlFor="">
                  Music URL [optional]
                </label>
                <BSTooltip tooltip={"If you want your participant to use background music, you can specify the music url here"} />
                </div>
                
                {/* <Input  showError={showfileSelectedErr} err={fileSelectedErr} value={fileSelected} setValue={fileUploadChange} type="file" accept='.mp3' containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='Select a file' /> */}
                <Input
                  value={musicUrl}
                  setValue={setMusicUrl}
                  err={musicUrlErr}
                  showError={showMusicUrlErr}
                  containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2"
                  placeholder="Enter the music URL"
                />
              </div>
              </section>

              <div className="text-center flex justify-end w-full">
                {isEdit() ? (
                  <div className="flex justify-center">
                    <Button
                      loading={loading}
                      text="Save and Exit"
                      onclick={() => proceed("exit")}
                      containerClass="w-[150px] bg-blue text-yellow mt-[30px]"
                    />
                  </div>
                ) : (
                  <Button
                    text="Next"
                    loading={loading}
                    onclick={() => proceed()}
                    containerClass="w-[150px] bg-blue text-yellow mt-[30px]"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateChallenge2;
