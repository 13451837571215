import React, { useEffect, useState } from 'react'
import { FiCopy, FiSearch } from 'react-icons/fi'
import { VscCloseAll } from 'react-icons/vsc'
import ButtonLoader from '../../helpers/button_loader'
import { iCreate } from '../../models/model'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { Logo } from '../images'
import Input from '../input'
import { slugify } from '../../helpers/helpers'
import { toast } from 'react-toastify'
import { Hr } from '..'

function IntegrateModal({ close, challenge } : iCreate) {
    const [search, setSearch] = useState('')
    const [result, setResult] = useState([])
    const [loading, setLoading] = useState(true)
    const ref = React.useRef<HTMLInputElement>(null);
    const [copied, setCopied] = useState(true)
    const [toCopy, SettoCopy] = useState('')
    const [code, setCode] = useState(`
    <script src="https://widget.beentos.com/beentos.min.js"></script>
    <script>
        // you can put the function on button click
        initBeentos({
            challengeCode : "${challenge.challengeCode}", 
            onSubmit : (res)=>{
                console.log("post submitted", res)
            },
            onInit : ()=> {
                console.log("Beentos ready")
            },
        })
    </script>`)
    const [style, setstyle] = useState(`
    <link rel="stylesheet" href="https://widget.beentos.com/beentos_tailwind.min.css">
    `)

    const clickToCopy = (content : string)=> {
        if(content == "link"){
            SettoCopy(`https://${window.location.host}/${slugify(challenge.brand.brandName)}/${challenge.challengeCode}`)
        }

        if(content == "code"){
            SettoCopy(code)
        }
        if(content == "style"){
            SettoCopy(style)

        }
    }
    useEffect(() => {
      if(toCopy  === "") return;
      else{
        if(ref.current){
            ref.current?.select();
            try {
                document.execCommand('copy');
                ref.current?.blur();
                SettoCopy("")
                toast.success("Copied to clipboard")
            } catch (err) {
                alert('please press Ctrl/Cmd+C to copy');
            } 
        }
      }
    }, [toCopy])
    return (
        <div>

            <div className='fixed top-0 left-0 w-full z-[999] h-[100vh]'>
            {
                toCopy === "" ? '' :
                <input className='absolute' ref={ref} onChange={()=>{}} type="text" value={toCopy}  />
            }
                <div className="max-h-[80vh] min-h-[300px] w-full flex justify-center">
                    <div onClick={() => close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[660px] p-3 relative'>
                        <div className="mt-[10px] h-[65vh] px-3 overflow-y-scroll">
                           <div>
                            <h3 className='font-[600] text-[22px] font-primarybold'>INTEGRATION :</h3>
                            <p>You can make your challenge available for your Audience using one of the following options:</p>
                            <Hr />
                            <br />
                            <h3 className='font-primarybold text-[18px]'>Beentos Url :</h3>
                            <div onClick={()=>clickToCopy("link")} style={{backgroundColor : "rgb(248 250 252)"}} className='mt-2 rounded-[10px] p-3'>
                                <p className='font-primary flex gap-2 items-center cursor-[pointer] font-[600]'>
                                {`https://${window.location.host}/${slugify(challenge.brand.brandName)}/${challenge.challengeCode}`}
                                <span className='cursor-[pointer]' >
                                <FiCopy />
                                </span>
                                </p>
                            </div>

                            <br />

                            <h3 className='font-primarybold text-center text-[18px]'>OR</h3>
                            <br />
                            <div className="flex pr-3 justify-between items-center">
                                <h3 className='font-primarybold text-[18px]'>Widget :</h3>
                            <a target='_blank' href="//widget.beentos.com">see example</a>
                            </div>
                            <div className="flex pr-3 gap-2 items-center">
                                <strong className='font-primarybold '>Copy the Style into your website HEAD tag :</strong>
                                <div onClick={()=>clickToCopy("style")} className="p-2 cursor-[pointer]">
                                <FiCopy />
                                </div>
                            </div>
                            <div style={{backgroundColor : "rgb(248 250 252)"}} className=''>
                                <SyntaxHighlighter language="javascript">
                                    {style}
                                </SyntaxHighlighter>
                            </div>
                            <br />
                            <div className="flex pr-3 gap-2 items-center">
                                <strong className='font-primarybold '>Copy the Beentos Widget Script :</strong>
                                {/* <div onClick={()=>clickToCopy("code")} className="p-2 cursor-[pointer]">
                                <FiCopy />
                                </div> */}
                            </div>
                            
                            <div style={{backgroundColor : "rgb(248 250 252)"}} className=''>
                                <SyntaxHighlighter language="javascript">
                                    {code}
                                </SyntaxHighlighter>
                            </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntegrateModal