import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { needHelp, verifyIcon } from '../../../components/images';
import useDocumentTitle from '../../../helpers/pageTitle';
import { decryptData } from '../../../helpers/security';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import Steps from '../../../components/signup/steps';
import { ChallengeSteps } from '../../../components/brand';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { brandCategories, plans } from '../../../data';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../helpers/loader';
import instance from '../../../helpers/axios';
import SubscriptionModal from '../../../components/brand/subscription_modal';

const AddSubscriptionForBrand: React.FC = () => {
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [title, setTitle] = useState('')
    const [countryCode, setCountryCode] = useState('US')
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [challenge, setChallenge] = useState("")
    const [allList, setAllList] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [userloc, setUserloc] = useState<any[]>([])
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
    const [selectedPrice, setSelectedPrice] = useState(10)
    const [subscription, setSubscription] = useState("Gold Package")
    const [selectedId, setSelectedId] = useState("Gol")
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })
     
    }, [])
    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin == "") {
            // nobody is logged in
            navigate("/admin/login");
        }
    }, [loggedInAdmin])
    const headers = {
        'Authorization': `Bearer ${loggedInAdmin}`
    }
    useEffect(() => {
        instance({
            method: "GET",
            url: `/subscription/subscription-type/?_orderBy=createdAt&_order=desc`,
            headers: headers
        }).then((response) => {
            var docs = response.data.data.docs;
            setAllList(docs);
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false);
        })

    }, [])

    const subForBrand = (id: string, amount: number, sub: string, days = 30, useCoupon = "") => {
        // return alert(params.brandid)
        setIsLoading(true);
        var data = {
            "subscriptionType": id,
            "autoRenewal": false,
            "subscriptionPeriod": days,
            "paymentStatus": "pending",
            "priceCurrency": countryCode == "NG" ? "NGN" : "USD"
        }
        instance({
            url: '/subscription/admin/'+params.brandid,
            method: "POST",
            headers: headers,
            data: data
        })
        .then((response) => {
            toast.success("Subscription added for Brand")
            setShowSubscriptionModal(false)
            // console.log(response)
            setIsLoading(false)
            // return false;
           
        }).catch((err) => {
            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            if (err.response.data.statusCode === 403) {
                navigate('/admin/login');
                return false;
            }
        })
    }
    useDocumentTitle('Create Subscription')
    // const a = []
    return (
        <div className='px-[5px] py-[50px]'>
            <ToastContainer />
            {
                isLoading ?
                    <Loader />
                    :
                    <div className="container mx-auto">
                        <div className='flex gap-3 flex-column'>
                            <Link to={"/admin/manage-brands"} >go back</Link>
                       
                        </div>
                        
                        {showSubscriptionModal && <SubscriptionModal countryCode={countryCode} selectedPrice={selectedPrice} subscription={subscription} selectId={selectedId} close={() => {
                            // close me
                            setShowSubscriptionModal(false)
                        }} proceed={(selected: number, useCoupon: string) => {
                            subForBrand(selectedId, selectedPrice, subscription, selected, useCoupon)
                        }} />}
                        <div className="py-[20px] ">
                            <br />
                            <div className="lg:mt-[10px">
                                <div className="text-center mb-[15px] w-full">
                                    <h3 className='text-blue text-[30px] font-bold font-primarybold'>Add Subscription for Brand</h3>
                                    <p className='font-bold font-primary text-[#0B0149]'>Choose the package below</p>
                                    <br />

                                </div>
                                <div className="mt-4 w-[90%] mx-auto">
                                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
                                        {
                                            allList.map((p: any, i) => (
                                                <div key={i} className={`rounded-[20px] bg-white p-[4px] ${i === 1 ? 'bg-yellow' : 'bg-white'}`}>
                                                    <div className={`bg-yellow rounded-[20px] p-1`}>
                                                        <div className="bg-white rounded-[20px] p-2 lg:p-3">
                                                            <div className="text-center">
                                                                <h3 className="text-blue text-[24px] font-primarybold">{p.name}</h3>
                                                                <h3 className="text-blue font-primarybold text-[18px] ">{countryCode == "NG" ? "NGN" : "$"} {countryCode == "NG" ? p.ngnPriceRatePerDay : p.priceRatePerDay}</h3>
                                                            </div>
                                                            <div className="h-[3px] bg-blue my-[10px]"></div>
                                                            {
                                                                p.detail ?
                                                                    ((p.detail).split(";")).map((feature: any, index: number) => (
                                                                        <div key={index} className='flex items-center my-[20px] justify-between'>
                                                                            <div className="flex">
                                                                                <img src={verifyIcon} className="h-[24px] mr-3" alt="" />
                                                                                <div>
                                                                                    {feature}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )) : ''}

                                                            <Button onclick={() => {
                                                                setSelectedId(p._id),
                                                                    setSelectedPrice(countryCode == "NG" ? p.ngnPriceRatePerDay : p.priceRatePerDay)
                                                                setSubscription(p.name)
                                                                setShowSubscriptionModal(true)
                                                            }} containerClass='bg-blue text-yellow w-full' text="Choose Plan" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}

export default AddSubscriptionForBrand