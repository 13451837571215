import {combineReducers } from 'redux'
import { appReducer } from './reducer';
const reducers = combineReducers({
    appReducer : appReducer
});
    
export default reducers;
//This RootState is required to use useSelector later on 
export type RootState = ReturnType<typeof reducers>;

    