import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { SettingsAside, SettingsHeader } from '../../../components/brand';
import { checked, notChecked } from '../../../components/images';
import instance from '../../../helpers/axios';
import useDocumentTitle from '../../../helpers/pageTitle';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';


const Privacy: React.FC = () => {
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [basicinfo, setBasicinfo] = useState(false)
    const [moreinfo, setMoreinfo] = useState(false)

    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`,
    }
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
    }, [])

    
    // get brand profile
    useEffect(() => {
        instance({
            method: "GET",
            url: "/brand/profile",
            headers: headers
        }).then((response) => {
            console.log(response)
            const c = response.data.data.challenges;
            const b = response.data.data.brand;
            const p = b.brandPrivacySettings;
            if(p !== undefined && p !== null){
                setBasicinfo(p.basicInformation)
                setMoreinfo(p.moreInformation)
            }
        }).catch((err) => {
            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            else if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }
            toast.error(err.response.data.message)
            console.log(err)
        })
    }, [])


      // get brand notification
      const updateNotification = (type : string, value : any) => {
        let data;
        if(type === "basic"){
            setBasicinfo(value)
            data = {
                basicInformation : value
            }
        }

        if(type === "more"){
            setMoreinfo(value)
            data = {
                moreInformation : value
            }
        }

        instance({
            method: "POST",
            url: "/brand-settings/privacy/update",
            headers: headers,
            data : data,
        }).then((response) => {
            console.log(response)
            toast.success("Settings updated")
        }).catch((err) => {

            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            else if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }
            toast.error(err.response.data.message)
            console.log(err)
        }).finally(()=> {
            // setLoading(false)
        })

    }
    useDocumentTitle('PRIVACY SETTINGS')

    return (
        <div className='w-full'>
            <ToastContainer />
            <div className="px-[15px] h-100 relative font-primary flex max-w-[1440]">
                <SettingsAside />
                <div className='lg:container sm:mx-auto md:mx-auto flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                    <SettingsHeader title='Privacy' />
                    <div className="my-[25px] bg-blue rounded-[10px] shadow-lg p-[15px]">
                        <div className='text-white my-3'>
                            <p>Decide what Information is shown about you to the public. </p>
                        </div>
                        <div>
                        </div>
                    </div>

                    <div className="my-[25px] bg-white rounded-[10px] shadow-lg p-[15px]">
                        <h3 className='mb-1 text-[20px] font-500 text-blue font-primary'>Basic Information</h3>
                        <div className="flex justify-between my=3">
                            <div className=''>
                                <p className='text-blue opacity-80'>Show brand’s basic information (i.e name, bio, category) .</p>
                            </div>
                            <div>
                                <img onClick={()=>updateNotification('basic', !basicinfo)} src={basicinfo ? checked : notChecked}  style={{ cursor: 'pointer' }} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="my-[25px] bg-white rounded-[10px] shadow-lg p-[15px]">
                        <h3 className='mb-1 text-[20px] font-500 text-blue font-primary'>More Information</h3>
                        <div className="flex justify-between my=3">
                            <div className=''>
                                <p className='text-blue opacity-80'>Show more Information (E.g location, number of followers, challenges & CAC number)</p>
                            </div>
                            <div>
                                <img onClick={()=>updateNotification('more', !moreinfo)} src={moreinfo ? checked : notChecked}  style={{ cursor: 'pointer' }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy