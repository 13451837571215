import React from 'react'
import { PDFDownloadLink, Font, Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Logo } from '../../components/images';
import { iPdf } from '../../models/model';
import { findPercentage, formatDate } from '../../helpers/helpers';
interface iReport {

}
const ReportTemplate = (data : iPdf) => {
	Font.register({
		family: 'Oswald',
		src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
	});
	console.log(data.challenges)
	const styles = StyleSheet.create({
		pdf : {
			height : '100vh',
			width : '100%',
		},
		body: {
			paddingTop: 35,
			paddingBottom: 65,
			paddingHorizontal: 35,
		},
		title: {
			fontSize: 24,
			textAlign: 'center',
			fontFamily: 'Oswald'
		},
		author: {
			fontSize: 12,
			textAlign: 'center',
			marginBottom: 15,
		},
		subtitle: {
			fontSize: 14,
			// margin: 12,
			fontFamily: 'Oswald'
		},
		text: {
			margin: 12,
			fontSize: 14,
			textAlign: 'justify',
			fontFamily: 'Times-Roman'
		},
		image: {
			// marginVertical: 15,
			// marginHorizontal: 50,
			width : '90%',
			maxHeight : '300px',
			objectFit : 'contain',
			marginHorizontal : 'auto'
		},
		logo: {
			height: 72,
			width : 72,
			objectFit : 'contain',
			margin : '0 auto'
			// marginHorizontal: 100,
		},
		space1 : {
			marginBottom : 10,
		},
		space2 : {
			marginBottom : 20,
		},
		header: {
			fontSize: 12,
			marginBottom: 20,
			textAlign: 'center',
			color: 'grey',
		},
		pageNumber: {
			position: 'absolute',
			fontSize: 12,
			bottom: 30,
			left: 0,
			right: 0,
			textAlign: 'center',
			color: 'grey',
		},
		table : {
			// display: 'flex',
			width : '100%',
		},
		row : {
			display : 'flex',
			flexDirection : 'row',
		},
		tableHeader : {
			display : 'flex',
			flexDirection : 'row',
			backgroundColor : "#F8F8F8",
			height : '40px',
			textAlign : 'center',
			alignItems : 'center',
			color : "rgb(11 1 73)",
		},
		headerCell : {
			fontWeight : 'bold',
			width : '100%',
			fontSize : 12
		},
		cell : {
			// flex : 1,
			width : '50%',
			flexGrow: 1,
			color : "#0B014980",
			padding : 4,
			fontSize : 10,
			minHeight : 40,
		}
	});
	const convertPositive = (value : any):number => {
        if(Number(value) < 0){
            return Number(value) * -1;
        }
        return Number(value)
    }

	return (
		// <PDFViewer style={styles.pdf}>
		<Document>
			<Page style={styles.body}>
				<Image
					style={styles.logo}
					src={Logo}
				/>
				<Text style={styles.header} fixed>
					~ Brand Report - Beentos ~
				</Text>
				
				<Text style={styles.author}>Date : {data.startDate} to {data.endDate}</Text>
				<Text style={styles.subtitle} >
					Overview :
				</Text>
				<Text style={styles.space1} ></Text>
				<Image
					style={styles.image}
					src={data.report1}
				/>
				<Text style={styles.space2} ></Text>
				<Text style={styles.subtitle}>
					Engagements Breakdown: 
				</Text>
				<Image
					style={styles.image}
					src={data.report2}
				/>
				<Text style={styles.space2} break ></Text>
				<Text style={styles.subtitle}>
					Social Media Shares: 
				</Text>
				<Text style={styles.space1} ></Text>
				<Image
					style={styles.image}
					src={data.report3}
				/>
				<Text style={styles.space1} ></Text>
				<Text style={styles.subtitle}>
					Users Reached (up to 24hrs): 
				</Text>
				<Text style={styles.space1} ></Text>
				<Image
					style={styles.image}
					src={data.report4}
				/>
				<Text style={styles.space1} break ></Text>
				<Text style={styles.subtitle}>
					Social Media Shares : 
				</Text>
				<Text style={styles.space1} ></Text>
				<Image
					style={styles.image}
					src={data.report5}
				/>
				<Text style={styles.space2} ></Text>
				<Text style={styles.subtitle}>
					All Campaigns : 
				</Text>
				<Text style={styles.space1} ></Text>
				<View style={styles.table} >
				{/* Title, start, end, engagement, participants */}
				<View style={styles.tableHeader}>
					<Text style={styles.headerCell}>Title</Text>
					<Text style={styles.headerCell}>Start</Text>
					<Text style={styles.headerCell}>End</Text>
					<Text style={styles.headerCell}>Engagement</Text>
					<Text style={styles.headerCell}>Participants</Text>
				</View>
				{
					data.challenges?.map((item : any, i)=>(
						<View style={[styles.row, i%2==1 ? {backgroundColor : "rgb(240 240 244)"} : {}]} key={i}>
							<Text style={[styles.cell]}>{item.title}</Text>
							<Text style={[styles.cell, {textAlign : "center"}]}>{formatDate(item.openingDate, 2, true)}</Text>
							<Text style={[styles.cell, {textAlign : "center"}]}>{formatDate(item.closingDate, 2, true)}</Text>
							<Text style={[styles.cell, {textAlign : "center"}]}>Views &nbsp; {findPercentage(convertPositive(item.noOfViews), data.overallviews || 0, 0)} &nbsp; &#9745; 10 </Text>
							<Text style={[styles.cell, {textAlign : "center"}]}>{(item.participants).length}</Text>
						</View>
					))
				}
				{
					data.challenges?.length === 0 ? 
					<Text style={styles.cell}>No records</Text>
					: ''
				}
				</View>
				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
					`${pageNumber} / ${totalPages}`
				)} fixed />
			</Page>
		</Document>
		// </PDFViewer>
	);
};

export default ReportTemplate;