import { useEffect, useState } from "react"
import { ActionType } from "../../store/actions";
import { useDispatch } from "react-redux";
import Button from "../../components/button";
import { Footer, Hr } from "../../components";
import { AppSection } from "../../components/home";
import { ToastContainer } from "react-toastify";
import ClientUserForm from "../../components/clients/click_user_form";
import { useNavigate, useParams } from "react-router-dom";
import instance from "../../helpers/axios";
import { Logo } from "../../components/images";
import Loader from "../../helpers/loader";

export default function ClientLanding () {
    const [showForm1, setShowForm1] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    
    const [challenge, setChallenge] = useState<any>({});
    const [guidelines, setGuidelines] = useState<any>([])
    const [uploadedFiles, setUploadedFiles] = useState<any>([])
    const [brand, setBrand] = useState<any>({});
    const [avatar, setAvatar] = useState(Logo);
    const [brandColor, setBrandColor] = useState("#0b0149");
    const dispatch = useDispatch();
    const params = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "none"
        })

        if(!params.challengeCode){

        }
        instance({
            method : "GET",
            url : `/challenge/all?challengeCode=${params.challengeCode}`,
          }).then((response)=>{
            console.log("response", response.data)
            const b = response.data.data.docs[0];
            if(response.data.data.totalDocs == 0) navigate("/not-found") 
            setChallenge(response.data.data.docs[0])
            if(b.published != true) navigate("/not-found");
            setChallenge(b)
            setBrand(b.brand)
            instance({
                method: "GET",
                url: `file?userId=${b.brand.brandId}&fileIntent=${b._id}&_searchBy[]=fileId&_keyword=advert`
            }).then((response) => {
                setUploadedFiles(response.data.data.docs)
            })

            if(b.brand.avatar){
                setAvatar(b.brand.avatar)
                if(b.brand.brandColor) setBrandColor(b.brand.brandColor)
            } 
            var g= b.guidelines.split("~end-of-a-step~")
            setGuidelines(g)
            setIsLoading(false)
          }).catch((e)=> {
            setIsLoading(false)

          })
    }, [])

    const space = {
        marginBottom : "30px"
    }

    const space1 = {
        marginBottom : "10px"
    }
    return (
        <>
        <ToastContainer /> 
        {
            showForm1 && 
        <ClientUserForm brandColor={brandColor} state={"form"} challenge={challenge?._id} close={(v:string)=> {
            setShowForm1(false)
        }} />
    }
    {
        isLoading ?
        <Loader />
        :
        <div style={showForm1 ? {
            overflow : "hidden",
            height : "80vh"
        } : {}}>
        <div className="container font-primary max-w-[1048px] mx-auto">
            <div className="brandLogo flex justify-center my-[20px] text-center">
                <img style={{
                    height : "120px"
                }} src={avatar} />
            </div>
            <div className="challengeTitle mb-4 mt-4">
                <h3 style={{
                    color : brandColor
                }} className={`text-[32px] text-center font-primarybold`}>{challenge.title}</h3>
            </div>
            <div className="brandDetails flex gap-4">
           
            </div>

            <div className="challengeDetails">
                <p><strong>About Campaign</strong></p>
                <div style={space1}></div>

                <p style={{fontWeight : 500, textAlign : "justify", listStyleType : "disk"}}
                dangerouslySetInnerHTML={{ __html: challenge.description }}>
                </p>
                <div style={space}></div>
                <p><strong>Guidelines</strong></p>
                <div style={space1}></div>
                <p>
                    <ol style={{listStyleType : "number", paddingInlineStart : "20px"}}>
                        {
                            guidelines.map((item : any, i:number)=> (
                                <li key={i}>{item}</li>
                            ))
                        }
                  </ol>
                </p>
                <div style={space}></div>
                <p><strong>Valid through: </strong> {challenge.closingDate}</p>
                
                <div style={space}></div>
                <p style={{
                    textTransform : "capitalize"
                }}><strong>Reward Type: </strong> {challenge.rewardType ? challenge.rewardType.join(", "): ""}</p>

                <div style={space}></div>
                <p><strong>Reward: </strong> {challenge.prizeNarration}</p>
                

                <div style={space}></div>
                <div className="text-center">
                    <Button style={{
                        background : brandColor
                    }} onclick={()=>setShowForm1(true)} h="45px" containerClass={`text-white w-full max-w-[200px] `}  text="Join"/>
                </div>
                &nbsp;
                <div style={space}></div>

            </div>
        </div>

            <div className="container font-primary">
                <div style={space}></div>
                &nbsp;
            </div>
                {
                    uploadedFiles.length > 0 &&

                    <div className="container">
                        {
                            uploadedFiles.length == 2
                            ? 
                            <div className="grid md:grid-cols-2 ">
                            {
                                uploadedFiles.map((item : any, i : number)=> (
                                        <div>
                                            <a target="_blank" href={`${item.fileRedirectUrl ? (item.fileRedirectUrl.includes("http")? item.fileRedirectUrl : "//"+item.fileRedirectUrl) : "#?"}`}>
                                        <img src={item.secureUrl} alt="" />
                                        </a>
                                        </div>
                                ))
                            }
                        </div>
                            :

                            <><div>
                                <a target="_blank" href={`${uploadedFiles[0].fileRedirectUrl ? (uploadedFiles[0].fileRedirectUrl.includes("http")? uploadedFiles[0].fileRedirectUrl : "//"+uploadedFiles[0].fileRedirectUrl) : "#?"}`}>
                                    <img src={uploadedFiles[0].secureUrl} alt="" />
                                </a>
                        </div>
                        <div className="grid md:grid-cols-2 ">
                            {
                                uploadedFiles.map((item : any, i : number)=> (
                                    <>
                                    {
                                        i > 0 &&
                                        <div>
                                            <a target="_blank" href={`${item.fileRedirectUrl ? (item.fileRedirectUrl.includes("http")? item.fileRedirectUrl : "//"+item.fileRedirectUrl) : "#?"}`}>
                                        <img src={item.secureUrl} alt="" />
                                        </a>
                                        </div>
                                    }
                                    </>
                                ))
                            }
                        </div>
                        </>
                        }

                    </div>
                }
            <br />
            <Hr />
            
            <div className="mt-5">
                <h3 className="text-[16px] text-center font-primarybold text-blue dark:text-white">Powered By BEENTOS:</h3>
            </div>
            <div className="text-center w-full flex justify-center mt-4">
            {/* <img className="h-[95px]" src={Logo} /> */}
            </div>
            {/* <AppSection source="landing" /> */}
            <Footer source="landing" />
            </div>
    }
    </>

    )
}