import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/button";
import { bgYellow, Logo } from "../../components/images";
import Input from "../../components/input";
import instance from "../../helpers/axios";
import { generateToken } from "../../helpers/helpers";
import useDocumentTitle from "../../helpers/pageTitle";
import { encryptData } from "../../helpers/security";
import { ActionType } from "../../store/actions";
import posthog from "posthog-js";
const AdminLogin = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [err, setErr] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: ActionType.UPDATE_LAYOUT,
      payload: "none",
    });
  }, []);
  useEffect(() => {
    sessionStorage.removeItem("otp");
    try {
      dispatch({
        type: ActionType.LOGOUT_BRAND,
      });
      dispatch({
        type: ActionType.UPDATE_BRAND_DATA,
        payload: "",
      });
    } catch (error) {}
  }, []);

  const login = () => {
    dispatch({
      type: ActionType.LOGOUT_ADMIN,
    });
    setLoading(true);
    setErr("");
    if (email === "" || password === "") {
      setErr("");
      return false;
    }

    var postData = {
      userName: email,
      password: password,
    };

    instance({
      url: "/auth/login",
      method: "POST",
      data: postData,
    })
      .then((response) => {
        // console.log(response)
        var roles = response.data.data.user.adminRoles;
        if (roles.length == 0) {
          setErr("Invalid user account");
          return false;
        }
        localStorage.clear();
        sessionStorage.clear();
        // success
        var admin = response.data.data.token;
        posthog.identify(response?.data?.data?.user?._id, {
          email: response?.data?.data?.user?.email,
          name: 'ADMIN' + response?.data?.data?.user?.fullName,
          ...(response?.data?.data?.user || {}),
        });
        dispatch({
          type: ActionType.LOGIN_ADMIN,
          payload: admin,
        });
        setTimeout(() => {
          navigate("/admin");
        }, 500);
      })
      .catch((err) => {
        try {
          // show error
          var e = err.response.data.message;
          if (e === "Your email is not verified, please verify your email") {
            navigate(`/otp/brand?token=${generateToken(20)}&u=${email}`);
          }
          setErr(err.response.data.message);
        } catch (error) {
          setErr("Oops, an error occur, please try again");
        }
      })
      .finally(() => {
        // unset variables
        setLoading(false);
      });
  };
  useDocumentTitle("BRAND SIGN IN ");
  return (
    <div className="h-[100vh] relative">
      <img src={bgYellow} className="h-[100vh] w-full object-fill" alt="" />
      <div className="h-[100vh] absolute top-0 left-0 flex w-full justify-center items-center">
        <div className="login-container shadow mx-auto w-[95%] max-w-[420px] p-[30px] text-center bg-white rounded-[20px]">
          <div className="bg-yellow mx-auto w-[85px] h-[75px] rounded-[8px] mb-[20px] flex items-center justify-center p-2">
            <Link to={"/"}>
              <img src={Logo} className="h-[72px]  object-contain" alt="" />
            </Link>
          </div>
          <h3 className="text-blue font-primarybold  text-[26px] ">
            Admin Panel
          </h3>
          <Input
            placeholder="Admin Email"
            value={email}
            setValue={setEmail}
            containerClass="mt-[22px]"
          />
          <Input
            placeholder="Password"
            type="password"
            value={password}
            setValue={setPassword}
            containerClass="mt-[26px]"
          />
          <div className="flex justify-between p-2 mt-[22px]">
            <label htmlFor="remember" className="">
              <input
                type="checkbox"
                className="border-0 peer outline-0 bg-transparent"
                name="remember"
                id="remember"
                value={1}
              />{" "}
              &nbsp;
              <span className="font-bold text-blue opacity-50">
                Remember me
              </span>
            </label>
            <Link
              className="font-bold font-primary text-blue"
              to={"/forgot-password"}
            >
              Forgot password?
            </Link>
          </div>
          <Button
            loading={loading}
            onclick={login}
            containerClass={`w-full bg-blue text-yellow font-bold hover:opacity-75 mt-[20px] ${
              loading ? "opacity-75" : ""
            }`}
          />
          {err !== "" ? (
            <p className="text-red-700 text-center mt-2">{err}</p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
