import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { needHelp, verifyIcon } from '../../../components/images';
import useDocumentTitle from '../../../helpers/pageTitle';
import { decryptData, encryptData } from '../../../helpers/security';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import Steps from '../../../components/signup/steps';
import { ChallengeSteps } from '../../../components/brand';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { brandCategories, plans } from '../../../data';
import instance from '../../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../helpers/loader';
import SubscriptionModal from '../../../components/brand/subscription_modal';
import ls from 'localstorage-slim'
import axios from 'axios';
const CreateChallenge3: React.FC = () => {
    const params = useParams();
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const [category, setCategory] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [title, setTitle] = useState('')
    const [countryCode, setCountryCode] = useState('US')
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [challenge, setChallenge] = useState("")
    const [allList, setAllList] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [userloc, setUserloc] = useState<any[]>([])
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
        showEnterCoupon()
    }, [])

    const showEnterCoupon = () => {
        var coupon: any = ls.get("bcoupon", { decrypt: true })
        console.log(coupon)
        if (coupon) {
            if (coupon.isValid) {
                if (coupon?.couponType.includes("Cash")) {

                }
            }

        }
    }
    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`
    }
    useEffect(() => {
        if (!loggedInBrand || loggedInBrand == "") {
            // nobody is logged in
            navigate("/brand/login");
        }
    }, [loggedInBrand])

    useEffect(() => {
        instance({
            method: "GET",
            url: `/subscription/subscription-type/?_orderBy=createdAt&_order=desc`,
            headers: headers
        }).then((response) => {
            var docs = response.data.data.docs;
            setAllList(docs);
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false);
        })

    }, [])
    const fetchLocation = async () => {
        let response = await axios.get("https://geolocation-db.com/json/");
        // console.log(response);
        if (response.data) {
            var ccd = response.data.country_code;
            if (ccd == "NG") setCountryCode(ccd)
            // setCountryCode("US")
        }
        setCountryCode("US")
    }
    const { pathname, search } = useLocation();

    useEffect(() => {
        fetchLocation()
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('challenge=') !== false) {
                    const cha = s.replace('challenge=', '')
                    setChallenge(cha)

                    instance({
                        url: '/subscription/brand',
                        method: "GET",
                        headers: headers,
                    })
                        .then((response) => {
                            console.log(response)
                        }).catch((err) => {

                        })


                }
            })
        }

    }, [])

    const subForBrand = (id: string, amount: number, sub: string, days = 30, useCoupon = "") => {
        setIsLoading(true);
        var data = {
            "subscriptionType": id,
            "autoRenewal": false,
            "subscriptionPeriod": days,
            "paymentStatus": "pending",
            "priceCurrency": countryCode == "NG" ? "NGN" : "USD"
        }

        instance({
            url: '/subscription',
            method: "POST",
            headers: headers,
            data: data
        })
        .then((response) => {
            // console.log(response)
            // setIsLoading(false)
            // return false;
            var paramsid = response.data.data._id
            var url = "";
            if (params.page) {
                url = "http://beentos.com" + `/verify-subscription-payment/${paramsid}`
            } else {
                url = "http://beentos.com" + `/verify-subscription-payment/${paramsid}?challenge=${challenge}`
            }
            // console.log(url)
            var newData = {
                "currency": countryCode == "NG" ? "ngn" : "usd",
                "pricePerUnit": amount,
                "quantity": days,
                "productType": "subscription",
                "productId": paramsid,
                "productTypeId": id,
                "productName": sub,
                "localCurrency": countryCode == "NG" ? "NGN" : "USD",
                "returnUrl": url,
                "couponCode": useCoupon,
                "priceCurrency": countryCode == "NG" ? "NGN" : "USD"
            }

            // return false;
            instance({
                method: "POST",
                url: countryCode == "NG" ? "/payment/flutterwave/create" : `/payment`,
                headers: headers,
                data: newData
            }).then((response) => {
                console.log(response)
                if (response.data.message == "Payment totally covered by coupon") {
                    // toast.success(response.data.message)
                    if (challenge) {
                        navigate("/brand/challenge/create-step-payment?challenge=" + challenge)
                    } else {
                        navigate("/brand/dashboard")
                    }
                } else {

                    window.location.href = response.data.data.paymentLink;
                }
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
                if (err.code == "ERR_NETWORK") {
                    alert("No internet connection")
                }
                if (err.response.data.statusCode === 403) {
                    navigate('/brand/login');
                    return false;
                }

                toast.error("Something went wrong")
            })

        }).catch((err) => {
            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }
        })
    }

    useDocumentTitle('CREATE CHALLENGE ')
    const [selectedPrice, setSelectedPrice] = useState(10)
    const [subscription, setSubscription] = useState("Gold Package")
    const [selectedId, setSelectedId] = useState("Gol")
    // const a = []
    return (

        <div className='px-[5px] py-[50px]'>
            <ToastContainer />
            {
                isLoading ?
                    <Loader />
                    :
                    <div className="container mx-auto">
                        <div className="text-blue font-primarybold text-[18px]"> {params.page ? "Subscription" : "Create New Campaign"}</div>
                        {showSubscriptionModal && <SubscriptionModal countryCode={countryCode} selectedPrice={selectedPrice} subscription={subscription} selectId={selectedId} close={() => {
                            // close me
                            setShowSubscriptionModal(false)
                        }} proceed={(selected: number, useCoupon: string) => {
                            subForBrand(selectedId, selectedPrice, subscription, selected, useCoupon)
                        }} />}
                        <div className="py-[20px] ">
                            <br />
                            <div className="lg:mt-[10px">
                                <div className="text-center mb-[15px] w-full">
                                    <h3 className='text-blue text-[30px] font-bold font-primarybold'>Ready to start Generating awesome <br /> content?</h3>
                                    <p className='font-bold font-primary text-[#0B0149]'>Choose the package that best suit you</p>
                                    <br />

                                </div>
                                <div className="mt-4 w-[90%] mx-auto">
                                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
                                        {
                                            allList.map((p: any, i) => (
                                                <div key={i} className={`rounded-[20px] bg-white p-[4px] ${i === 1 ? 'bg-yellow' : 'bg-white'}`}>
                                                    <div className={`bg-yellow rounded-[20px] p-1`}>
                                                        <div className="bg-white rounded-[20px] p-2 lg:p-3">
                                                            <div className="text-center">
                                                                <h3 className="text-blue text-[24px] font-primarybold">{p.name}</h3>
                                                                {/* <h3 className="text-blue font-primarybold text-[18px] ">{countryCode == "NG" ? "NGN" : "$"} {countryCode == "NG" ? p.ngnPriceRatePerDay : p.priceRatePerDay}</h3> */}
                                                            </div>
                                                            <div className="h-[3px] bg-blue my-[10px]"></div>
                                                            {
                                                                p.detail ?
                                                                    ((p.detail).split(";")).map((feature: any, index: number) => (
                                                                        <div key={index} className='flex items-center my-[20px] justify-between'>
                                                                            <div className="flex">
                                                                                <img src={verifyIcon} className="h-[24px] mr-3" alt="" />
                                                                                <div>
                                                                                    {feature}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )) : ''}

                                                            <Button onclick={() => {
                                                                setSelectedId(p._id),
                                                                    setSelectedPrice(countryCode == "NG" ? p.ngnPriceRatePerDay : p.priceRatePerDay)
                                                                setSubscription(p.name)
                                                                setShowSubscriptionModal(true)
                                                            }} containerClass='bg-blue text-yellow w-full' text="Choose Plan" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}

export default CreateChallenge3