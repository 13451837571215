import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { iCreate } from '../../models/model'
import Button from '../button'
import { Success } from '../images'

const Choose = ({ hasSub = "pending", isBudget = true, close, challenge, brandId }: iCreate) => {
    const navigate = useNavigate()

    // const a = []
    return (
        <div>
            <div className='fixed top-0 left-0 w-full z-[999] h-[100vh]'>
                <div className="h-[100vh] w-full flex items-center justify-center">
                    <div onClick={() => close()} className='back_drop fixed top-0 left-0 w-full bg-[#F0F0F4] h-[100vh] opacity-80'></div>
                    {
                        window.location.href.includes("admin") ?
                            <div className='bg-white shadow-lg rounded-[20px] w-[95%] lg:w-[460px] p-3 relative'>
                                <div className="mt-[20px] lg:mt-[10px] text-center">
                                    <h3 className="text-blue text-[20px] font-primary font-bold leading-tight">You are almost there!</h3>
                                    <p className='text-blue max-w-[280px] mt-[10px] mx-auto text-[14px] opacity-80'>Click the button below to proceed</p>
                                </div>
                                <div className="my-[25px] h-[1px] bg-[#7E799F80] w-full"></div>
                                <div>
                                    <div className="w-full mb-[20px] text-center">
                                        <Button text="Continue" onclick={() => {
                                            navigate(`/admin/create-challenge/${brandId}/create-step-payment?challenge=${challenge}`)
                                        }} containerClass="w-[80%] border-blue text-blue mt-[0px]" />
                                    </div>
                                </div>
                            </div>

                            :
                            <div className='bg-white shadow-lg rounded-[20px] w-[95%] lg:w-[460px] p-3 relative'>
                                <div className="mt-[20px] lg:mt-[10px] text-center">
                                    {
                                        hasSub == "pending" ?
                                        <h3 className="text-blue text-[20px] font-primary font-bold leading-tight">Please subscribe to complete <br /> this Campaign</h3>
                                        :
                                        <h3 className="text-blue text-[20px] font-primary font-bold leading-tight">{isBudget ? "Proceed to Pay the Campaign Budget" : "Almost Done" }</h3>
                                    }
                                      
                                        <p className='text-blue max-w-[280px] mt-[10px] mx-auto text-[14px] opacity-80'>Click the button below to proceed {isBudget ?  "to payment" : ""}</p>
                                </div>
                                <div className="my-[25px] h-[1px] bg-[#7E799F80] w-full"></div>

                                <div>
                                    {/* <div className="w-full mb-[20px] text-center">
                                            <Button text="Proceed" onclick={() => {
                                                navigate(`/brand/challenge/create-step-payment?challenge=${challenge}`)
                                            }} containerClass="w-[80%] border-blue text-blue mt-[0px]" />
                                    </div> */}

                                    <div className="w-full mb-[20px] text-center">
                                        {
                                            hasSub == "paid" ?
                                            <Button text="Proceed" onclick={() => {
                                                navigate(`/brand/challenge/create-step-payment?challenge=${challenge}`)
                                            }} containerClass="w-[80%] border-blue text-blue mt-[0px]" />
                                            :
                                            <Button text="Subscribe" onclick={() => {
                                                navigate( `/brand/challenge/create-step-6?challenge=${challenge}`)
                                            }} containerClass="w-[80%] bg-blue text-yellow mt-[0px]" />
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Choose