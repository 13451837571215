import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { needHelp } from '../../components/images';
import useDocumentTitle from '../../helpers/pageTitle';
import { decryptData } from '../../helpers/security';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import { BiDotsVertical } from 'react-icons/bi';
import Button from '../../components/button';
import ChallengeCard from '../../components/brand/challengeCard';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Pagination from '../../components/pagination';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import ButtonLoader from '../../helpers/button_loader';
import { Completed } from '../../components/brand';
// import { ShareModal } from '../../components/brand';
import ls from 'localstorage-slim';

const VerifyChallengePayment: React.FC = () => {
    const params = useParams();
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [challenges, setChallenges] = useState([])
    const [Draft, setDraft] = useState([])
    const [brand, setBrand] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [offset, setOffset] = useState(0)
    const [page, setPage] = useState(1)
    const [type_, setType_] = useState('cha')
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
    }, [])

    // get brand profile
    useEffect(() => {
       if(window.location.pathname.includes("subscription")) setType_("sub")
        setTimeout(() => {
            setIsLoading(false)
        }, 5000);
        

    }, [])
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInBrand}`
          }
      instance({
        method : "GET",
        url : "/subscription/brand/subscription",
        headers : headers
      }).then((response)=>{
        console.log(response)
        var data = response.data.data;
        ls.set("bsub", data, {encrypt : true});
      }).catch((err)=>{
        ls.set("bsub", null, {encrypt : true});
      })
    }, [])
    

    // /api/challenge/brand/challenges/:creationStatus/:offset/:limit
    useDocumentTitle('BRAND Generate Campaign Invoice')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px]">
                <ToastContainer />
                {/* wrapper */}
                <div className="h-[30vh]"></div>
                <div className="flex justify-center">
                    {
                        isLoading 
                        ?
                    <div className="min-h-[100px] flex justify-center items-center bg-blue text-center shadow w-[95%] rounded shadow max-w-[200px]">
                        <ButtonLoader />
                        <p className="text-center text-white">Please wait</p>
                    </div>
                        :
                        <Completed source={type_} />
                    }
                </div>
            </div>
        </div>
    )
}

export default VerifyChallengePayment