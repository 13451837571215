import React from 'react'
import ButtonLoader from '../helpers/button_loader'
import {iButton} from '../models/model'

const Button = ({style, containerClass, text = 'Submit', onclick, loading = false, h='', img = ""} : iButton) => {
  return (
    <button  onClick={onclick} className={`control border border-blue pl-2 h-[50px] rounded-[45px] ${containerClass}`} style={style ? style : {height: h == '' ? '50px' : h}}>
        {
          !loading && img !== "" ? 
          <span className="mr-3">
          <img src={img} alt="" />
          </span>
           : ""
        }
        {loading ? 
        <div className="flex justify-center text-center">
          <ButtonLoader />
        </div>
        : text}
    </button>
  )
}

export default Button