import React from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css'
import { CDropdown, CDropdownMenu, CDropdownToggle, CDropdownItem } from '@coreui/react';
import { iInput } from '../models/model';
import { capitalizeFirstLetter } from '../helpers/helpers';

function CoreDropdown (data : iInput) {
  const {source = "main", extendedClasses = 'dark:text-white text-blue', placeholder, items, setValue, value, containerClass, className, type="text", isDropDown, showError = false, err = "", prevErr, hasPt =true, posErr = "", pos="", accept="", multi=false, w=''} = data;
  return (
    <React.Fragment>
        <CDropdown className={`w-full ${prevErr ? 'mt-[20px]' : ''}`} >
            <CDropdownToggle className={`${containerClass} font-primary font-semibold`} style={{borderRadius : '45px', width: '100%', display : 'flex', alignItems: 'center', justifyContent : source  == "challenge" || source == "main" ? 'space-between' : 'space-evenly', color : extendedClasses.includes("yellow") ? 'yellow' : '#0B0149'}} color='transparent'> <span className={`font-semibold font-primary ${value === '' || value === "Select" ? 'font-medium is-dirty peer text-gray opacity-80' : extendedClasses}`}>{value == "" ? placeholder : capitalizeFirstLetter(value)}</span></CDropdownToggle>
            <CDropdownMenu className='w-full'>
                {
                    items.map((item : any, i : number)=>(
                        <CDropdownItem className='w-full' selected={value == item.value ? true : false} onClick={(e)=>{
                            e.preventDefault();
                            setValue(item.value)}} key={i} style={{fontWeight: item.value === value ? 500 : 'normal', color:'#0B0149'}} href="#">{capitalizeFirstLetter(item.value)}</CDropdownItem>
                    ))
                }
            </CDropdownMenu>
        </CDropdown>
        {
          showError ? <div className='w-full pl-2 flex justify-start'>
                <p className="m-0 text-red-700"> <small>{err}</small> </p>
            </div>
            : ''
        }
    </React.Fragment>
  )
}

export default CoreDropdown