import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { needHelp } from '../../../components/images';
import useDocumentTitle from '../../../helpers/pageTitle';
import { decryptData } from '../../../helpers/security';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import Steps from '../../../components/signup/steps';
import { ChallengeSteps } from '../../../components/brand';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { votingCategories, brandCategories, rewardTypes, locations, challengeTypes } from '../../../data';
import { ValidDate } from '../../../helpers/helpers';

const CreateChallenge2: React.FC = () => {
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);

    const [maxLength, setMaxLength] = useState('')
    const [maxLengthERr, setMaxLengthERr] = useState('')
    const [showMaxLengthErr, setShowMaxLengthErr] = useState(false)

    const [budget, setBudget] = useState('')
    const [budgetErr, setBudgetErr] = useState('')
    const [showBudgetErr, setShowBudgetErr] = useState(false)

    const [winner, setWinner] = useState('')
    const [winnerErr, setWinnerErr] = useState('')
    const [showWinnerErr, setShowWinnerErr] = useState(false)

    const [ctype, setCtype] = useState('')
    const [ctypeErr, setCtypeErr] = useState('')
    const [showCtypeErr, setShowCtypeErr] = useState(false)

    const [valid, setValid] = useState('')
    const [validErr, setValidErr] = useState('')
    const [showValidErr, setShowValidErr] = useState(false)

    const [fileSelected, setfileSelected] = useState('')
    const [fileSelectedErr, setfileSelectedErr] = useState('')
    const [showfileSelectedErr, setShowfileSelectedErr] = useState(false)

    const [file, setFile] = useState<File>()
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [title, setTitle] = useState('')
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [challenges, setChallenges] = useState([])

    const { challengeData } : any = useTypedSelector((state) => state.appReducer);

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
     
    }, [])


    useEffect(() => {
        if(!challengeData) {
            navigate("/brand/dashboard");
        }else{
          var data = JSON.parse(challengeData);
        //   setTitle(data.title)
        setValid(data.valid)
        setWinner(data.winner)
        setMaxLength(data.maxLength)
        setBudget(data.budget)
        setfileSelected(data.backgroundMusic)
        setCtype(data.categoryType)
        }
      }, [challengeData])


      useEffect(() => {
        console.log(valid)
    }, [valid])
    useEffect(() => {
        if (!loggedInBrand || loggedInBrand == "") {
            // nobody is logged in
            navigate("/brand/login");
        }
    }, [loggedInBrand])
    // {
    //     // navigate('/brand/challenge/create-step-3')
    // }
    const fileUploadChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        // setFile(e.target.files[0])
        let file_ = e.target.files ? e.target.files[0] : null;
        if(file_){
            setFile(file_)
            // sessionStorage.setItem('c_file', file_)
            setfileSelected(file_.name);
        }else{
            setfileSelected("")            
        }
    }

    const proceed = () => {
        let passed = true;
        setShowMaxLengthErr(false)
        setShowWinnerErr(false)
        setShowValidErr(false)
        setShowCtypeErr(false)
        setShowfileSelectedErr(false)
        setLoading(true)
        setShowBudgetErr(false)
        setTimeout(() => {
            if(maxLength == ""){
                setShowMaxLengthErr(true)
                setMaxLengthERr("Length must be in seconds")
                passed = false;
            }


            if(budget == ""){
                setShowBudgetErr(true)
                setBudgetErr("Please input challenge budget")
                passed = false;
            }


            if(winner == ""){
                setShowWinnerErr(true)
                setWinnerErr("No. of winners must be at least 10")
                passed = false;
            }else{
                if(parseInt(winner) < 10){
                    setShowWinnerErr(true)
                    setWinnerErr("No. of winners must be at least 10")
                    passed = false;
                }
            }

       
            if(ctype == ""){
                setShowCtypeErr(true)
                setCtypeErr("Please select category")
                passed = false;
            }

            if(valid == ""){
                setShowValidErr(true)
                setValidErr("Please select date")
                passed = false;
            }else if(!ValidDate(valid)){
                setShowValidErr(true)
                setValidErr("Date cannot be in the past.")
                passed = false;
            }

            if(fileSelected == ""){
                setfileSelectedErr("Please enter file url")
                setShowfileSelectedErr(true)
                passed = false;
            }

            if(!passed){
                setLoading(false)
            }else{

                var data = JSON.parse(challengeData);
                data = {...data, backgroundMusic : fileSelected, maxLength : maxLength, budget : budget, winner : winner, categoryType : ctype, valid : valid, }
                dispatch({
                  type : ActionType.UPDATE_CHALLENGE,
                  payload : JSON.stringify(data)
                })
                navigate('/brand/challenge/create-step-check')

            }
        }, 2000);
    }

    useDocumentTitle('STEP 3 - CREATE CHALLENGE')
    // const a = []
    return (
        <div className='px-[5px] py-[50px]'>
            <div className="container mx-auto">
                <div className="text-blue font-primarybold text-[18px]">Create New Campaign</div>

                <div className="py-[40px] bg-white shadow-lg rounded ">
                    <ChallengeSteps current={3} completed={[1,2]} />
                    <br />
                    <div className="lg:mt-[50px">
                        <br />
                        <div className="text-center my-[20px] w-full">
                        <h3 className='text-blue text-[24px] font-bold font-primary'>What are your Campaign terms and conditions?</h3>
                        </div>
                        <div className="mt-4 w-[80%] mx-auto">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                                <div className="my-2">
                                    <label className='font-primary text-blue' htmlFor="">Total Budget</label>
                                    <Input err={budgetErr} type="number" showError={showBudgetErr} value={budget} setValue={setBudget} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='$0.00' />
                                </div>
                                <div className="my-2">
                                    <label className='font-primary text-blue' htmlFor="">Max Video Length in Seconds</label>
                                    <Input err={maxLengthERr} showError={showMaxLengthErr} value={maxLength} setValue={setMaxLength} type="number" containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='10' />
                                </div>

                                <div className="my-2">
                                    <label className='font-primary text-blue' htmlFor="">Numbers of winners</label>
                                    <Input value={winner} setValue={setWinner} err={winnerErr} showError={showWinnerErr} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='Min of 10' />
                                </div>

                                <div className="my-2">
                                    <label className='font-primary text-blue' htmlFor="">Campaign type</label>
                                    <Input pos='top-[50%]' posErr='top-[30%]' prevErr={false} showError={showCtypeErr} err={ctypeErr} isDropDown={true} items={challengeTypes} placeholder="Select and option" value={ctype} setValue={setCtype} containerClass="mt-[4px] border-graylight border-2 shadow-sm mt-0 bg-[#F0F0F4]" />
                                </div>

                                <div className="my-2">
                                    <label className='font-primary text-blue' htmlFor="">Valid Thru</label>
                                    <Input showError={showValidErr} err={validErr} value={valid} setValue={setValid} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" type="date" placeholder='12/12/2024' />
                                </div>

                                <div className="my-2">
                                    <label className='font-primary text-blue' htmlFor="">Music</label>
                                    {/* <Input  showError={showfileSelectedErr} err={fileSelectedErr} value={fileSelected} setValue={fileUploadChange} type="file" accept='.mp3' containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='Select a file' /> */}
                                    <Input value={fileSelected} setValue={setfileSelected} err={fileSelectedErr} showError={showfileSelectedErr} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='Enter the file URL' />
                                </div>
                            </div>

                            <div className="text-center flex justify-end w-full">
                            <Button text="Next" loading={loading} onclick={()=>proceed()} containerClass="w-[150px] bg-blue text-yellow mt-[30px]" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateChallenge2