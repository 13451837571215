import React from 'react'
import { Link } from 'react-router-dom'
import useDocumentTitle from '../helpers/pageTitle'

function Terms() {
    useDocumentTitle('Terms and Conditions')
    return (
        <div className="mx-auto max-w-[1200]">
            <div className="container mx-auto mt-[50px] dark:text-graylight">
                <div className="container mx-auto p-3 font-primary">
                    <div className="title my-3">
                        <h3 className="text-[32px] font-primarybold text-blue dark:text-graylight">
                            Terms and Conditions
                        </h3>
                    </div>
                    <p className='mt-1'><br /></p>
                    <p className='mt-1'><br /></p>
                    <h3 style={{ fontSize: "20px" }}><strong><span style={{ fontSize: "13pt" }}>Terms and Conditions</span></strong></h3>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Last updated: June 29, 2024</span></strong></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>Please read these terms and conditions carefully before using Our Service.</span></p>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Interpretation and Definitions</span></strong></p>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Interpretation</span></strong></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</span></p>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Definitions</span></strong></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>For the purposes of these Terms and Conditions:</span></p>
                    <ul className='ml-4 mt-1'>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "14px" }}>Company</span></strong><span style={{ fontSize: "14px" }}>&nbsp;(referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Beentos, Toronto, Canada.</span></p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "14px" }}>Service</span></strong><span style={{ fontSize: "14px" }}>&nbsp;refers to the Application or the Website or both.</span></p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "14px" }}>Website</span></strong><span style={{ fontSize: "14px" }}>&nbsp;refers to Beentos, accessible from</span><a href="http://www.beentos.com"><span style={{ fontSize: "14px" }}>&nbsp;</span><u><span style={{ color: "#1155cc", fontSize: "14px" }}>www.beentos.com</span></u></a><span style={{ fontSize: "14px" }}>.</span></p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "14px" }}>You</span></strong><span style={{ fontSize: "14px" }}>&nbsp;means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</span></p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "14px" }}>Account</span></strong><span style={{ fontSize: "14px" }}>&nbsp;means a unique account created for You to access our Service or parts of our Service.</span></p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "14px" }}>Content</span></strong><span style={{ fontSize: "14px" }}>&nbsp;refers to content such as text, images, or other information that can be posted, uploaded, linked to, or otherwise made available by You, regardless of the form of that content.</span></p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "14px" }}>Business User</span></strong><span style={{ fontSize: "14px" }}>&nbsp;refers to businesses using our platform to create marketing campaigns.</span></p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "14px" }}>User</span></strong><span style={{ fontSize: "14px" }}>&nbsp;refers to individuals creating content for companies on the Beentos platform.</span></p>
                        </li>
                    </ul>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Acknowledgment</span></strong></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</span></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others who access or use the Service.</span></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>By accessing or using the Service, You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions, then You may not access the Service.</span></p>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Creating Marketing Campaigns</span></strong></p>
                    <ul className='ml-4 mt-1'>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "16px" }}>Eligibility</span></strong><span style={{ fontSize: "14px" }}>: To use our Service, Business Users must ensure that their marketing campaigns comply with all applicable laws and regulations.</span></p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "16px" }}>Content Responsibility</span></strong><span style={{ fontSize: "14px" }}>: Business Users are responsible for all content they upload to our platform. This includes ensuring that the content does not violate any intellectual property rights, privacy rights, or any other rights.</span></p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "16px" }}>Right to Cancel</span></strong><span style={{ fontSize: "14px" }}>: We reserve the right to cancel any campaign at any time if we believe it violates any of our policies or applicable laws. In such cases, we will notify the Business User of the cancellation and the reasons for it.</span></p>
                        </li>
                    </ul>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Data Storage and Retention</span></strong></p>
                    <ul className='ml-4 mt-1'>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "16px" }}>Active Accounts</span></strong><span style={{ fontSize: "14px" }}>: Data uploaded by Business Users, their customers, or prospective customers will be stored only for accounts with an active subscription.</span></p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "16px" }}>Inactive Accounts</span></strong><span style={{ fontSize: "14px" }}>: Data may be deleted within 30 days if the account is inactive or without a subscription.</span></p>
                        </li>
                    </ul>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>User Content</span></strong></p>
                    <ul className='ml-4 mt-1'>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "16px" }}>Permission</span></strong><span style={{ fontSize: "14px" }}>: By checking this box, You grant Beentos and the sponsor company, along with its affiliates, permission to use your content for marketing and promotion purposes.</span></p>
                        </li>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><strong><span style={{ fontSize: "16px" }}>Compliance</span></strong><span style={{ fontSize: "14px" }}>: Additionally, You agree to abide by the terms and conditions, as well as the privacy policy outlined by Beentos.</span></p>
                        </li>
                    </ul>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Accounts</span></strong></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>When You create an account with us, You must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on our Service.</span></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with our Service or a third-party social media service.</span></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>You agree not to disclose Your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of Your account.</span></p>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Links to Other Websites</span></strong></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>Our Service may contain links to third-party websites or services that are not owned or controlled by the Company.</span></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>The Company has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.</span></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>We strongly advise You to read the terms and conditions and privacy policies of any third-party websites or services that You visit.</span></p>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Termination</span></strong></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>We may terminate or suspend Your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</span></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your account, You may simply discontinue using the Service.</span></p>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Limitation of Liability</span></strong></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven&apos;t purchased anything through the Service.</span></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</span></p>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Governing Law</span></strong></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</span></p>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Disputes Resolution</span></strong></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</span></p>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Changes to These Terms and Conditions</span></strong></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material we will make reasonable efforts to provide at least 30 days&apos; notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</span></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the Website and the Service.</span></p>
                    <p className='mt-1'><strong><span style={{ fontSize: "20px" }}>Contact Us</span></strong></p>
                    <p className='mt-1'><span style={{ fontSize: "14px" }}>If you have any questions about these Terms and Conditions, You can contact us:</span></p>
                    <ul className='ml-4 mt-1'>
                        <li style={{ listStyleType: "disc", fontSize: "14px" }}>
                            <p className='mt-1'><span style={{ fontSize: "14px" }}>By email: support@beentos.com</span></p>
                        </li>
                    </ul>
                    <p className='mt-1'><br /></p>
                    <p className='mt-1'><br /></p>
                </div>
            </div>

        </div>
    )
}

export default Terms 