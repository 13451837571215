import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import { Logo, appleEn, arrowBack, bgWhite, bgYellow, googleEn } from "../../components/images";
import Input from '../../components/input';
import Steps from '../../components/signup/steps';
import useDocumentTitle from '../../helpers/pageTitle';
import { brandCategories } from '../../data';
import { isValidUrl, validateEmail } from '../../helpers/helpers';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../store/actions';
import { useTypedSelector } from '../../hooks/useSelector';
import { CoreDropdown } from '../../components';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../helpers/loader';



const ResetPasswordUser = () => {
  const [email, setEmail] = useState<string>('')

  const [loading, setLoading] = useState(false)

  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showEmailErr, setshowEmailErr] = useState(false)
  const [emailErr, setEmailErr] = useState("")
  const [joined, setJoined] = useState('')
  const [password, setPassword] = useState("")
  const [token, setToken] = useState("")
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch({
      type: ActionType.UPDATE_LAYOUT,
      payload: "none"
    })
    sessionStorage.removeItem('otp')
    try {
      dispatch({
        type: ActionType.LOGOUT_BRAND,
      })
    } catch (error) {

    }
  }, [])

  useEffect(() => {
    detectDeviceType();
  }, []);

  const detectDeviceType = () => {
    const userAgent = navigator.userAgent;
    const isAndroidDevice = /Android/i.test(userAgent);
    const isIPhoneDevice = /iPhone/i.test(userAgent);

    if (isAndroidDevice) {
      window.location.assign("https://play.google.com/store/apps/details?id=com.beentosapp")
    }

    if (isIPhoneDevice) {
      window.location.assign("https://apps.apple.com/app/beentos/id6450564973")
    }
  };

  useDocumentTitle('RESET PASSWORD')
  return (
    <div className='h-[100vh] relative'>
      <ToastContainer />

      {
        isLoading ? <Loader /> :
          <div className='w-full'>
            <img src={bgYellow} className="h-[100vh] w-full object-fill" alt="" />
            <div className="h-[100vh] absolute top-0 left-0 flex w-full justify-center items-center">
              <div style={{background : "white"}} className="login-container text-center flex-col flex justify-center items-center relative shadow-lg w-[95%] max-w-[520px] p-[40px] px-[30px] lg:px-[60px] text-center bg-white rounded-[20px]">
                <Link to={'/'}>
                  <img src={Logo} className="h-[100px]" alt="" />
                </Link>
                <br />
                <p><i>Download now on</i></p>
                <div className="mb-3"></div>
                <a href="https://play.google.com/store/apps/details?id=com.beentosapp">
                  <img src={googleEn} />
                </a>
                <div className="mb-3"></div>


                <p><i>or</i></p>
                <div className="mb-3"></div>
                <a href="https://apps.apple.com/app/beentos/id6450564973">

                  <img src={appleEn} />
                </a>
                <div className="mb-3"></div>
              </div>

            </div>
          </div>
      }
    </div>
  )
}

export default ResetPasswordUser