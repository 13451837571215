import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SettingsAside, SettingsHeader } from '../../../components/brand';
import Button from '../../../components/button';
import { checked, notChecked, verifyIcon } from '../../../components/images';
import useDocumentTitle from '../../../helpers/pageTitle';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import ls from 'localstorage-slim';
import instance from '../../../helpers/axios';
import { formatCreatedAtDate, formatDate } from '../../../helpers/helpers';
import Pagination from '../../../components/pagination';


const Payment: React.FC = () => {
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const { search } = useLocation()
    const dispatch = useDispatch();
    const [page, setPage] = useState(1)
    const [bsub, setBsub] = useState<any>(null)
    const [subscriptionType, setsubscriptionType] = useState<any>(null)
    const [payments, setPayments] = useState([])
    const [allRecords, setAllRecords] = useState(0)
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
    }, [])

    useEffect(() => {
      var sub:any = ls.get("bsub", {decrypt : true});
      if(sub) {
        setsubscriptionType(sub.subscriptionType)
          setBsub(sub)
      } 
      
    }, [])
    

    useEffect(() => {
        var brandid = ls.get("app_brand", {decrypt : true})

        if(brandid){

            
            const headers = {
            'Authorization': `Bearer ${loggedInBrand}`
          }
      instance({
        method : "GET",
        url : "/payment/all?payerId="+brandid+"&page="+page,
        headers : headers
      }).then((response)=>{
        setPayments(response.data.data.docs)
        setAllRecords(response.data.data.totalDocs)
      }).catch((err)=>{

      })
        }
    }, [page])

    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if(search_.length > 0){
          search_.forEach(s => {
            if(s.includes('page=') !== false){
              const scrollTo = s.replace('page=','')
                setPage(parseInt(scrollTo))
              return false;
            }
          });
        }
    }, [search])
    useDocumentTitle('Payment SETTINGS')

    return (
        <div className='w-full'>
            <div className="px-[15px] h-100 relative font-primary flex max-w-[1440]">
                <SettingsAside />
                <div className='lg:container sm:mx-auto md:mx-auto flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                    <SettingsHeader title='Payment & Plans' sub_title='Manage your payment & plan details' />
                  
                    <div className="grid gap-3 lg:grid-cols-2">
                        <div className='my-[25px] bg-white rounded-[20px] shadow-lg '>
                            <div className="flex justify-between items-center p-[15px]">
                                <h3 className="text-blue font-primarybold">{bsub ? (subscriptionType ? subscriptionType.name : "Subscription") : "No plan found"}</h3>
                                <Button text={bsub ? "Active" : `No active plan`} onclick={null} containerClass={`border-blue ${bsub ? 'bg-blue text-yellow w-[100px]' : 'text-blue'} rounded-[10px] py-[1px] px-[2px] text-[12px]`} h='30px' />
                            </div>
                            <div className='border-blue bg-blue outline-blue opacity-25 rounded mt-[2px] h-[1px]'> </div>
                            <div className='my-3 p-[15px]'>
                                {
                                    bsub ?
                                    <>
                                        {
                                        subscriptionType ?
                                            ((subscriptionType.detail).split(";")).map((feature:any, index:number)=>(
                                            <div key={index} className='flex items-center my-[20px] justify-between'>
                                                <div className="flex">
                                                    <img src={verifyIcon} className="h-[24px] mr-3" alt="" />
                                                    <div>
                                                        {feature}
                                                    </div>
                                                </div>
                                            </div>
                                        )) : ''}
                                    </>
                                    :
                                    <>
                                    <p className='text-blue opacity-80 text-[14px]'>Choose and buy a subscription plan today </p>
                                    <div className="flex justify-between w-full mt-2">
                                        <strong className='text-blue font-primarybold'>Starting from</strong>
                                        <Button text='$99' onclick={null} containerClass='border-blue bg-blue text-yellow w-[50px] rounded-[40px] py-[1px] px-[10px] text-[12px]' h='30px' />
                                    </div>
                                    </>
                                }
                            </div>
                            <div className='border-blue bg-blue outline-blue opacity-25 rounded mb-[2px] h-[1px]'> </div>
                            
                            <div className="flex justify-end p-3">
                                <Button text={bsub ? "Upgrade" : 'Subscribe Now' } onclick={()=>{
                                    navigate("/brand/subscription/plans")
                                }} containerClass='border-blue bg-blue text-yellow w-full max-w-[130px] rounded-[40px] py-[1px] px-[10px] text-[12px]' h='30px' />
                            </div>
                        </div>

                        <div className='my-[25px] bg-white rounded-[20px] shadow-lg hidden'>
                            <div className=" p-[15px]">
                                <h3 className="text-blue font-primarybold">Payment Plan</h3>
                                <p className='text-blue opacity-80 text-[14px]'>Change how you want to pay your plan</p>
                            </div>
                            <div className='my-3 border-[#F0F0F4] border-2 h-[70px] flex items-center justify-center mx-[15px] p-[15px]'>
                                <p className='text-blue opacity-80 text-[14px]'>Choose and buy a subscription plan today </p>
                            </div>
                            <div className="flex justify-end pt-3 px-3">
                                <Button text='Edit' onclick={()=>navigate('/brand/settings/payment/methods')} containerClass='border-blue bg-blue text-yellow w-full max-w-[130px] rounded-[40px] py-[1px] px-[10px] text-[12px]' h='30px' />
                            </div>
                        </div>

                    </div>

                    <div className='my-[25px] bg-white rounded-[20px] shadow-lg '>
                        <div className="flex justify-between items-center p-[15px]">
                            <div>
                                <h3 className="text-blue font-primarybold">Billing History</h3>
                                {/* <p className="text-blue opacity-80 text-[12px]">Download your plan receipt</p> */}
                            </div>
                            {/* <Button text='Download all' onclick={null} containerClass='border-blue text-blue rounded-[10px] py-[1px] px-[10px] text-[12px]' h='30px' /> */}
                        </div>
                        <div className='border-blue bg-blue outline-blue opacity-25 rounded mt-[2px] h-[1px]'> </div>

                        <div className="mt-3 p-2">
                            {
                                payments.length == 0 ?
                                <div className="h-[400px] flex items-center justify-center">
                                    <p className="text-blue opacity-80 text-[12px]">No history</p>
                                </div> 
                                :
                                <>
                                
                            <table className="border-collapse p-3 text-sm table-auto w-full p-3">
                                        <thead className='w-full text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                                            <tr>
                                                <th>ID</th>
                                                {/* amount, type, item, createdAt */}
                                                <th className='text-left'>Item</th>
                                                <th className='text-left'>Type</th>
                                                <th className='text-left'>Amount</th>
                                                <th className='text-left'>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody className='p-2'>
                                            {
                                                payments.map((item : any, i : number)=>(
                                                    <tr key={i} className='p-2 text-[#0B014980] border-spacing-2' style={{ height: '70px' }}>
                                                    <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} w-[50px]`}>{i+1}</td>
                                                    <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} max-w-[200px]`}>{(item.productName).substr(0, 50)}</td>
                                                    <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.productType}</td>
                                                    <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.amount}</td>
                                                    <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{formatDate(formatCreatedAtDate(item.createdAt), 1)}</td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                             </table>

                             <Pagination limit={10} length={allRecords} page={page} />

                             </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payment;