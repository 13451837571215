import React, { useEffect, useState } from 'react'

import { Carousel } from 'flowbite-react';

// Import Swiper styles
import 'swiper/css';
import { Logo5 } from '../images';
import instance from '../../helpers/axios';
import { FaqCard } from '../brand';
function Faqs() {
  const [faqs, setFaqs] = useState<any[]>([])
  const [allFaqs, setAllFaqs] = useState(0)
  const toggleFAQ = (index: number) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  useEffect(() => {

    instance({
      method: "GET",
      url: `/knowledge-base/all?_orderBy=createdAt&_order=desc`,
    }).then((response) => {
      console.log("response", response)
      var docs = response.data.data.docs;
      setFaqs(docs);
      setAllFaqs(response.data.data.totalDocs)
    }).catch((err) => {

    }).finally(() => {
    })
  }, [])
  return (
    <div className='mx-auto bg-white py-[60px]' id='brands_section'>
      <div className="container mx-auto">
        <div className="text-center">
          <h3 className='text-[36px] font-primarybold mb-[20px] lg:mb-[50px] text-blue dark:text-gray2'>Frequently Asked questions</h3>
        </div>
        <div className="font-primary p-4 bg-white rounded-[15px] shadow">
          {faqs.map((faq, index) => (
            <FaqCard source="home" isOpen={faq.open} answer={faq.detail} question={faq.subject} index={index} key={index} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Faqs