import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import Button from '../../components/button';
import { calendar, checkBox, checkBoxChecked, exporticon, who } from '../../components/images';
import { activeCount, graph1, graph2, totalCount, trendup } from '../../components/super_images';
import { CChart } from '@coreui/react-chartjs';
import { BsArrowDown, BsArrowUp, BsCaretDownSquare, BsCircleFill } from 'react-icons/bs';
import { BiCaretDown, BiDotsVertical } from 'react-icons/bi';
import { AiOutlineCaretDown, AiOutlineCheckSquare, AiOutlineEye } from 'react-icons/ai';
import { formatDate, getPreviousDate, getToday } from '../../helpers/helpers';
import { CoreDropdown } from '../../components';
import { resultSort } from '../../data';
import { FiSearch } from 'react-icons/fi';
import Pagination from '../../components/pagination';
import { DateRangePicker } from 'rsuite';
// import { ShareModal } from '../../components/brand';
import ls from "localstorage-slim"
const Brands: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const [selected, setSelected] = useState<number[]>([])
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(true)
    const [c, setC] = useState([])
    const [search_, setSearch_] = useState("");
    const [allList, setAllList] = useState<any[]>([])
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const [allRecords, setAllRecords] = useState(0)
    const [dropdown_, setDropDown] = useState("")
    const [analytics, setAnalytics] = useState<any>({})
    const [brandData, setBrandData] = useState<any>({})
    const [challengesData, setChallengesData] = useState<any>({})
    const [subscriptionsData, setSubscriptionsData] = useState<any>({})
    const [sort, setSort] = useState("All")
    const [page, setPage] = useState(1)
    const [offset, setOffset] = useState(0)
    const [isDateChanged, setIsDateChanged] = useState(false)
    const [previous, setPrevious] = useState(getPreviousDate("", 90));
    const [endDate, setEndDate] = useState(getToday());
    const [isCalenderOpen, setIsCalenderOpen] = useState(false)
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    const chartlegends = [
        { title: "Running", className: "text-[#0B0149]" },
        { title: "Not Running", className: "text-[#0B01491F]" },
    ]
    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin == "") {
            // nobody is logged in
            navigate("/admin/login");
        }
    }, [loggedInAdmin])
    useEffect(() => {
        fetchConstant()
    }, [])

    const fetchConstant = async () => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/brand/all?_page=1&_limit=1000000000000000`,
            headers: headers
        }).then((response) => {
            var docs = response.data.data.docs;
            setAllList(docs);
            setC(docs);
            setAllRecords(response.data.data.totalDocs)
        }).catch((err) => {
        }).finally(() => {
        })
    }
    const loginAsBrand = (contactEmail : string) => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "POST",
            url: `/auth/admin/login-user`,
            headers: headers,
            data : {
                email : contactEmail
            }
        }).then((response) => {
            var brand = response.data.data.token;

            var id = response.data.data.user._id
            ls.set("refreshToken", response.data.data.refresh_token, { encrypt: true })
            dispatch({
            type: ActionType.LOGIN,
            payload: id
            })

            dispatch({
            type: ActionType.LOGIN_BRAND,
            payload: brand
            })
            window.open("/brand/dashboard", "blank")
            }).catch((err) => {
                setIsLoading(false);
            }).finally(() => {
                setIsLoading(false);
            })
    }
    const addSubscription = (brandid : string) => {
        // navigate()
    }
    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                // if (s.includes('page=') !== false) {
                //     const scrollTo = s.replace('page=', '')
                //     let p = parseInt(scrollTo);
                //     setOffset(p-1)
                //     setPage(p)
                //     return false;
                // }
            });
        }
    }, [search])

    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/brand/admin/analytics?from=${previous}&to=${endDate}`,
            headers: headers
        }).then((response) => {
            setIsLoading(false);
            setBrandData(response.data.data.brandsData)
            setChallengesData(response.data.data.challengesData)
            setSubscriptionsData(response.data.data.subscriptionsData)
            setAnalytics(response.data.data)
        }).catch((err) => {
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        })
    }, [endDate, previous])
    const deleteUser = (brandid: string) => {
        if (!confirm("Are you sure to delete this brand?")) return false;
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "DELETE",
            url: `/brand/${brandid}`,
            headers: headers
        }).then((response) => {
            window.location.reload();
        }).catch((err) => {
            setIsLoading(false);

        }).finally(() => {
        })
    }

    const addSelected = (value: number) => {
        var selected_ = selected;
        if (selected_.includes(value)) {
            selected_.splice(selected_.indexOf(value), 1)
        } else {
            selected_.push(value)
        }
        setSelected(selected_);
        setTimeout(() => {
            setAllList(c)
        }, 500);
    }

    function dropChanged(value: any) {
        setPage(1)
        setSort(value)
    }
    const searchChanged = (value: string) => {
        setPage(1)
        setOffset(0)
        setTimeout(() => {
            if (value === "" || value === undefined || value === null) {
                setSearch_("")
                setAllList(c)
                setAllRecords(c.length)
                return;
            };
            setSearch_(value)
            let constantValue = c;
            var filtered: any[] = constantValue.filter((list:any)=>list.brandName.toLowerCase().includes(value.toLowerCase()));
            setAllRecords(filtered.length)
            setAllList(filtered)
        }, 500);
        
    }
    const handleRangeChanged = (date: any) => {
        // console.log(date)
        setPrevious(getToday(date[0]))
        setEndDate(getToday(date[1]))
        setIsDateChanged(true);
    }
    useDocumentTitle('ADMIN - Brands')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px] font-primary">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                            <div className="search flex justify-between items-center mb-4">
                                <div className="calendar relative">
                                <h3 className="font-primaary font-bold text-blue">Manage Brands</h3>
                                <div className="my-2"></div>
                                    <div className="rounded-[10px] items-center shadow bg-white p-4 flex">
                                        <div>
                                            <img src={calendar} className="object-contain" alt="" />
                                        </div>
                                        <div className='flex-1 px-4'>
                                            <h3 className="text-[18px] text-blue font-bold">Change Period</h3>
                                            {
                                                isDateChanged ?
                                                    <p className='text-gray2 text-[13px]'>{formatDate(previous, 3)} - {formatDate(endDate, 3)}</p>
                                                    :
                                                    <p className='text-gray2 mt-1 text-[13px]'>Last 90days</p>
                                            }
                                        </div>
                                        <div>
                                            <AiOutlineCaretDown className='text-blue' style={{ cursor: 'pointer' }} onClick={() => setIsCalenderOpen(!isCalenderOpen)} />
                                        </div>
                                    </div>
                                    <div className="absolute left-0 bottom-0">
                                        <DateRangePicker
                                            open={isCalenderOpen}
                                            format="yyyy-MM-dd"
                                            defaultCalendarValue={[new Date(previous), new Date(endDate)]}
                                            onChange={handleRangeChanged}
                                            editable={false}
                                            // defaultOpen={true}
                                            ranges={[]}
                                            onOk={() => setIsCalenderOpen(false)}
                                        // cancelable={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* brand counts */}
                            <div className="my-[15px] gap-4 py-[25px] flex flex-col lg:flex-row bg-white justify-center shadow rounded">
                                <div className='flex items-center gap-3'>
                                    <img src={totalCount} alt="" className='h-[75px] w-[75px] object-contain' />
                                    <div>
                                        <p className='text-gray2 mb-[5px]'>Total Brands</p>
                                        <h3 className='mb-[5px] font-bold font-primarybold text-blue text-[22px]'>{isLoading ? 0 : brandData.currentCount}</h3>
                                        <div className="flex">
                                            <BsArrowUp className='text-green-700 font-bold' /> <span className='text-gray2'>18% this month</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-[1px] bg-[#0B01491F] hidden lg:block' ></div>
                                <div className='w-full h-[1px] bg-[#0B01491F] block lg:hidden' ></div>

                                <div className='flex items-center gap-3'>
                                    <img src={totalCount} alt="" className='h-[75px] w-[75px] object-contain' />
                                    <div>
                                        <p className='text-gray2 mb-[5px]'>Subscribed</p>
                                        <h3 className='mb-[5px] font-bold font-primarybold text-blue text-[22px]'>{!isLoading ? subscriptionsData.currentCount : 0}</h3>
                                        <div className="flex">
                                            <BsArrowDown className='text-red-700 font-bold' /> <span className='text-gray2'>12% this month</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-[1px] bg-[#0B01491F] hidden lg:block' ></div>
                                <div className='w-full h-[1px] bg-[#0B01491F] block lg:hidden' ></div>

                                <div className='flex items-center gap-3'>
                                    <img src={activeCount} alt="" className='h-[75px] w-[75px] object-contain' />
                                    <div>
                                        <p className='text-gray2 mb-[5px]'>Campaign</p>
                                        <h3 className='mb-[5px] font-bold font-primarybold text-blue text-[22px]'>{!isLoading ? challengesData.currentCount : 0}</h3>
                                        <div className="flex">
                                            <span className='text-gray2'>Campaign created</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* Top  Brands */}
                            <div className="py-[15px] px-3 rounded shadow bg-white " style={{ overflow: 'scroll' }}>
                                <div className="flex flex-col items-center lg:flex-row justify-between">
                                    {/* filter button */}
                                    <div className="flex-initial w-full max-w-[150px]">
                                        {/* <CoreDropdown value={"Sort : " + sort} placeholder='Sort : All' extendedClasses='dark:text-white text-yellow' containerClass="flex justify-center w-[full] h-[45px] border-graylight border-2 shadow-sm text-[0.8rem] mt-0 bg-blue text-yellow" showError={false} err={""} items={resultSort} setValue={(value: any) => dropChanged(value)} /> */}
                                    </div>

                                    {/* export and search */}
                                    <div className="flex gap-4">
                                        {/* search */}
                                        <div className={`flex items-center control border border-blue pl-2 h-[45px] rounded-[45px] w-[200px] mt-[5px] bg-[#F0F0F4] border-graylight shadow-sm border-2`}>
                                            <FiSearch className='text-[22px] text-[#7E799F]' /> <div className="mr-1"></div>
                                            <input placeholder="Search" type="text" value={search_} onChange={(e) => searchChanged(e.target.value)} className={`bg-transparent font-primary font-semibold ${search_ === '' ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[40px] is-dirty peer `} />
                                        </div>
                                        {/* export */}
                                        <Button text='Export' containerClass="mt-[5px] bg-blue rounded-[10px] font-bold font-primary text-yellow h-[45px] mb-3 w-[150px] mx-auto flex items-center justify-center px-[10px] text-[14px]" img={exporticon} h="45px" />
                                    </div>
                                </div>

                                <div className="shadow-[10px] rounded-[10px] w-[800px] md:w-full lg:w-full border-graylight border-2" >
                                    <table className="border-collapse text-sm table-auto w-[800px] md:w-full lg:w-full">
                                        <thead className='text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                                            <tr>
                                                <th></th>
                                                <th>Brand Name</th>
                                                <th className='text-left'>Email</th>
                                                <th className='text-left'>Phone number</th>
                                                <th className='text-left'>Country</th>
                                                <th className='text-left'>Status</th>
                                                <th className="text-left"><BiDotsVertical /></th>
                                            </tr>
                                        </thead>
                                        <tbody className='p-2'>
                                            {
                                                (allList.slice(page == 1 ? 0 : (page - 1) * 10, page == 1 ? 10 : ((page - 1) * 10) + 10)).map((item: any, i) => (
                                                    <tr onClick={()=> {
                                                        if(dropdown_ != "") setDropDown("")
                                                    }} key={item._id} className='p-2 text-[#0B014980] border-spacing-2' style={{ height: '70px' }}>
                                                        <td className='w-[120px] text-center pr-2'>
                                                            <div className=" flex items-center justify-end">
                                                                {
                                                                    selected.includes(item) ?
                                                                        <img onClick={() => addSelected(item)} style={{ cursor: 'pointer' }} src={checkBoxChecked} alt="" />
                                                                        :
                                                                        <img onClick={() => addSelected(item)} style={{ cursor: 'pointer' }} src={checkBox} alt="" />
                                                                }
                                                                <div className="mx-2"></div>
                                                                <div className="mx-2"></div>
                                                                <img className='h-[40px]  w-[40px] rounded-[100%] object-cover' src={who} alt="" />

                                                            </div>
                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>
                                                            <div className="flex items-center font-bold font-primary">
                                                                <div className='pl-3'>
                                                                    <h3 className='text-blue'>{item.brandName}</h3>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/* brand name, email, phonen number, country, status */}
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.contactEmail}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.contactPhoneNumber}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.country}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>Active</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} w-[50px]`}>
                                                            <div className='relative'>
                                                                {
                                                                    dropdown_ == item._id ?
                                                                        <div className="rounded-[10px] z-[4444] absolute w-[250px] top-[-20px] right-[10px] text-blue font-primary bg-white p-3 shadow-blue shadow-md">
                                                                            <div className='my-2'>
                                                                                <Link to={`/admin/manage-brands/subscriptions/${item.brandId}`} className='my-2'>Add Subscription</Link>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <Link to={`#?`} onClick={()=> loginAsBrand(item.contactEmail)} className='my-2'>Login as Brand</Link>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <Link to={`/admin/create-challenge/${item.brandId}`} className='my-2'>Create Campaign</Link>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <a href="#?" onClick={() => {
                                                                                    deleteUser(item.brandId);
                                                                                }} className='text-red-700 my-2'>Delete</a>

                                                                            </div>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                <BiDotsVertical onClick={() => {
                                                                    setDropDown(item._id)
                                                                }} />

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>

                                        {
                                            allList.length === 0 ?
                                                <p>No record found</p>
                                                :
                                                ''
                                        }
                                    </table>
                                </div>
                            </div>
                            <Pagination limit={10} length={allRecords} page={page} />

                        </div>
                }

            </div>
        </div>
    )
}

export default Brands