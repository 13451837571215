import React, { useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { VscCloseAll } from 'react-icons/vsc'
import ButtonLoader from '../../helpers/button_loader'
import { iCreate } from '../../models/model'
import { Logo } from '../images'
import Input from '../input'

function SearchModal({ close }: iCreate) {
    console.log("here")
    const [search, setSearch] = useState('')
    const [result, setResult] = useState([])
    const [loading, setLoading] = useState(true)
    return (
        <div>
            <div className='fixed top-0 left-0 w-full z-[999] h-[100vh]'>
                <div className="max-h-[80vh] min-h-[300px] w-full flex justify-center">
                    <div onClick={() => close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[860px] p-3 relative'>
                        <div className="mt-[15px] text-center">
                            <form action="">
                                <header className="flex items-center">
                                    <label className="p-2 DocSearch-MagnifierLabel" htmlFor="docsearch-input" id="docsearch-label">
                                        <FiSearch className='text-gray2 font-bold text-[18px] ' />
                                    </label>
                                    <div className={`control border border-0 pl-2 h-[30px] rounded-[15px] w-full`}>
                                        <input autoFocus={true} arial-aria-label="Search Beentos" placeholder="Search Beentos" type="text" value={search} onChange={(e) => setSearch(e.target.value)} className={`bg-transparent font-primary font-semibold ${search === '' ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[30px] is-dirty peer`} />
                                    </div>
                                    <button type="reset" title="Clear the query" className="DocSearch-Reset" aria-label="Clear the query"></button>
                                    <button className="DocSearch-Cancel" type="reset" aria-label="Cancel">
                                        <VscCloseAll onClick={() => close()} className='text=blue text-[22px]' />
                                    </button>
                                </header>
                            </form>
                        </div>

                        <div className="mt-[10px] h-[65vh] px-3 overflow-y-scroll">
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchModal