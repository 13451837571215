import React, { useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { VscCloseAll } from 'react-icons/vsc'
import { toast, ToastContainer } from 'react-toastify'
import { brandCategories, superAdminUsersTypes } from '../../data'
import instance from '../../helpers/axios'
import ButtonLoader from '../../helpers/button_loader'
import { useTypedSelector } from '../../hooks/useSelector'
import { iCreate } from '../../models/model'
import Button from '../button'
import CoreDropdown from '../core_dropdown'
import { checkBox, checkBoxChecked, copy_icon1, Logo } from '../images'
import Input from '../input'

function PickColorModal({ close }: iCreate) {
    const [search, setSearch] = useState('')
    const [result, setResult] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedUser, setSelectedUser] = useState('')
    const { loggedInAdmin, loggedInBrand     } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [brandColor, setBrandColor] = useState("#0b0149")
    const [allRoles, setAllRoles] = useState([
        {title : "Super Admin", value : "super-admin"},
        {title : "Brand Admin", value : "brand-admin"},
    ])
    const [selectedRoles, setSelectedRoles] = useState<any[]>([])

    const addMember = () => {
        setIsLoading(true)
        const headers = {
            'Authorization': `Bearer ${loggedInBrand}`
        }
        const data = {
            brandColor : brandColor
        }
        instance({
            method: 'PUT',
            url: '/brand/account',
            data: data,
            headers: headers,
        }).then((response) => {
            toast.success("Saved successfully")
            // getProfile()
            window.location.reload()
        }).catch((Err) => {
            console.log(Err)
            toast.error("Something went wrong, please try again")
        }).finally(() => setLoading(false))
      
    }
    const selectARole = (value : string) =>{

    }
    return (
        <div>
            <ToastContainer />
            <div className='fixed top-0 left-0 w-full flex items-center justify-center z-[999] h-[100vh]'>
                <div className="max-h-[80vh] min-h-[300px] w-full flex justify-center">
                    <div onClick={() => close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[670px] p-3 relative'>
                        <h3 className='text-[#1E2124] text-[20px]'>Select Brand Color</h3>
                        <p className='text-[#1E2124] mt-2'>Change your Brand Color </p>
                        <div className="h-[1px] my-3 bg-[#CDE1FF] w-full"></div>

                        <div style={{background : brandColor}} className='flex justify-center py-1'>
                            <label htmlFor='selectColor' className='rounded-[100%] h-[100px] w-[100px] flex justify-center cursor-[pointer] items-center'>
                            <small className='text-white font-bolds'>
                                Click to Change
                            </small>
                            </label>
                            <input id='selectColor' hidden value={brandColor} onChange={(e) => setBrandColor(e.target.value)} type="color" className=''  />
                        </div>

                        <div className="h-[1px] my-4 bg-[#CDE1FF] w-full"></div>
                        <div className="px-3">
                            <div className="mt-3 flex justify-between">
                                <Button loading={isLoading} onclick={addMember}  text='Proceed' containerClass='border-blue bg-blue text-yellow border-1 w-[120px]' h='40px' />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PickColorModal