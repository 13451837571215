import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { cardPayment, needHelp, paypal, radio, radioActive, stripe } from '../../../components/images';
import { radioActive, stripe } from '../../../components/images';
import useDocumentTitle from '../../../helpers/pageTitle';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import { ChallengeSteps } from '../../../components/brand';
import Button from '../../../components/button';
import { Completed } from '../../../components/brand';
import instance from '../../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';

const AdminCreateChallenge6: React.FC = () => {
    const params = useParams()
    const [brand, setBrand] = useState<any>({})

    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { challengeData }: any = useTypedSelector((state) => state.appReducer);
    const [budget, setBudget] = useState('')
    const [charges, setCharges] = useState(5)
    const [saved, setSaved] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isBudget, setIsBudget] = useState(false)
    const headers = {
        'Authorization': `Bearer ${loggedInAdmin}`
    }
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })
    }, [])
    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin === "") {
            // nobody is logged in
            navigate("/brand/login");
        }
    }, [loggedInAdmin, navigate])

    useEffect(() => {
        var brandId = params.brandid;
        
        instance({
            method: "GET",
            url: `/brand/all?_searchBy[]=brandId&_keyword=${brandId}`,
            headers: headers
        }).then((response) => {
            console.log("response1", response)
            var docs = response.data.data.docs;
            if(docs.length == 0){
                navigate("/admin/")
            }else{
                setBrand(docs[0]);
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
        })
    }, [])

    // useEffect(() => {
    //     if (!challengeData) {
    //         navigate("/brand/dashboard");
    //     } else {
    //         var data = JSON.parse(challengeData);
    //         setBudget(data.budget)
    //         var b = parseInt(data.budget);
    //         var char = 10/100 * b
    //         setCharges(char)

    //         const r = data.rewardType[0]
    //         if(r.length === 1){
    //             if(r[0].label === "Deal"){
    //                 setIsBudget(false)
    //             }
    //         }
    //     }
    // }, [])

    const { pathname, search } = useLocation();
    const [challengeId, setChallengeId] = useState('')
    const [challenge, setChallenge] = useState<any>({})
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        setLoading(true)
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('challenge=') !== false) {
                    const cha = s.replace('challenge=', '')
                    setChallengeId(cha)
                    instance({
                        method: "GET",
                        url: `/challenge/draft/single/${cha}`,
                        headers: headers
                    }).then((response) => {
                        var cha = response.data.data;
                        setChallenge(cha)

                        const r = cha.rewardType
                        var r_value = ""
                        r.forEach((element: any) => {
                            if (r_value === "") {
                                r_value = element;
                            } else {
                                r_value = r_value + "," + element;
                            }
                        });
                        setBudget(cha.totalPrizeBudget)
                        var b = parseInt(cha.totalPrizeBudget);
                        var char = 10 / 100 * b
                        setCharges(char)

                        if (r_value == "deals" || r_value == "deal") {
                            setIsBudget(false)
                        }
                    setIsBudget(false)

                    }).catch((err) => {

                        if (err.code == "ERR_NETWORK") {
                            alert("No internet connection")
                        } else {
                            if (err.response.data.statusCode === 403) {
                                navigate('/brand/login');
                                return false;
                            } else {
                                navigate("/brand/dashboard")
                            }
                        }
                        toast.error(err.response.data.message)
                    }).finally(() => {
                        setLoading(false)
                    })
                }
            });
        } else {
            navigate('/brand/dashboard');
        }

    }, []);

    const proceed = () => {
        setLoading(true)
        setTimeout(() => {

            const date = new Date();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            // This arrangement can be altered based on how we want the date's format to appear.
            let currentDate = `${year}-${month}-${day}`;
            var postData = {...challenge, "openingDate": currentDate, "creationStatus": "complete", "lastStep": 4, totalPrizeBudget : isBudget ? challenge.totalPrizeBudget : 0, brandId : params.brandid?.toString()}

            // var postData = {
            //     // "title": challenge.title,
            //     // "openingDate": currentDate,
            //     // "TandC": "string",
            //     // "creationStatus": "draft",
            //     // "lastStep": 4,
            //     // "challengeId": challengeId
            // }
            const headers = {
                'Authorization': `Bearer ${loggedInAdmin}`
            }

            instance({
                url: '/challenge/admin/create',
                method: "POST",
                headers: headers,
                data: postData
            })
                .then((response) => {
                    setLoading(false)
                    setSaved(true)
                    dispatch({
                        type: ActionType.UNSET_CHALLENGE,
                    })
                    // success
                    try {
                        sessionStorage.removeItem('challengeData')
                    } catch (error) {
                    }
                }).catch((err) => {
                    setLoading(false)
                    toast.error("Oops, an error occur. please try again...");
                })
        }, 2000);
    }
    useDocumentTitle('CREATE CHALLENGE ')
    // const a = []
    return (
        <div className='px-[5px] py-[50px]'>

            {saved ? <Completed /> : ''}
            <ToastContainer />
            <div className="container mx-auto">
                <div className="text-blue font-primarybold text-[18px]">Create Campaign : {brand.brandName}</div>

                <div className="py-[40px] bg-white shadow-lg rounded ">
                    {/* <ChallengeSteps current={5} /> */}
                    <ChallengeSteps challenge={challengeId} current={4} completed={[1, 2, 3]} />

                    <br />
                    {
                        isBudget ?

                            <div className="lg:mt-[50px">
                                <br />
                                <div className="text-center my-[40px] w-full">
                                    <h3 className='text-blue text-[24px] font-bold font-primary'>You’re a step closer to boost your Business!</h3>
                                </div>
                                <div className="mt-4 w-[90%] max-w-[520px] mx-auto">
                                    <h6 className='text-blue font-primarybold mb-4'>Payment Summary</h6>
                                    <div className="div bg-graylight p-3 rounded">
                                        <div className="flex mb-3 items-center justify-between text-blue font-bold">
                                            <p className=''>Total Payable</p>
                                            <p>${budget}</p>
                                        </div>
                                        <div className="h-[2px] bg-[#7E799F80] w-full"></div>
                                        <div className="flex my-3 items-center justify-between text-blue font-bold">
                                            <p className=''>Charges</p>
                                            <p>${charges}</p>
                                        </div>
                                        <div className="h-[2px] bg-[#7E799F80] w-full"></div>
                                        <div className="flex mt-3 items-center justify-between text-blue font-bold">
                                            <p className=''>Total </p>
                                            <p>${parseInt(budget) + charges}</p>
                                        </div>
                                        {/* <div className="my-[15px] mb-[25px] w-full justify-start flex items-center">
                                <div className="card flex mr-[25px] items-center">
                                    <img src={radioActive} className='object-contain h-[20px]' alt="" /> <div className="mx-1"></div>
                                    <img src={cardPayment} className='object-contain h-[25x]' alt="" />
                                </div>
                                <div className="card flex mr-[25px] items-center">
                                    <img src={radio} className='object-contain h-[20px]' alt="" /> <div className="mx-1"></div>
                                    <img src={stripe} className='object-contain h-[25px]' alt="" />
                                </div>
                                <div className="card flex  items-center">
                                    <img src={radio} className='object-contain h-[20px]' alt="" /> <div className="mx-1"></div>
                                    <img src={paypal} className='object-contain h-[20px]' alt="" />
                                </div>
                            </div> */}

                                        {/*                        
                            <div className="mt-5">
                                <label className='font-primary text-blue' htmlFor="">Card Number</label>
                                <Input value={title} setValue={setTitle} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='Enter card number' />
                            </div> */}
                                        {/* <div className="flex items-center lg:flex-row justify-between flex-col">
                                <div className="mt-5">
                                    <label className='font-primary text-blue' htmlFor="">Valid Thru</label>
                                    <div className="flex">
                                    <Input pos="top-[45%]" items={monthsList} isDropDown={true} value={title} setValue={setTitle} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='Month' />
                                    <div className="mx-3"></div>
                                    <Input pos="top-[45%]" items={monthsList} isDropDown={true} value={title} setValue={setTitle} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='Year' />
                                    </div>
                                </div>
                                <div className="mx-2"></div>
                                <div className="mt-5 relative">
                                    <label className='font-primary text-blue' htmlFor="">CVV</label>
                                    <div className="mt-2"></div>
                                    <Input value={title} type="password" setValue={setTitle} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='CVV' />
                                    <span>
                                        <AiOutlineEyeInvisible className='opacity-75 absolute top-[60%] right-[15px]' />
                                    </span>
                                </div>
                            </div>
                            
                            <div className="mt-5">
                                <label className='font-primary text-blue' htmlFor="">Card Holder's Name</label>
                                <Input value={title} setValue={setTitle} containerClass="h-[50px] border-graylight bg-[#F0F0F4] shadow-sm border-2" placeholder='Name on Card' />
                            </div> */}

                                    </div>
                                    <div className="div bg-graylight p-3 rounded mt-[20px]">
                                        <div className="flex mb-3 items-center justify-between text-blue font-bold">
                                            <p className=''>Payment Method</p>
                                        </div>
                                        <div className="text-center flex justify-between w-full">
                                            <div className="flex mr-[25px] items-center">
                                                <img src={radioActive} className='object-contain h-[18px]' alt="" /> <div className="mx-1"></div>
                                                <img src={stripe} className='object-contain h-[20px]' alt="" />
                                            </div>

                                            <Button text='change' h='30px' onclick={() => {
                                                // navigate('/brand/challenge/create-step-5')
                                            }} containerClass="w-[90px] text-[12px] flex items-center justify-center pl-0 border-yellow bg-yellow text-blue" />
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <Button loading={loading} text="Pay Now" onclick={() => proceed()} containerClass="w-[150px] bg-blue text-yellow mt-[30px]" />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="lg:mt-[50px">
                                <br />
                                <div className="text-center my-[40px] w-full">
                                    <h3 className='text-blue text-[24px] font-bold font-primary'>You’re a step closer to boost your Business!</h3>
                                </div>
                                <div className="text-center">
                                    <Button loading={loading} text="Submit and Create" onclick={() => proceed()} containerClass="w-[250px] bg-blue text-yellow mt-[30px]" />
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AdminCreateChallenge6