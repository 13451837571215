function InitMode() {
    if (localStorage.theme === 'dark') {
        document.documentElement.classList.add('dark')
        return 'dark';
    } else {
        document.documentElement.classList.remove('dark')
        return 'light';
    }
  }

const ToggleMode = (mode : string) => {
    localStorage.theme = mode;
    return InitMode();
}
const appMode = {initMode : InitMode, toggleMode : ToggleMode}
export default appMode;