import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Button from '../components/button';
import { notFound } from '../components/images'
import { ActionType } from '../store/actions';

function Notfound() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
    dispatch({
        type : ActionType.UPDATE_LAYOUT,
        payload : "none"
    })
    }, [dispatch])
  return (
    <div className='not-found bg-gray2 py-[50px] min-h-[100vh]'>
        <div className="flex flex-col items-center justify-center">
            <h3 className="text-white text-[36px]">
                Oops!!!
            </h3>
            <div className="my-5">
                <img src={notFound} alt="" />
            </div>
            <h3 className="text-white text-[36px]">
                Sorry page not found
            </h3>
            <div className="text-center w-full">
                <Button onclick={()=>navigate(-1)} text="Go back" containerClass={`w-[200px] bg-blue text-yellow font-bold hover:opacity-75 mt-[20px]` } />
            </div>
        </div>
    </div>
  )
}

export default Notfound