import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import { bgWhite, arrowBack } from "../../components/images";
import Input from '../../components/input';
import Steps from '../../components/signup/steps';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../store/actions';

const BrandRegister3 = () => {
  
  const [reg, setReg] = useState <string>('')
  const [regErr, setRegErr] = useState <string>('')
  const [name, setName] = useState <string>('')
  const [nameErr, setNameErr] = useState <string>('')
  const [phone, setPhone] = useState <string>('')
  const [phoneErr, setPhoneErr] = useState <string>('')
  const [showNameErr, setShowNameErr] = useState(false)
  const [showPhoneErr, setShowPhoneErr] = useState(false)
 
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  
  const [showRegErr, setShowRegErr] = useState(false)
  const { brandData } : any = useTypedSelector((state) => state.appReducer);

  useEffect(() => {
    dispatch({
      type : ActionType.UPDATE_LAYOUT,
      payload : "none"
    })
    if(!brandData) {
      navigate("/brand/register");
    }else{
      var data = JSON.parse(brandData);
      setName(data.name?data.name : '')
      setReg(data.reg ? data.reg : '')
      setPhone(data.phone ? data.phone : '')
    }
  }, [brandData, dispatch, navigate])
  
  const login = () =>{
    let passed = true;
    setLoading(true)
    setShowRegErr(false)
    setShowNameErr(false)
    setShowPhoneErr(false)

    if(name === ""){
      setNameErr("Please select your country");
      setShowNameErr(true)
      passed = false;
    }

    if(reg === ""){
      setRegErr("Please enter company registration number");
      setShowRegErr(true)
      passed = false;
    }

    if(phone === ""){
      setPhoneErr("Please your mobile number");
      setShowPhoneErr(true)
      passed = false;
    }
    setTimeout(() => {
      if(!passed){
        setLoading(false)
        return false;
      }

      var data = JSON.parse(brandData);
      data = {...data, name : name, phone : phone, reg : reg}
      dispatch({
        type : ActionType.UPDATE_BRAND_DATA,
        payload : JSON.stringify(data)
      })
      navigate("/brand/register-step-4")

    
    }, 1000);

  }
  useDocumentTitle('BRAND SIGN UP - STEP 3')
  return (
    <div className='h-[100vh] relative'>
      <img src={bgWhite} className="h-[100vh] w-full object-fill" alt="" />
      <div className="h-[100vh] absolute top-0 left-0 flex w-full justify-center items-center">
        <div className="login-container shadow-lg w-[95%] max-w-[520px] p-[40px] px-[30px] lg:px-[60px] text-center bg-white rounded-[20px] relative">
          <div className="absolute top-[10px] left-[10px]">
            <img src={arrowBack} className="h-[30px] object-contain" style={{cursor:'pointer'}} onClick={()=>navigate("/brand/register-step-2")} alt="back" />
          </div>
          <h3 className="text-blue font-primarybold  text-[26px] ">Create an account</h3>
          <div className="flex items-center justify-center my-[20px]">
            <Steps s={1} iscurrent={false} islast={false} />
            <Steps s={2} iscurrent={false} islast={false} />
            <Steps s={3} iscurrent={true} islast={true} />
          </div>
          <div className='w-full text-left'>
            <h3 className="text-blue font-primarybold text-[16px] ">Other details</h3>
          </div>

          <Input showError={showNameErr} err={nameErr} placeholder="Contact person's name" value={brandData.name ? brandData.name : name} setValue={setName} containerClass="mt-[15px]" />
          <Input showError={showPhoneErr} err={phoneErr} placeholder="Contact person's phone" value={brandData.phone ? brandData.phone : phone} setValue={setPhone} containerClass="mt-[25px]" />
          <Input showError={showRegErr} err={regErr} placeholder="Contact person's registration number" value={brandData.reg ? brandData.reg : reg} setValue={setReg} containerClass="mt-[25px]" />
          
          <div className="lg:flex lg:justify-end pt-[10px]">
          <Button onclick={login} text="Next" loading={loading} containerClass='w-full lg:w-[200px] bg-blue text-yellow font-bold hover:opacity-75 mt-[20px]' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandRegister3