import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { needHelp, verifyIcon } from '../../../components/images';
import useDocumentTitle from '../../../helpers/pageTitle';
import { decryptData, encryptData } from '../../../helpers/security';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import Steps from '../../../components/signup/steps';
import { ChallengeSteps } from '../../../components/brand';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { brandCategories, plans } from '../../../data';
import instance from '../../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../helpers/loader';
import SubscriptionModal from '../../../components/brand/subscription_modal';
import ls from 'localstorage-slim'
import axios from 'axios';
import { BiTrash } from 'react-icons/bi';
import { FiUpload } from 'react-icons/fi';
import AddBannerModal from '../../../components/brand/add_banner';
const UploadBanners: React.FC = () => {
    const params = useParams();
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [title, setTitle] = useState('')
    const [challenge, setChallenge] = useState<any>({})
    const [brandid, setBrandid] = useState("")
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [fileLoading, setFileLoading] = useState(false)
    const [isAdsBanner, setIsAdsBanner] = useState(false)

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
    }, [])

    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`
    }
    useEffect(() => {
        if (!loggedInBrand || loggedInBrand == "") {
            // nobody is logged in
            navigate("/brand/login");
        }
    }, [loggedInBrand])

    useEffect(() => {
        getChallenge();
    }, [])
    const getChallenge = () => {
        instance({
            method: "POST",
            url: `/challenge/single/${params.challenge}/${0}/${10}`,
            headers: headers
        }).then(async (response) => {
            var cha = response.data.data.challenge;
            let b = cha.brandId;
            getImages(b)
            setBrandid(b)
            setChallenge(cha)
        }).catch((err) => {
            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            } else {
                if (err.response.data.statusCode === 403) {
                    navigate('/brand/login');
                    return false;
                } else {
                    navigate("/brand/dashboard")
                }
            }
        }).finally(() => {

        })
    }
    const getImages = (b = "") => {
        instance({
            method: "GET",
            url: `file?userId=${b == "" ? brandid : b}&fileIntent=${params.challenge}`,
            headers: headers
        }).then((response) => {
            setUploadedFiles(response.data.data.docs)
        })
        setIsAdsBanner(false)
    }

    const deleteFile = (fileid: string) => {
        instance({
            method: "DELETE",
            url: `file`,
            data: { fileUrls: [fileid] },
            headers: headers
        }).then((response) => {
            toast.success("File deleted")
            getImages()
        })
    }


    const { pathname, search } = useLocation();

    useDocumentTitle('UPLOAD CHALLENGE ')
    const [selectedPrice, setSelectedPrice] = useState(10)
    const [subscription, setSubscription] = useState("Gold Package")
    const [selectedId, setSelectedId] = useState("Gol")
    // const a = []
    return (

        <div className='px-[5px] py-[50px]'>
            <ToastContainer />
            {
                isLoading ?
                    <Loader />
                    :
                    <div className="container mx-auto">
                        <div className="text-blue font-primarybold text-[18px]">Campaign Banners</div>
                        {isAdsBanner &&
                            <AddBannerModal
                                challenge={challenge}
                                brandId={brandid}
                                callback={() => getImages()} close={() => {
                                    setIsAdsBanner(false)
                                }} />
                        }
                        <div className="py-[20px] ">
                            <br />
                            <div className="lg:mt-[10px">

                                <div className="mt-4 w-[90%] mx-auto">
                                    <div className="flex justify-end">
                                        <div>
                                            <label onClick={() => {
                                                if (uploadedFiles) {
                                                    if (uploadedFiles?.length >= 3) {
                                                        return toast.error("You cannot upload more than 3 banners");
                                                    }
                                                }
                                                setIsAdsBanner(true)
                                            }
                                            } style={{ cursor: 'pointer' }} className="flex gap-2 items-center">
                                                <FiUpload className='text-blue' />
                                                <span>Upload</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='h-[10px]'></div>

                                    <div className="py-[40px] bg-white shadow-lg rounded ">
                                        <h3 className='text-blue text-center text-[20px] font-bold font-primarybold'>Campaign Advert Banners</h3>
                                        <p className='text-center'>You can upload up to three (3) banners</p>
                                        <br />


                                        <div className="grid grid-cols-2 p-3 gap-2">
                                            {
                                                uploadedFiles?.map((item: any, i) => (
                                                    <div className='relative'>
                                                        <div className='shadow p-1 absolute right-[3px] top-0 rounded-[100%]'>
                                                            <BiTrash onClick={() => {
                                                                deleteFile(item.secureUrl)
                                                            }} className='text-white ' />
                                                        </div>
                                                        <img src={item.secureUrl} alt="" className='w-full cover max-h-[250px]' />
                                                    </div>
                                                ))
                                            }
                                            {
                                                uploadedFiles?.length == 0 && <div>
                                                    <h6>You have no bannner uploaded</h6>
                                                </div>
                                            }
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}

export default UploadBanners