import React, { useEffect, useState } from 'react'
import  useDocumentTitle from '../helpers/pageTitle'
import {posts} from '../blog'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { iPost } from '../models/post'
import { Interweave } from 'interweave';
import instance from '../helpers/axios'
import { formatCreatedAtDate, formatDate, shuffle } from '../helpers/helpers'
import BlogCard from '../components/admin/blog'
import Pagination from '../components/pagination'

function Blog() {
    
    const params = useParams()
    const navigate = useNavigate();
    const [firstPost, setfirstPost] = useState<iPost>()
    const [posts_, setPosts_] = useState<iPost[]>()
    const [allRecords, setAllRecords] = useState(0)
    useEffect(() => {
        if(!params.id){
            fetchConstants()
        }else{
            // const slug = params.id;
            // const post = posts.filter(c => c.slug === slug);
            // if(!post){
            //     navigate('/not-found');
            //     return;
            // }
            fetchConstants()
            // setfirstPost(post[0])
            // setPosts_(posts.filter(c => c.slug !== post[0].slug));
            fetchConstants(params.id)
        }

    }, [navigate, params])

    const fetchConstants = (id = "") =>{
        instance({
            method: 'get',
            url: id ? `/blog/all?_id=${params.id}`:`/blog/all`
        }).then((response)=>{
            var b = response.data.data.docs
            if(id){
                setfirstPost(b[0]);
            }else{
                if(params.id){
                    setPosts_(shuffle(b))
                }else{
                    setPosts_(b)
                }
                setAllRecords(response.data.data.totalDocs)
            }
        }).catch((err)=> {
            // navigate("/admin/blog")
            setPosts_([])
        }).finally(()=>{
        })
    }
    useDocumentTitle('BLOG ')
    return (
        <div className="blog min-h-[90vh] bg-gray dark:bg-black">
            <div className="mx-auto max-w-[1440]">
                <div className="container mx-auto pb-[50px]">
                    <div className="block lg:flex pt-[50px]">
                        {
                            params.id ? 
                            <div className="flex-1 lg:pr-[30px]">
                                <div className="post_image">
                                    <img src={firstPost?.blogImage} className='rounded  h-[2500x] lg:h-[450px] w-full object-cover' alt="first post" />
                                </div>

                                <p className='mt-3 text-blue dark:text-white font-primary'>{formatDate(formatCreatedAtDate(firstPost?.createdAt?.toString()), 1)}</p>
                                <div className="post_title text-blue dark:text-white font-primarybold text-[32px]">
                                    {firstPost?.title}
                                </div>

                                <div className="post_content dark:text-white mt-3"  >
                                    <Interweave content={firstPost?.content} />
                                </div>
                            </div>
                            :
                            <div className="flex-1 grid grid-cols-1 gap-2 lg:grid-cols-2 lg:pr-[30px]">
                                {
                                    posts_?.map((post)=>(
                                        <BlogCard image={post.blogImage} title={post.title} slug={'/blog/'+post._id} />
                                    ))
                                }

                                <div className="mt-3">
                                    <Pagination page={1} length={allRecords} limit={10} />                                    
                                </div>
                            </div>
                        }
                        {/* latest post */}
                        <div className="flex-initial w-[full] lg:w-[340px] latest_post mt-[50px] lg:mt-0 p-2 lg:p-3 ">
                            <h3 className='text-[32px] font-primarybold text-blue dark:text-white'>Latest Posts</h3>
                            <div className="md:flex lg:block my-3">
                                {
                                    posts_?.slice(0, 2).map((post, index) => {
                                    return <div className='p-2 mb-[20px]' key={index}>
                                        <Link to={`/blog/${post._id}`}>
                                            <img src={post.blogImage} className='h-[250px] md:h-[250px] lg:h-[200px] w-full' alt="blog 1" />
                                        </Link>
                                        <div className="mt-2">
                                            <Link to={`/blog/${post._id}`} className='flex flex-wrap'>
                                                <h4 className="font-primarybold text-blue dark:text-white text-[20px]">{post.title}
                                                <span className='text-blue dark:text-white font-primary text-[14px]'>&nbsp; Read more....</span>
                                                <span className='text-blue dark:text-white font-primary text-[14px]'>&nbsp; { formatDate(formatCreatedAtDate(post.createdAt?.toString()), 1, false)}</span>
                                                </h4>
                                            </Link>
                                        </div>
                                    </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog 