import React, {useEffect, useState} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {brandToggle, Logo} from '../images'
import {AiOutlinePlusCircle} from 'react-icons/ai'
import {FiBell, FiMenu, FiSearch} from "react-icons/fi";
import instance from '../../helpers/axios';
import { useTypedSelector } from '../../hooks/useSelector';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../store/actions';
import SearchModal from './search_modal';

export default function BrandNavbar() {
  const [isActive, setisActive] = useState(false)
  const path = useLocation().pathname
  const dispatch = useDispatch()
  const { loggedInBrand, sideBarActive } = useTypedSelector((state) => state.appReducer);
  const [showSearch, setShowSearch] = useState(false)
  useEffect(() => {
    setisActive(false);
    console.log(isActive)
  }, [path]);
  const headers = {
    'Authorization': `Bearer ${loggedInBrand}`,
}

  return (
    <div className='bg-blue'>
      <nav className='font-primary flex justify-between items-center h-[51px] lg:h-[90px] '>
          {/* logo */}
          <div >
            <Link to="/admin" className="brand flex justify-center items-center bg-yellow w-[104px] rounded-t-[10px] lg:w-[187px] h-[53px] lg:h-[90px]"> 
                <img src={Logo} className="object-contain h-[53px] lg:h-[79px]" alt="" />
            </Link>
          </div>
          <div className='flex-1 flex justify-end lg:justify-start items-center'>
              <div className="search_bar hidden lg:block flex-1 px-[20px]">
                <div onClick={()=>setShowSearch(!showSearch)} style={{cursor : 'pointer'}} className="relative border bg-[#F0F0F4] border-blue px-3 flex items-center h-[45px] rounded-[45px]">
                  <input type="text" readOnly placeholder='Search' style={{cursor : 'pointer'}} className='input w-full h-[45px] bg-transparent font-primary font-semibold dark:text-white  text-blue  border-0 outline-0 bg-none w-full px-2 h-[50px] is-dirty peer ' />
                  <FiSearch className='text-gray2 font-bold text-[18px] ' />
                </div>
              </div>
              <div onClick={()=>setShowSearch(!showSearch)} style={{cursor : 'pointer'}} className="px-[5px] mr-[4px] lg:mr-[0] lg:px-[10px] lg:hidden">
                <FiSearch className='text-gray2 outline-2 font-bold text-[18px] ' />
              </div>
             
              <div className="px-[5px] lg:px-[10px]">
                <Link to={'#?'} className='bg-transparent flex items-center justify-center px-[10px] text-[14px]'> <FiBell className='text-white font-bold text-[20px]' /> </Link>
              </div>
              <button onClick={()=>{
                dispatch({
                    type : ActionType.SIDEBAR_TOGGLE,
                    payload : !sideBarActive
                })
            }} className='flex mr-3 lg:hidden items-center bg-white rounded p-2 py-1 ml-3 justify-center px-[5px] lg:px-[10px] text-[14px]'> <FiMenu className='text-blue font-bold text-[20px]' /> </button>
             
          </div>
      </nav>
            {showSearch ? <SearchModal close={()=>setShowSearch(false)} /> : ''}
    </div>
  )
}
