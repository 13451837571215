import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {Logo} from './images'
import {Socials} from '.'
import { ScrollToDiv } from '../helpers/helpers'


function Footer({source = "app"}) { 
  const path = useLocation().pathname

  return (
    <div className={`${source == "app" ? "bg-yellow" : ""} pt-[30px] dark:bg-black lg:pt-[60px]`} id='footer'>
      <div className="px-[20px] lg:px-[40px] mx-auto">
          <div className="flex flex-col lg:flex-row justify-between">
            {/* Quick Links and products */}
            <div className='p-2 flex w-[100%] lg:w-[500px] justify-between'>
              {/* logo */}
              {
                source == "app" 
                ?
              <>   
              <div className='dark:text-white'>
                  <h6 className='text-[18px] font-primarybold text-blue dark:text-white'>Quick Links</h6>
                  <ul>
                    <li>
                      <Link to={'/'} className='font-primary hover:font-bold'>Home</Link>
                    </li>
                    <li>
                      <Link to={path === '/' ?'#?' : '/?section=about_page'} className='font-primary hover:font-bold' onClick={()=>ScrollToDiv("about_page")}>About</Link>
                    </li>
                    <li>
                      <Link to={path === '/' ?'#?' : '/?section=howitworks_section'} className='font-primary hover:font-bold' onClick={()=>ScrollToDiv("howitworks_section")}>How it Works</Link>
                    </li>
                    
                    <li>
                      <Link to={'/blog'} className='font-primary hover:font-bold'>Blog</Link>
                    </li>
                  </ul>
              </div>

              {/* products */}
              <div>
                  <h6 className='text-[18px] font-primarybold text-blue dark:text-white'>Products</h6>
                  <ul>
                    <li>
                      <Link to={path === '/' ?'#?' : '/?section=howitworks_section'} className='font-primary hover:font-bold dark:text-white' onClick={()=>ScrollToDiv("howitworks_section")}>Users</Link>
                    </li>
                    <li>
                      <Link to={path === '/' ?'#?' : '/?section=brands_section'} className='font-primary hover:font-bold dark:text-white' onClick={()=>ScrollToDiv("brands_section")}>Partners</Link>
                    </li>
                    <li>
                      <Link to={path === '/' ?'#?' : '/?section=company_section'} className='font-primary hover:font-bold dark:text-white' onClick={()=>ScrollToDiv("company_section")}>Companies</Link>
                    </li>
                  </ul>
              </div>
              </>
              :
              <></>
            }
            </div>

            {/* contact */}
            {
                source == "app" 
                ?
              <>   
            <div className='p-2 lg:mt-0'>
                <div>
                    <h6 className='text-[18px] font-primarybold text-blue dark:text-white'>Contact Us</h6>
                    <p className='w-full font-primary w-full lg:max-w-[250px] text-[16px] dark:text-white'>Have a question or want to become a partner? We’d love to hear from you.</p>
                </div>

                <div className='mt-[30px] lg:mt-[50px]'>
                    <h6 className='text-[18px] font-primarybold text-blue dark:text-white'>Toronto, Canada</h6>
                    <p className='font-primary dark:text-white'>info@beentos.com<br /></p>
                </div>

            </div>
            </>
              : <></>
            }
            {
            
            source !== "landing" && <Socials /> 
            }
          </div>

          <div className="flex flex-col-reverse lg:flex-row lg:justify-between items-center ">
              <p className='m-0 leading-tight font-primarybold text-blue dark:text-white'>
                &copy; &nbsp; Beentos {new Date().getFullYear()}. All rights reserved.
              </p>
              <ul className="flex mt-4 gap-x-3">
                <li>
                  <Link to={'/terms'} className='font-primary text-blue dark:text-white' >Terms and Condition</Link>
                </li>
                <li>
                  <Link to={'/privacy-policy'} className='font-primary text-blue dark:text-white' >Privacy Policy</Link>
                </li>
              </ul>
          </div>
          { source !== "app"  && <br /> }
      </div>
    </div>
  )
}

export default Footer