import React, { useState } from 'react'
import { BiDotsHorizontal } from 'react-icons/bi'
import { Clipimg, playIcon, video, who, winnersIcon } from '../images'
import ReactPlayer from 'react-player/lazy'


import ButtonLoader from '../../helpers/button_loader';
import instance from '../../helpers/axios';
import ShareModal from './share_modal';
import { toast, ToastContainer } from 'react-toastify';
import ConfirmModal from './ask_modal';
import { FiDownload, FiHeart } from 'react-icons/fi';
import { BsArrowUpRightSquare } from 'react-icons/bs';
import { Rating } from '@material-tailwind/react';
import { MdStarRate } from 'react-icons/md';


interface iClipCard {
    item: any,
    loggedInBrand: string,
    reported?: any,
    source?: string,
    reload?: any,
    selectWinners?: boolean,
    setChecked?: any,
    selectedWinners?: any[],
    winners?: any[]
}
function ClipCaard2({ source = "main", winners, setChecked, selectWinners = false, selectedWinners, item, loggedInBrand, reported = null, reload }: iClipCard) {
    const [isActive, setIsActive] = useState(false)
    const [showPlayer, setShowPlayer] = useState(false)
    const [playerReady, setPlayerReady] = useState(false)
    const [isAdmin, setIsAdmin] = useState(window.location.href.includes("admin") ? true : false)
    const [isFlag, setIsFlag] = useState(false)
    const [showShare, setShowShare] = useState(false)
    const [showOverlay, setShowOverlay] = useState(false);
    const [isPost, setIsPost] = useState(false)

    const generateThumb = () => {

    }
    const [isDelete, setIsDelete] = useState(false)
    const { _id, comments, contentType, user, noOfVoters, noOfViews, media, thumbnailUrl } = item;
    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`
    }
    const pinpost = () => {
        instance({
            method: "POST",
            url: `/brand/challenge-post/pin/${_id}`,
            headers: headers
        }).then((response) => {
            console.log("response", response)
        }).catch((err) => {

        }).finally(() => {
        })
    }
    const flagPost = () => {
        instance({
            method: "POST",
            data: {
                flag: true
            },
            url: `/challenge-post/admin/flag/${_id}`,
            headers: headers
        }).then((response) => {
            // console.log("response", response)
            toast.success("Post flagged")
            setIsDelete(false)
            setIsFlag(false)
        }).catch((err) => {
            setIsDelete(false)
            alert("Unable to flag post")
        }).finally(() => {
        })
    }

    const deletePost = () => {
        instance({
            method: "DELETE",
            url: `/challenge-post/admin/delete-reported-posts`,
            headers: headers,
            data: { property: "id", value: reported ? [reported._id] : [_id] }
        }).then((response) => {
            // console.log("response", response)
            setIsDelete(false)

            toast.success("Post deleted")
            window.location.href = "./reported-contents";
        }).catch((err) => {
            setIsDelete(false)
            alert("Unable to delete" + err.message)
            alert(JSON.stringify(err))


        }).finally(() => {
        })
    }

    const showDelete = () => {
        setIsDelete(true)
    }
    return (
        <div>
            <ToastContainer />
            {
                isDelete ?
                    <ConfirmModal
                        callback={() => deletePost()}
                        close={() => setIsDelete(false)} title="Delete Post?" description='This action cannot be revert' />
                    : ""
            }


            {
                isFlag ?
                    <ConfirmModal
                        callback={() => flagPost()}
                        close={() => setIsFlag(false)} title="Flag Post?" description='Are you sure to flag this post' />
                    : ""
            }


            {
                showShare ?
                    <ShareModal close={() => setShowShare(false)} type='post' id={_id} /> : ''
            }
            {
                isPost ?
                    <ConfirmModal
                        callback={() => { }}
                        close={() => setIsPost(false)} title="Are you sure you want to Post this Video?" description='This action cannot be revert' />
                    : ""
            }
            {
                showPlayer
                    ?
                    <div className='fixed flex items-center  top-0 left-0 w-full z-[99999] h-[100vh]'>
                        <div className="max-h-[80vh] min-h-[300px] w-full flex justify-center">
                            <div onClick={() => setShowPlayer(false)} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                            <div className='relative'>
                                {
                                    playerReady ? ''
                                        :
                                        <div className='absolute top-[48%] z-[3px] left-[45%]'>
                                            <ButtonLoader />
                                        </div>
                                }

                                <div className={`${playerReady ? 'bg-white' : ''} left-[0] z-[49999px]`}>
                                    <ReactPlayer
                                        controls={true}

                                        onReady={() => { setPlayerReady(true) }}
                                        // url={media.mediaSecureUrl} 
                                        url={item.media.mediaSecureUrl}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    : ''
            }
            {/* // <div className="relative shadow rounded "> */}

            <div
                className={`relative overflow-hidden w-full pt-[100%]`}
                onMouseEnter={() => setShowOverlay(true)}
            >
                {
                    contentType == "text" || contentType == "image" &&
                    <div className='absolute z-[9999410]  top-[20px] left-[10px]'>
                        <FiDownload className='cursor-[pointer] text-white' />
                    </div>
                }

                {
                    selectWinners && !winners?.includes(item._id) &&
                    <div className='absolute z-[9999410]  top-[20px] right-[10px]'>
                        {
                            !selectedWinners?.includes(item._id) ?
                                <div onClick={() => {
                                    setChecked(item._id, true)
                                }} className={`border-2 cursor-[pointer] ${contentType == "text" ? "border-white" : "border-blue"} shadow h-[18px] w-[18px]`}></div>
                                :
                                <div onClick={() => {
                                    // on checked
                                    setChecked(item._id, false)
                                }} className='border-2 cursor-[pointer] flex shadow rounded-[100%] items-center justify-center border-blue  h-[18px] w-[18px] checkedBoss'>
                                    {/* <div className='bg-blue  h-[8px] w-[8px] checkedBoss'>
                        </div>  */}
                                    <span className='font-bold'> &#10003; </span>
                                </div>
                        }
                    </div>

                }

                {isActive &&
                    <div className="bg-white py-2 top-0 px-3 shadow z-[9999410] right-0 rounded absolute w-[180px]">
                        {
                            isAdmin ?
                                <ul className="text-[12px]">
                                    <li className='mt-1'>
                                        <a onClick={() => {
                                            setIsActive(false)
                                            setShowPlayer(true)

                                        }} className="text-blue font-primary" href="#?">View Post</a>
                                    </li> <li className='mt-1'>
                                        <a onClick={() => {
                                            setIsActive(false)
                                            setIsDelete(false)
                                            setIsFlag(true)
                                        }} className="text-blue font-primary" href="#?">Flag</a>
                                    </li>

                                    <li className='mt-1'>
                                        <a onClick={
                                            () => {
                                                setIsActive(false)
                                                setIsDelete(true)
                                            }
                                        } className="text-blue font-primary" href="#?">Delete Post</a>
                                    </li>
                                </ul>
                                :
                                <ul className="text-[12px]">
                                    <li className='mt-1'>
                                        <a onClick={() => {
                                            setIsActive(false)
                                            setShowPlayer(true)

                                        }} className="text-blue font-primary" href="#?">View Post</a>
                                    </li>
                                    {
                                        source == "main"
                                        &&
                                        <>


                                            <li className='mb-1'>
                                                <a onClick={pinpost} className="text-blue font-primary" href="#?">Pin Post</a>
                                            </li>
                                            <li className='my-1'>
                                                <a onClick={() => { setIsActive(false); setShowShare(true) }} className="text-blue font-primary" href="#?">Share Post</a>
                                            </li>
                                            <li className='my-1'>
                                                <a className="text-blue font-primary" href="#?">Flag as inappropriate</a>
                                            </li>
                                            <li className='mt-1'>
                                                <a onClick={
                                                    () => {
                                                        setIsActive(false)
                                                        setIsPost(true)
                                                    }
                                                } className="text-blue font-primary" href="#?">Post on Instagram</a>
                                            </li>
                                            <li className='mt-1'>
                                                <a className="text-blue font-primary" href="#?">Delete Post</a>
                                            </li>
                                        </>
                                    }
                                </ul>
                        }

                    </div>
                }

                {/* <div className="mt-3 flex justify-end pr-3">
                        <BiDotsHorizontal onClick={() => setIsActive(true)} style={{ cursor: 'pointer' }} />
                    </div> */}
                <div
                    onClick={() => {
                        setIsActive(!isActive)
                        // setShowPlayer(true)
                    }}
                >
                    <div
                        className={`bg-blue cursor-[pointer] bg-opacity-60 px-3  h-full w-full absolute inset-0 z-10 flex items-start flex-column justify-end text-white ${showOverlay ? "" : "hidden"
                            }`}
                        onMouseLeave={() => setShowOverlay(false)}
                    >
                        <div className="flex items-center">
                            <img src={user ? user.avatar : who} className="h-[50px] object-cover w-[50px] rounded-[100%]" alt="" />
                            <div className="mx-2">
                                <h3 className="text-white">{user ? (user.userName).substr(0, 10) : 'User'}</h3>
                                {
                                    item.privateUser &&
                                    <>  <small>{item.privateUser.email}</small>
                                        <small>{item.privateUser.phoneNumbeer}</small>
                                    </>
                                }
                                 {item.rating &&
                            <div>
                                <Rating
                                ratedIcon={<MdStarRate size={18} color='yellow' />}
                                unratedIcon={<MdStarRate color='white' className='border-black' />}
                                readonly
                                 style={{
                                    height: "20px",
                                    fontSize: "18px"
                                }} className='flex sm text-sm font-sm gap-1 text-[12px]' color='yellow' value={parseInt(item.rating)} />
                            </div>
                        }
                            </div>
                        </div>
                       
                        <div className="grid w-full mt-3 grid-cols-3 text-white">
                            <div className="text-center">
                                <h3 className="text-[14px] text-[#fff]">Comment</h3>
                                <h3 className="text-white">{comments?.length}</h3>
                            </div>
                            <div className="text-center">
                                <h3 className="text-[14px] text-[#fff]">Like</h3>
                                <h3 className="text-white">{noOfVoters}</h3>
                            </div>
                            <div className="text-center">
                                <h3 className="text-[14px] text-[#fff]">Impression</h3>
                                <h3 className="text-white">{noOfViews}</h3>
                            </div>
                        </div>

                        <div className='h-[30px]'></div>
                    </div>

                </div>
                {
                    contentType == "text"
                        ?
                        <div
                            className="absolute bg-blue inset-0 flex justify-center items-center object-cover w-full h-full"
                        >
                            <div>

                                <p className='text-white text-center mx-auto w-[90%] text-[20px]'>
                                    {item.contentText}
                                </p>
                                <div className='h-[20px]'></div>
                                <div className="flex w-[90%] mx-auto justify-center gap-2 items-center">
                                    <span className='text-white text-[14px]'>~</span>
                                    <h3 className="text-white text-[14px]">{item.privateUser ? item.privateUser.fullName : (user ? (user.userName).substr(0, 10) : 'User')}</h3>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            {
                                contentType == "image" ?
                                    <div
                                        className="absolute inset-0 w-full h-full"
                                    >
                                        <img
                                            src={media.mediaSecureUrl}
                                            className="inset-0 object-cover w-full h-[75%]"
                                        />
                                        <div className='h-[25%] flex items-center justify-center w-full p-2 px-3 bg-blue text-white'>
                                            <div className='w-full'>
                                                <p className='text-white text-center mx-auto w-[90%] text-[16px]'>
                                                    {item.contentText}
                                                </p>
                                                <div className='h-[4px]'></div>
                                                <div className="flex w-[90%] mx-auto justify-center gap-2 items-center">
                                                    <span className='text-white text-[14px]'>~</span>
                                                    <h3 className="text-white text-[14px]">{item.privateUser ? item.privateUser.fullName : (user ? (user.userName).substr(0, 10) : 'User')}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <img
                                        src={thumbnailUrl}
                                        className="absolute inset-0 object-cover w-full h-full"
                                    />
                            }
                        </>
                }
            </div>
        </div>

    )
}

export default ClipCaard2