import React, { useState, useEffect } from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom'
import BlogCard from '../../components/admin/blog'
import { blog3, camera } from '../../components/images'
import { iPost } from '../../models/post'
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill'
import Input from '../../components/input'
import Button from '../../components/button'
import instance from '../../helpers/axios'
import { useTypedSelector } from '../../hooks/useSelector'
import { Loader } from 'rsuite'
import { ActionType } from '../../store/actions';
import { toast, ToastContainer } from 'react-toastify';

export const links: iPost[] = [
    {
        title: "BLOG",
        image: blog3,
        slug: "/admin/blog"
    },
    {
        title: "FAQs",
        image: blog3,
        slug: "/admin/faqs"
    }
]

export const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        matchVisual: false,
    }
}
function AdminBlogCreate() {
    const params = useParams();
    const dispatch = useDispatch();
    const [value, setValue] = useState("")
    const [title, setTitle] = useState("")
    const { loggedInAdmin } = useTypedSelector((state:any) => state.appReducer);
    const [selectedUrl, setSelectedUrl] = useState('')
    const [fileLoading, setFileLoading] = useState(false)
    const [file, setFile] = useState<File>()
    const [fileSelected, setFileSelected] = useState<any>('')
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [blog, setBlog] = useState<any>(null)
    const navigate = useNavigate();
    const headers = {
        'Authorization': `Bearer ${loggedInAdmin}`,
    }

    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin == "") {
            // nobody is logged in
            navigate("/admin/login");
        }
    }, [loggedInAdmin])

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    const unsetselected = () => {
        setFileSelected('')
    }

    useEffect(() => {
        if(!params.id) return ;
        instance({
            method: 'get',
            headers : headers,
            url: `/blog/all?_id=${params.id}`
        }).then((response)=>{
            var b = response.data.data.docs[0]
            setTitle(b.title);
            setValue(b.content);
            setBlog(response.data.data.docs)
            if(b.blogImage){
                setSelectedUrl(b.blogImage)
            }
            // console.log(response.data.data.docs)
        }).catch((err)=> {
            navigate("/admin/blog")
        }).finally(()=>{
        })
    }, [])
    

const fileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setFile(e.target.files[0])
    setFileLoading(true)
    let file_ = e.target.files ? e.target.files[0] : null;
    if (file_) {
        var formData = new FormData();
        formData.append("file", file_)
        instance({
            method: 'post',
            data: formData,
            headers : headers,
            url: '/file/upload/profile'
        }).then((response)=>{
            setSelectedUrl(response.data.data.secureUrl)
        }).catch((err)=> {
            console.log(err)
        }).finally(()=>{
            setFileLoading(false)
        })
        setFile(file_)
        var reader = new FileReader();
        reader.onload = function (event) {
            // The file's text will be printed here
            setFileSelected(event.target?.result)
        };
        reader.readAsDataURL(file_);
    } else {
        setFileSelected("")
    }
}

const createPost = (status = "draft") => {
    // setFile(e.target.files[0])
    if(title == "" || value == ""){
        toast.error("Title and Content are required")
        return false;
    }
    setIsButtonLoading(true)
    var data = {
        // userId: userId,
        content: value,
        title: title,
        blogImage : selectedUrl,
        status : status
      }
      
    setTimeout(() => {
        instance({
            method: params.id ? "PUT" : 'POST',
            data: data,
            headers : headers,
            url: params.id ? `/blog/${params.id}` : '/blog/create'
        }).then((response)=>{
            if(params.id) {
                toast.success("Blog updated successfully")
            }else{
                toast.success("Blog added successfully")
                navigate(`/admin/blog/update/${response.data.data._id}`)
            }
        }).catch((err)=> {
            try {
                toast.error(err.response.data.message)
            } catch (error) {
                toast.error("Something went wrong")
            }
        }).finally(()=>{
            setIsButtonLoading(false)
        })
    }, 2000);
   
}


    return (
        <>
            <div>
                <div className="max-w-[1445px] font-primary">
                    <ToastContainer />
                    <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                        <div className="search flex justify-between flex-col lg:flex-row items-center mb-4">
                            {

                            }
                            <h3 className="font-primaary font-bold text-blue">{params.id ? title : "Create Post" }</h3>

                            <Link to={'/admin/blog'} className='bg-yellow rounded-[45px] font-bold font-primary w-[50px] lg:w-[150px] text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]'> <div className="hidden lg:block mx-1"></div> <span className='hidden lg:flex'>Back</span> </Link >
                        </div>

                        <div className="bg-white p-3 rounded shadow-sm">
                        <div className="my-2 flex justify-end">
                        {
                            fileSelected === "" ?
                                <div className="h- relative">
                                    <label htmlFor='uploadDp' style={{ cursor: 'pointer' }} className="absolute rounded shadow-sm h-[150px] w-[150px] bg-[#00000099] flex justify-center items-center">
                                        <img src={camera} className="h-[24px] object-contain" alt="" />
                                    </label>
                                    <img src={selectedUrl === "" ? blog3 : selectedUrl} alt="" className='h-[150px] w-[150px] object-contain' />
                                    <input onChange={(e) => fileUploadChange(e)} hidden type="file" name="upload_dp" id="uploadDp" accept='.png,.jpg,.jpeg,.webp' />
                                </div>
                                :
                                (
                                    fileLoading ? <Loader /> :
                                <div className="h- relative">
                                    <img src={fileSelected} alt="" className='h-[150px] w-[150px] object-contain' />
                                    <div className="flex">
                                        <button onClick={unsetselected} className='btn p-2 rounded-[20px] bg-transparent broder-blue text-[12px] flex items-center justify-evenly' >Cancel</button> <div className="mx-2"></div>
                                    </div>
                                </div>
                                )
                        }

                    </div>
                            <div className="my-2">
                                <label className='font-primary text-blue' htmlFor="">Title*</label>
                                <Input err={""} type="text" showError={false} value={title} setValue={setTitle} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='Blog title' />
                            </div>

                            {/* <div className="my-2">
                                <label className='font-primary text-blue' htmlFor="">Short Description*</label>
                                <Input err={""} type="text" showError={false} value={title} setValue={setTitle} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='Blog title' />
                            </div> */}

                            <div className="my-3">
                                <label className='font-primary text-blue' htmlFor="">Content*</label>
                                <ReactQuill className='bg-white h-[450px]' modules={modules} theme="snow" value={value} onChange={setValue} />
                            </div>
                            <div className="flex justify-between mt-5">
                                <Button onclick={()=>createPost()} text={params.id ? 'Update' : 'Save'} loading={isButtonLoading} containerClass='bg-yellow rounded-[45px] font-bold font-primary w-[50px] lg:w-[150px] text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]' />
                                <Button onclick={()=>createPost("published")} text={params.id ? 'Update and Publish' : 'Save and Publish'} loading={isButtonLoading} containerClass='bg-yellow rounded-[45px] font-bold font-primary w-[50px] lg:w-[150px] text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]' />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminBlogCreate