import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import Button from '../../components/button';
import { calendar, checkBox, checkBoxChecked, exporticon, who } from '../../components/images';
import { activeCount, graph1, graph2, totalCount, trendup } from '../../components/super_images';
import { CChart } from '@coreui/react-chartjs';
import { BsArrowDown, BsArrowUp, BsCaretDownSquare, BsCircleFill } from 'react-icons/bs';
import { BiCaretDown, BiDotsVertical } from 'react-icons/bi';
import { AiOutlineCaretDown, AiOutlineCheckSquare, AiOutlineEye } from 'react-icons/ai';
import { formatDate, getPreviousDate, getToday } from '../../helpers/helpers';
import { CoreDropdown } from '../../components';
import { resultSort } from '../../data';
import { FiSearch } from 'react-icons/fi';
import Pagination from '../../components/pagination';
import { DateRangePicker } from 'rsuite';
import AppLink from '../../components/link';
// import { ShareModal } from '../../components/brand';

const AdminSubscriptions: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const [selected, setSelected] = useState<number[]>([])
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(true)
    const [c, setC] = useState([])
    const [search_, setSearch_] = useState("");
    const [allList, setAllList] = useState<any[]>([])
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const [allRecords, setAllRecords] = useState(0)
    const [dropdown_, setDropDown] = useState("")
    const [analytics, setAnalytics] = useState<any>({})
    const [brandData, setBrandData] = useState<any>({})
    const [challengesData, setChallengesData] = useState<any>({})
    const [subscriptionsData, setSubscriptionsData] = useState<any>({})
    const [sort, setSort] = useState("All")
    const [page, setPage] = useState(1)
    const [isDateChanged, setIsDateChanged] = useState(false)
    const [previous, setPrevious] = useState(getPreviousDate("", 90));
    const [endDate, setEndDate] = useState(getToday());
    const [isCalenderOpen, setIsCalenderOpen] = useState(false)
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    const chartlegends = [
        { title: "Running", className: "text-[#0B0149]" },
        { title: "Not Running", className: "text-[#0B01491F]" },
    ]
    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin == "") {
            // nobody is logged in
            navigate("/admin/login");
        }
    }, [loggedInAdmin])
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        setIsLoading(true)
        instance({
            method: "GET",
            url: `/subscription?_page=${page}`,
            headers: headers
        }).then((response) => {
            console.log("response", response)
            var docs = response.data.data.docs;
            setAllList(docs);
            setC(docs);
            setAllRecords(response.data.data.totalDocs)
        }).catch((err) => {

        }).finally(() => {
            // setIsLoading(false);
        })
    }, [page])

    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('page=') !== false) {
                    const scrollTo = s.replace('page=', '')
                    setPage(parseInt(scrollTo))
                    return false;
                }
            });
        }
    }, [search])

    useEffect(() => {
        setIsLoading(true);
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/brand/admin/analytics?from=${previous}&to=${endDate}`,
            headers: headers
        }).then((response) => {
            setBrandData(response.data.data.brandsData)
            setChallengesData(response.data.data.challengesData)
            setSubscriptionsData(response.data.data.subscriptionsData)
            // console.log("response", response.data.data)
            setAnalytics(response.data.data)
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false);
        })
    }, [endDate, previous])
    const deleteUser = (brandid: string) => {
        if (!confirm("Are you sure to delete this brand?")) return false;
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "DELETE",
            url: `/brand/${brandid}`,
            headers: headers
        }).then((response) => {
            window.location.reload();
        }).catch((err) => {
            setIsLoading(false);

        }).finally(() => {
        })
    }

    const addSelected = (value: number) => {
        var selected_ = selected;
        if (selected_.includes(value)) {
            selected_.splice(selected_.indexOf(value), 1)
        } else {
            selected_.push(value)
        }
        setSelected(selected_);
        setTimeout(() => {
            setAllList(c)
        }, 500);
    }

    function dropChanged(value: any) {
        setPage(1)
        setSort(value)
    }
    const searchChanged = (value: string) => {
        if (value === "" || value === undefined || value === null) {
            setSearch_("")
            return setAllList(c)
        };

        setSearch_(value)
        var filtered: any[] = [];
        allList.map((list: any) => {
            var fname: string = list.brandName;
            if ((fname.toLowerCase()).includes(value.toLowerCase())) filtered = [...filtered, list];
        })

        setAllList(filtered)

    }
    const handleRangeChanged = (date: any) => {
        // console.log(date)
        setPrevious(getToday(date[0]))
        setEndDate(getToday(date[1]))
        setIsDateChanged(true);
    }
    useDocumentTitle('ADMIN - Brands')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px] font-primary">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                            <div className="search flex justify-between flex-col lg:flex-row lg:items-start items-center mb-4">
                                <div className="calendar relative">
                                <h3 className="font-primaary font-bold text-blue">Manage Subscriptions</h3>
                                </div>
                                <AppLink href='/admin/subscription/packages' containerClass='bg-yellow rounded-[45px] font-bold font-primary w-[120px] text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]' text='Packages' />
                            </div>
                            
                            {/* Top  Brands */}
                            <div className="py-[15px] px-3 rounded shadow bg-white " style={{ overflow: 'scroll' }}>
                                <div className="flex flex-col items-center lg:flex-row justify-between">
                                    {/* filter button */}
                                    <div className="flex-initial w-full max-w-[150px]">
                                    </div>

                                    {/* export and search */}
                                    <div className="flex gap-4">
                                        {/* search */}
                                        <div className={`flex items-center control border border-blue pl-2 h-[45px] rounded-[45px] w-[250px] mt-[5px] bg-[#F0F0F4] border-graylight shadow-sm border-2`}>
                                            <FiSearch className='text-[22px] text-[#7E799F]' /> <div className="mr-1"></div>
                                            <input placeholder="Search" type="text" value={search_} onChange={(e) => searchChanged(e.target.value)} className={`bg-transparent font-primary font-semibold ${search_ === '' ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[40px] is-dirty peer `} />
                                        </div>
                                        {/* export */}
                                        <Button text='Export' containerClass="mt-[5px] bg-blue rounded-[10px] font-bold font-primary text-yellow h-[45px] mb-3 w-[150px] mx-auto flex items-center justify-center px-[10px] text-[14px]" img={exporticon} h="45px" />
                                    </div>
                                </div>

                                <div className="shadow-[10px] rounded-[10px] w-[800px] md:w-full lg:w-full border-graylight border-2" >
                                    <table className="border-collapse text-sm table-auto w-[800px] md:w-full lg:w-full">
                                        <thead className='text-blue p-2 h-[45px] border-b-graylight border-b-2'>
                                            <tr>
                                                <th></th>
                                                <th>Brand Name</th>
                                                <th className='text-left'>Type</th>
                                                <th className='text-left'>Amount</th>
                                                <th className='text-left'>Amount Paid</th>
                                                <th className='text-left'>Coupon</th>
                                                <th className='text-left'>Status</th>
                                                <th className='text-left'>Period</th>
                                                <th className='text-left'>Date</th>
                                                <th className="text-left"><BiDotsVertical /></th>
                                            </tr>
                                        </thead>
                                        <tbody className='p-2'>
                                            {
                                                allList.map((item: any, i) => (
                                                    <tr key={i} className='p-2 text-[#0B014980] border-spacing-2' style={{ height: '70px' }}>
                                                        <td className='w-[120px] text-center pr-2'>
                                                            <div className=" flex items-center justify-end">
                                                                {
                                                                    selected.includes(item) ?
                                                                        <img onClick={() => addSelected(item)} style={{ cursor: 'pointer' }} src={checkBoxChecked} alt="" />
                                                                        :
                                                                        <img onClick={() => addSelected(item)} style={{ cursor: 'pointer' }} src={checkBox} alt="" />
                                                                }
                                                                <div className="mx-2"></div>
                                                                <div className="mx-2"></div>
                                                                <img className='h-[40px]  w-[40px] rounded-[100%] object-cover' src={item.brand ? item.brand.avatar ? item.brand.avatar :who : who} alt="" />

                                                            </div>
                                                        </td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''}`}>
                                                            <div className="flex items-center font-bold font-primary">
                                                                <div className='pl-3'>
                                                                    <h3 className='text-blue'>{item.brand ? item.brand.brandName : ""}</h3>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/* brand name, email, phonen number, country, status */}
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.subscriptionType.name}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.amount}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.amountPaid}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{"adhfas (50%)"}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.status}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{item.subscriptionPeriod}days</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} `}>{formatDate(item.lastPaymentUpdate, 3)}</td>
                                                        <td className={`${i % 2 !== 0 ? 'bg-graylight' : ''} w-[50px]`}>
                                                            <div className='relative'>
                                                                {
                                                                    dropdown_ == item._id ?
                                                                        <div className="rounded-[10px] z-[4444] absolute min-w-[150px] top-[-20px] right-[10px] text-blue font-primary bg-white p-3 shadow-blue shadow-md">
                                                                            {/* <div className='my-2'>
                                                                                <a href="#?" className='my-'>Add Role</a>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <a href="#?" className='my-2'>Suspend</a>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <a href="#?" className='my-2'>Deactivate</a>
                                                                            </div>
                                                                            <div className='my-2'>
                                                                                <a href="#?" className='my-2'>Reactivate</a>
                                                                            </div> */}
                                                                            <div className='my-2'>
                                                                                <Link to={`/admin/create-challenge/${item.brandId}`} className='my-2'>Create Challenge</Link>
                                                                            </div>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                <BiDotsVertical onClick={() => {
                                                                    setDropDown(item._id)
                                                                }} />

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>

                                        {
                                            allList.length === 0 ?
                                                <p>No record found</p>
                                                :
                                                ''
                                        }
                                    </table>
                                </div>
                            </div>
                            <Pagination limit={10} length={allRecords} page={page} />

                        </div>
                }

            </div>
        </div>
    )
}

export default AdminSubscriptions