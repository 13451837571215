import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import Button from '../../components/button';
import { checkBox, checkBoxChecked, exporticon, who } from '../../components/images';
import { activeCount, activeUsers, campaignRunning, engagementRate, graph1, graph2, totalCount, trendup } from '../../components/super_images';
import { CChart } from '@coreui/react-chartjs';
import { BsArrowDown, BsArrowUp, BsCaretDownSquare, BsCircleFill } from 'react-icons/bs';
import { BiCaretDown, BiDotsVertical } from 'react-icons/bi';
import { AiOutlineCheckSquare, AiOutlineEye } from 'react-icons/ai';
import { formatDate, getPreviousDate, getToday } from '../../helpers/helpers';
import { CoreDropdown } from '../../components';
import { resultSort } from '../../data';
import { FiSearch } from 'react-icons/fi';
import { ClipCard } from '../../components/brand';
import Pagination from '../../components/pagination';
// import { ShareModal } from '../../components/brand';

const Content: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const [selected, setSelected] = useState<number[]>([])
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [isLoading, setIsLoading] = useState(false)
    const [c, setC] = useState([])
    const [today, setToday] = useState(getToday(new Date()))
    const [statDate, setStatDate] = useState(getPreviousDate(getToday(), 365))
    const [search_, setSearch_] = useState("");
    const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const [allList, setAllList] = useState<any[]>([])
    const [totalRunningCampaign, setTotalRunningCampaign] = useState(0)
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin == "") {
            navigate("/admin/login");
        }
    }, [loggedInAdmin])
    const chartlegends = [
        { title: "Running", className: "text-[#0B0149]" },
        { title: "Not Running", className: "text-[#0B01491F]" },
    ]

    const addSelected = (value: number) => {
        var selected_ = selected;
        if (selected_.includes(value)) {
            selected_.splice(selected_.indexOf(value), 1)
        } else {
            selected_.push(value)
        }
        setSelected(selected_);
        setTimeout(() => {
            setAllList(c)
        }, 500);
    }
    const [sort, setSort] = useState("All")
    const [page, setPage] = useState(1)
    function dropChanged(value: any) {
        setPage(1)
        setSort(value)
    }
    const searchChanged = (value: string) => {
        if (value === "" || value === undefined || value === null) {
            setSearch_("")
            return setAllList(c)
        };
        console.log("value", value)
        setSearch_(value)
        var filtered: any[] = [];
        allList.map((list: any) => {
            if (list.user) {
                var fname: string = list.user.fullName;

                if ((fname.toLowerCase()).includes(value.toLowerCase())) filtered = [...filtered, list];
            }
        })

        setAllList(filtered)
    }
    const [engagementRate, setEngagementRate] = useState(0)
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/challenge/super-analytics?from=${statDate}&to=${today}`,
            headers: headers
        }).then((response) => {
            console.log(response.data.data.analyticsData)
            setEngagementRate(getData(response.data.data.analyticsData))
            // setTotalRunningCampaign(response.data.data.totalDocs)
        }).catch((err) => {

        }).finally(() => {
            // setIsLoading(false);
        })
    }, [])


    const getData = (data : any) => {
        var engagement = 0;
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            var d = 0;
            d = element.clicks + element.impressions + element.reached.userReached + element.shared;
            engagement += d;
        }
        return engagement;
    }

    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `/challenge/all?paymentStatus=paid&closingDate=<${today}`,
            headers: headers
        }).then((response) => {
            // console.log("response", response)
            setTotalRunningCampaign(response.data.data.totalDocs)
        }).catch((err) => {

        }).finally(() => {
            // setIsLoading(false);
        })
    }, [])

    const [allPosts, setAllPosts] = useState([])
    const [allPostCount, setAllPostCount] = useState(0)
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInAdmin}`
        }
        instance({
            method: "GET",
            url: `challenge-post?_orderBy=noOfVoters`,
            headers: headers
        }).then((response) => {
            console.log("response", response)
            setAllPostCount(response.data.data.totalDocs)
            setAllPosts(response.data.data.docs)
        }).catch((err) => {

        }).finally(() => {
        })
    }, [])

    useDocumentTitle('ADMIN - Content')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px] font-primary">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                            <div className="search flex justify-between items-center mb-4">
                                <h3 className="font-primaary font-bold text-blue">Manage Content</h3>
                            </div>
                            {/* brand counts */}
                            <div className="my-[15px] gap-4 py-[25px] flex flex-col lg:flex-row bg-white justify-center shadow rounded">
                                <div className='flex items-center gap-3'>
                                    <img src={campaignRunning} alt="" className='h-[75px] w-[75px]' />
                                    <div>
                                        <p className='text-gray2 mb-[5px]'>Campaign Running</p>
                                        <h3 className='mb-[5px] font-bold font-primarybold text-blue text-[22px]'>{totalRunningCampaign}</h3>
                                    </div>
                                </div>

                                <div className='w-[1px] bg-[#0B01491F] hidden lg:block' ></div>
                                <div className='w-full h-[1px] bg-[#0B01491F] block lg:hidden' ></div>

                                <div className='flex items-center gap-3'>
                                    <img src={activeUsers} alt="" className='h-[75px] w-[75px]' />
                                    <div>
                                        <p className='text-gray2 mb-[5px]'>Engagement </p>
                                        <h3 className='mb-[5px] font-bold font-primarybold text-blue text-[22px]'>{engagementRate}</h3>
                                    </div>
                                </div>

                                <div className='w-[1px] bg-[#0B01491F] hidden lg:block' ></div>
                                <div className='w-full h-[1px] bg-[#0B01491F] block lg:hidden' ></div>

                                <div className='flex items-center gap-3'>
                                    <img src={activeUsers} alt="" className='h-[75px] w-[75px]' />
                                    <div>
                                        <p className='text-gray2 mb-[5px]'>Total Posts</p>
                                        <h3 className='mb-[5px] font-bold font-primarybold text-blue text-[22px]'>{allPostCount}</h3>
                                    </div>
                                </div>

                            </div>


                            <div className="search flex justify-between items-center my-4">
                                <h3 className="font-primaary font-bold text-blue">Users' Post</h3>
                            </div>

                            <div className="rounded p-3 pb-[30px] bg-white rounded-[10px] shadow">
                                <Button onclick={()=>{
                                    navigate("/admin/manage-contents/pinned")
                                }} containerClass="bg-yellow w-[150px] text-blue " text='Pinned Posts'/>
                            </div>


                            <div className="rounded mt-5 p-3 pb-[30px] pb-5 mb-3 bg-white rounded-[10px] shadow">
                                <div className="search flex justify-end items-center mb-4">
                                    <div className={`flex items-center control border border-blue pl-2 h-[45px] rounded-[45px] w-[150px]} bg-[#F0F0F4] border-graylight shadow-sm border-2`}>
                                        <FiSearch className='text-[22px] text-[#7E799F]' /> <div className="mr-1"></div>
                                        <input placeholder="Search" type="text" value={search_} onChange={(e) => searchChanged(e.target.value)} className={`bg-transparent font-primary font-semibold ${search_ === '' ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[40px] is-dirty peer `} />
                                    </div>
                                    <div className="mx-2"></div>
                                    <div className="max-w-[150px] w-full">
                                        <CoreDropdown value={"Sort : " + sort} placeholder='Sort : All' extendedClasses='dark:text-white text-yellow' containerClass="mt-[-5px] w-full h-[45px] border-graylight border-2 shadow-sm text-[0.8rem] mt-0 bg-blue text-yellow" showError={false} err={""} items={resultSort} setValue={setSort} />
                                    </div>
                                </div>
                                <div className="mb-2"></div>
                                <div className="grid lg:grid-cols-2 gap-3 gap-y-5">
                                    {
                                        (allPosts.slice(page === 1 ? 0 : (page - 1) * 10, page === 1 ? 10 : ((page - 1) * 10) + 10)).map((item, i) => (
                                            <ClipCard loggedInBrand={loggedInAdmin} item={item} key={i} />
                                        ))
                                    }
                                </div>

                                {
                                    allPosts.length === 0 ?
                                        <p>No post found </p>
                                        : ''
                                }
                            </div>

                        </div>
                }

                <Pagination length={allPostCount} page={page} />
            </div>
        </div>
    )
}

export default Content