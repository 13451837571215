import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import { arrowBack, bgWhite, bgYellow } from "../../components/images";
import Input from '../../components/input';
import Steps from '../../components/signup/steps';
import useDocumentTitle from '../../helpers/pageTitle';
import { brandCategories } from '../../data';
import { isValidUrl, validateEmail } from '../../helpers/helpers';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../store/actions';
import { useTypedSelector } from '../../hooks/useSelector';
import { CoreDropdown } from '../../components';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../helpers/loader';



const ResetPassword = () => {
  const [email, setEmail] = useState <string>('')
  
  const [loading, setLoading] = useState(false)

  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showEmailErr, setshowEmailErr] = useState(false)
  const [emailErr, setEmailErr] = useState("")
  const [joined, setJoined] = useState('')
  const [password, setPassword] = useState("")
  const [token, setToken] = useState("")
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let e = (search).substring(search.indexOf("email="))
    e = e.replace("email=", '');
    if(!validateEmail(e)){
        return navigate('/brand/login')
    }else{
      setEmail(e)

      // get email domain
      var domain = (e).substring(e.indexOf("@"))
      // get firt 3letters of the email
      var third = (e).substring(0, 3)

      //join with email domain
      var j = `${third}***${domain}`
      setJoined(j)
    }
    sessionStorage.removeItem('otp')
    try {
    dispatch({
      type : ActionType.LOGOUT_BRAND,
    })
    } catch (error) {
      
    }
  }, [])

  const resend = () => {
    setIsLoading(true)
    setToken("")
    setPassword("")
    instance({
      url : `/auth/request-password-reset?email=${email}`,
      method : 'GET',
    }).then((response)=>{
        // console.log(response);
        setIsLoading(false)
        toast.success(response.data.message)
        setTimeout(() => {
          navigate(`/reset-password?email=${email}`);
        }, 400);
    }).catch((e)=>{
      console.log(e);
      toast.error("Something went wrong");
    }).finally(()=>{
      setIsLoading(false)
    })
  }

  const login = () =>{
    let passed = true;
    setLoading(true)
    setshowEmailErr(false)

    if(email === ""){
      toast.error("Something went wrong")
      passed = false;
    }
    if(token === ""){
      toast.error("Please enter the OTP sent to your email")
      passed = false;
    }

  
    if(password === ''){
      passed = false
      toast.error("Please your new password")

    }else if(password.length < 8 ){
      toast.error('Password must be at least 8 digits')
      passed = false
    }


    setTimeout(() => {
      if(!passed){
        setLoading(false)
        return false;
      }
      const data = {
        email : email,
        token : token,
        password : password
      };

      
      instance({
        url : `/auth/reset-password`,
        method : 'POST',
        data : data,
      }).then((response)=>{
        toast.success(response.data.message)
        setTimeout(() => {
          navigate("/brand/login")
        }, 1000);

      }).catch((e)=>{
        console.log(e)
          toast.error("Token is invalid or expired")
      }).finally(()=>{
        setLoading(false)
      })
      // write logic
      // navigate('/brand/register-step-2');
    }, 1000);

  }
  useDocumentTitle('RESET PASSWORD')
  return (
    <div className='h-[100vh] relative'>
      <ToastContainer />

      {
        isLoading ? <Loader /> :
      <div className='w-full'>
      <img src={bgYellow} className="h-[100vh] w-full object-fill" alt="" />
      <div className="h-[100vh] absolute top-0 left-0 flex w-full justify-center items-center">
        <div className="login-container relative shadow-lg w-[95%] max-w-[520px] p-[40px] px-[30px] lg:px-[60px] text-center bg-white rounded-[20px]">
            <div className="absolute top-[10px] left-[10px]">
            <img src={arrowBack} className="h-[30px] object-contain" style={{cursor:'pointer'}} onClick={()=>navigate("/brand/login")} alt="back" />
          </div>
          <h3 className="text-blue font-primarybold my-[20px] text-[26px] ">Reset Password?</h3>
          <div className='w-full text-center'>
            <p>Enter the OTP sent to your email address {joined}.  <span style={{cursor : 'pointer'}} onClick={resend} className='font-bold font-primary'>Resend token</span></p>
          </div>
          <br />

          <div className='text-left w-full'>
            <p className='text-blue font-primary font-bold m-[0px]'>Token</p>
            <Input type='text' showError={false} err={""} placeholder="Enter the token sent to your email" value={token} setValue={setToken} containerClass="mt-[5px]" />
          </div>
          <div className='text-left w-full mt-[10px]'>
            <p className='text-blue font-primary font-bold m-[0px]'>New Password</p>
            <Input type='password' showError={false} err={""} placeholder="New password" value={password} setValue={setPassword} containerClass="mt-[5px]" />
          </div>
          <div className="lg:flex pt-[10px]">
          <Button onclick={login} text="Change Password" loading={loading} containerClass='w-full bg-blue text-yellow font-bold hover:opacity-75 mt-[20px]' />
          </div>

            <div className="flex justify-center p-2 mt-[22px]">
              <span  className="font-bold text-[14px]">
              <Link to={'/brand/register'} className='font-bold font-primary text-blue hover:opacity-75'>Back to login</Link>
              </span> &nbsp;
              
          </div>
        </div>
      </div>
      </div>
      }
    </div>
  )
}

export default ResetPassword