import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import { arrowBack, bgWhite, bgYellow } from "../../components/images";
import Input from '../../components/input';
import Steps from '../../components/signup/steps';
import useDocumentTitle from '../../helpers/pageTitle';
import { brandCategories } from '../../data';
import { isValidUrl } from '../../helpers/helpers';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../store/actions';
import { useTypedSelector } from '../../hooks/useSelector';
import { CoreDropdown } from '../../components';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';



const ForgotPassword = () => {
  const [email, setEmail] = useState <string>('')
  
  const [loading, setLoading] = useState(false)

  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showEmailErr, setshowEmailErr] = useState(false)
  const [emailErr, setEmailErr] = useState("")

  const { brandData } : any = useTypedSelector((state) => state.appReducer);

  useEffect(() => {
    sessionStorage.removeItem('otp')
    try {
    dispatch({
      type : ActionType.LOGOUT_BRAND,
    })
    } catch (error) {
      
    }
  }, [])


  const login = () =>{
    let passed = true;
    setLoading(true)
    setshowEmailErr(false)

    if(email === ""){
      setshowEmailErr(true)
      setEmailErr("Email address cannot be empty");
      passed = false;
    }


    setTimeout(() => {
      if(!passed){
        setLoading(false)
        return false;
      }
      const data = JSON.stringify({
        email : email,
      });
      
      instance({
        url : `/auth/request-password-reset?email=${email}`,
        method : 'GET',
      }).then((response)=>{
          // console.log(response);
          setLoading(false)
          toast.success(response.data.message)
          setTimeout(() => {
            navigate(`/reset-password?email=${email}`);
          }, 400);
      }).catch((e)=>{
        console.log(e);
        toast.error("Something went wrong");
      }).finally(()=>{
        setLoading(false)
      })
      // write logic
      // navigate('/brand/register-step-2');
    }, 1000);

  }
  useDocumentTitle('BRAND SIGN UP ')
  return (
    <div className='h-[100vh] relative'>
      <ToastContainer />
      <img src={bgYellow} className="h-[100vh] w-full object-fill" alt="" />
      <div className="h-[100vh] absolute top-0 left-0 flex w-full justify-center items-center">
        <div className="login-container relative shadow-lg w-[95%] max-w-[520px] p-[40px] px-[30px] lg:px-[60px] text-center bg-white rounded-[20px]">
            <div className="absolute top-[10px] left-[10px]">
            <img src={arrowBack} className="h-[30px] object-contain" style={{cursor:'pointer'}} onClick={()=>navigate("/brand/login")} alt="back" />
          </div>
          <h3 className="text-blue font-primarybold my-[20px] text-[26px] ">Forgot Password?</h3>
          <div className='w-full text-left'>
            <p>A link will be sent to your email. Follow that link to change password. </p>
          </div>
          <br />

          <div className='text-left w-full'>
            <p className='text-blue font-primary font-bold'>Email Address</p>
            <Input showError={showEmailErr} err={emailErr} placeholder="Email address" value={email} setValue={setEmail} containerClass="mt-[10px]" />
          </div>
          <div className="lg:flex pt-[10px]">
          <Button onclick={login} text="Send" loading={loading} containerClass='w-full bg-blue text-yellow font-bold hover:opacity-75 mt-[20px]' />
          </div>

            <div className="flex justify-center p-2 mt-[22px]">
              <span  className="font-bold text-[14px]">
                <span className="text-blue opacity-50">
                Don’t have an account? Register your business now. 
                </span> &nbsp;
              <Link to={'/brand/register'} className='font-bold font-primary text-blue hover:opacity-75'>Sign up</Link>
              </span> &nbsp;
              
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword