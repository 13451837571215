import ReactDOM from "react-dom/client";
import "@coreui/coreui/dist/css/coreui.min.css";
import "./index.css";
import App from "./App";
import { PostHogProvider } from "posthog-js/react";

import { Provider } from "react-redux";
import { store } from "./store";

const options = {
  api_host: `https://us.i.posthog.com/`,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <PostHogProvider
    apiKey={`phc_3oOWJW3tMCKahWHqgsdqAhDtBoBmQ7hagGeKi42c86q`}
    options={options}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </PostHogProvider>
);
