import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import  useDocumentTitle from '../helpers/pageTitle'
import "react-phone-input-2/lib/bootstrap.css";
import { iUser } from '../models/user';
import instance from '../helpers/axios';
import ButtonLoader from '../helpers/button_loader';
import { validateEmail } from '../helpers/helpers';

function Register() {
    const [phone, setPhone] = useState('+1')
    useDocumentTitle('Regiter')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLasname] = useState('')
    const [email, setEmail] = useState('')
    const [err, setErr] = useState<string>('')
    const [success, setSuccess] = useState<string>('')
    const [loading, setLoading] = useState(false)
    const [industry, setIndustry] = useState('')
    const [company, setCompany] = useState('')
    const [website, setWebsite] = useState('')
    const [comment, setComment] = useState('')

    const joinBrand = async (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErr("")
        setSuccess("")
        if(loading) return;
        setLoading(true);

        if(email === '' || phone === '' || lastname === '' || firstname === ''){
            setLoading(false)
            setErr('All fields are required')
            return;
        }
        if(phone.length < 11){
            setLoading(false)
            setErr('Please enter a valid mobile number')
            return;
        }

        if(!validateEmail(email)){
            setLoading(false)
            setErr('Please enter a valid email address')
            return;
        }

        var data:iUser = {
            email : email,
            userName : lastname + " " + firstname,
            userType : 'brand',
            industry : industry, 
            company : company, 
            website : website,
            phone : phone
        }

        instance({
            url : '/tempuser/register',
            method : 'POST',
            data : data
          })
          .then((response)=>{
            if(response.data.status === true){
                setSuccess('You are registered succesfully!')
            }else{
                setErr('Oops something went wrong')
            }
          }).catch((err)=>{
            console.log(err)
            setErr('Oops something went wrong')
            setLoading(false)
          }).finally(()=>{
            setIndustry("")
            setCompany("")
            setWebsite("")
            setComment("")
            setLasname("")
            setFirstname("")
            setEmail("")
            setPhone("+1")
            setLoading(false)

          })

    }
    return (
        <div className="mx-auto max-w-[1440] bg-graylight py-[50px] dark:bg-black">
            <div className="container mx-auto">
                <form onSubmit={(e)=>joinBrand(e)} autoComplete="off" className="form mx-auto w-full lg:max-w-[520px]">
                    <div className="form-group  text-center">
                        <h3 className="text-blue dark:text-gray2 text-[36px] font-primarybold">Register</h3>
                    </div>
                    <div className="form-group grid grid-cols-2 gap-x-4 mt-4">
                        <div className="flex-initial w-50">
                            <div className='p-1'>
                                <label className="dark:text-gray2 flex items-center" htmlFor="firstName">First name <span className='text-red-700 text-[10px]'> &nbsp; &#10033;</span> </label>
                            </div>
                            <input autoComplete='nope' type="text" value={firstname} onChange={(e)=>setFirstname(e.target.value)} name='firstName' className="input px-2 rounded border-blue outline-blue h-[45px] w-full bg-white dark:bg-graylight" />
                        </div>
                        <div className="flex-initial w-50">
                            <div className='p-1'>
                                <label className="dark:text-gray2 flex items-center" htmlFor="lastName">Last name<span className='text-red-700 text-[10px]'> &nbsp; &#10033;</span> </label>
                            </div>
                            <input name='lastName' value={lastname} onChange={(e)=>setLasname(e.target.value)} type="text" className="input px-2 h-[45px] w-full bg-white dark:bg-graylight" />
                        </div>
                    </div>
                    <div className="form-group mt-4">
                        <div className='p-1'>
                        <label className="dark:text-gray2" htmlFor="emailAddress">Email Address<span className='text-red-700 text-[10px]'> &nbsp; &#10033;</span> </label>
                        </div>
                        <input type="text" name='emailAddress' value={email} onChange={(e)=>setEmail(e.target.value)} className="input px-2 rounded border-blue outline-blue h-[45px] w-full bg-white dark:bg-graylight" />
                    </div>

                    <div className="form-group w-full mt-4">
                        <div className='p-1'>
                        <label className="dark:text-gray2  flex items-center" htmlFor="emailAddress">Phone number<span className='text-red-700 text-[10px]'> &nbsp; &#10033;</span> </label>
                        </div>
                        <PhoneInput
                            inputClass='w-full h-[45px] dark:bg-graylight'
                            containerClass='w-full px-2 h-[50px]'
                            inputStyle={{height: '50px', width: '100%'}}
                            country={"ca"}
                            enableSearch={false}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                        /> 
                    </div>
                    <div className="form-group grid grid-cols-2 gap-x-4 mt-4">
                        <div className="w-full">
                            <div className='p-1'>
                            <label className="dark:text-gray2  flex items-center" htmlFor="firstName">Company Name<span className='text-red-700 text-[10px]'> &nbsp; &#10033;</span> </label>
                            </div>
                            <input value={company} onChange={(e)=>setCompany(e.target.value)} type="text" className="input px-2 rounded border-blue outline-blue h-[45px] w-full bg-white dark:bg-graylight" />
                        </div>
                        <div className="w-full">
                            <div className='p-1'>
                            <label className="dark:text-gray2  flex items-center" htmlFor="lastName">Industry<span className='text-red-700 text-[10px]'> &nbsp; &#10033;</span> </label>
                            </div>
                            <input value={industry} onChange={(e)=>setIndustry(e.target.value)} type="text" className="input px-2 h-[45px] w-full bg-white dark:bg-graylight" />
                        </div>
                    </div>

                    <div className="form-group mt-4">
                        <div className='p-1'>
                        <label className="dark:text-gray2  flex items-center" htmlFor="emailAddress">Company Website<span className='text-red-700 text-[10px]'> &nbsp; &#10033;</span> </label>
                        </div>
                        <input value={website} onChange={(e)=>setWebsite(e.target.value)} type="text" name='emailAddress' className="input px-2 rounded border-blue outline-blue h-[45px] w-full bg-white" />
                    </div>

                    <div className="form-group mt-4">
                        <div className='p-1'>
                            <label className="dark:text-gray2  flex items-center" htmlFor="emailAddress">Comment</label>
                        </div>
                        <textarea onChange={(e)=>setComment(e.target.value)} name="comment" id="comment"className='px-2 w-full h-[100px]'>{comment}</textarea>
                    </div>

                    <div className="form-group text-center mt-4">
                        <button className={`h-[45px] text-white bg-blue dark:bg-graylight dark:text-blue dark:font-bold w-full lg:w-[240px] rounded ${loading ? 'bg-opacity-75' : ''} `}> {loading ? <div className='flex justify-center'><ButtonLoader /></div> : 'Submit' }</button>
                    </div>
                    {
                    success !== "" ?
                    <div className="bg-teal-100 mb-2 transition duration-1000 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                    <div className="flex">
                      <div>
                        <p className="font-bold">{success}</p>
                      </div>
                    </div>
                  </div> : ''
                  }
                  {
                    err !== "" ?
                  <div className='p-2'>
                    <span className="text-red-700">{ err }</span>
                  </div> : ''
                  }
                </form>
            </div>
        </div>
    )
}

export default Register 