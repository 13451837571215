// redirect after verification ----

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Button from '../components/button';
import { bgYellow } from "../components/images";
import Input from '../components/input';
import instance from '../helpers/axios';
import { validateEmail } from '../helpers/helpers';
import Loader from '../helpers/loader';
import useDocumentTitle from '../helpers/pageTitle';
import { ActionType } from '../store/actions';
import ls from "localstorage-slim";

const OTPVerification = () => {
  const [email, setEmail] = useState <string>('')
  const [join, setJoin] = useState <string>('')
  const [token, setToken] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [err, setErr] = useState('')
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams()
  const search = location.search;
  const [showLoader, setShowLoader] = useState(false)
  useEffect(() => {
    // console.log(email);
    let e = (search).substring(search.indexOf("&u="))
    e = e.replace("&u=", '');
    if(!validateEmail(e)){
      if(params.type === 'brand'){
        return navigate('/brand/login')
      }else{
        return navigate('/home')
      }
    }else{
      setEmail(e)

      // get email domain
      var domain = (e).substring(e.indexOf("@"))
      // get firt 3letters of the email
      var third = (e).substring(0, 3)

      //join with email domain
      var j = `${third}***${domain}`

      var alreadysent = sessionStorage.getItem('otp') ? sessionStorage.getItem('otp') : ''

      if(alreadysent === ''){
        instance({
          url : `/auth/request-email-verification?email=${e}`,
          method : 'GET'
        }).then((response) => {
          toast.success(response.data.message);
          sessionStorage.setItem('otp', "true");
        }).catch((error)=>{
          console.log(error)
          try {
            toast.error(error.response.data.message);
          } catch (error) {
            toast.error("We are unable to generate One Time Password at the moment, please try again later!")
          }
        })
      }else{
        toast.success(`We have sent OTP to ${e}, it may take up to 15mins to recieve it.`);

      }
      setJoin(j)
    }
    
  }, [search])
  
  const verifyAccount = () =>{
      setLoading(true);
      setErr("");
      if(token === ""){
        setErr("");
        setLoading(false);
        return false
      }

      var postData = {
      "token": token,
      }

    instance({
      url : '/auth/verify-account',
      method : "POST",
      data : postData
    })
    .then((response)=>{
      // console.log(response.data)
      toast.success(`Account verification success, please login to your account.`);
      setTimeout(() => {
      
        var brand = response.data.data.token;
        var id = response.data.data._id
        if(!brand){
          navigate("/brand/login");
          return;
        }
        localStorage.clear()
        sessionStorage.clear()
        ls.set("refreshToken", response.data.data.refresh_token, { encrypt: true })
        dispatch({
          type: ActionType.LOGIN,
          payload: id
        })
        ls.remove("chats")

        dispatch({
          type: ActionType.LOGIN_BRAND,
          payload: brand
        })
          navigate("/brand/dashboard")
      }, 2000);
    }).catch((err)=>{
      // show error
      toast.error(err.response.data.message);
    }).finally(()=>{
      // unset variables
      setLoading(false)
    })
    
  }


  useEffect(() => {
    dispatch({
      type : ActionType.UPDATE_LAYOUT,
      payload : "none"
  })
}, [])

const resendToken = () => {
  setShowLoader(true)
  sessionStorage.removeItem('otp');
  window.location.reload();
}
  useDocumentTitle('OTP VERIFICATION ')
  return (
    <div className='h-[100vh] relative'>
      { showLoader ? <Loader/> : '' }
      <img src={bgYellow} className="h-[100vh] w-full object-fill" alt="" />
      <ToastContainer />
      <div className="h-[100vh] absolute top-0 left-0 flex w-full justify-center items-center">
        <div className="login-container shadow mx-auto w-[95%] max-w-[420px] p-[30px] text-center bg-white rounded-[20px]">
          <h3 className="text-blue font-primarybold  text-[26px] ">OTP Verification</h3>
          <p className='text-[13px]'>Enter the OTP your received at {join} </p>
          <Input placeholder="Enter OTP" type='text' value={token} setValue={setToken} containerClass="mt-[26px]" />
          <div className="flex justify-center p-2 mt-[10px]">
            <label htmlFor="remember" className=''>
              <span className="font-bold text-blue opacity-50">Didn't get the code?</span>  <span onClick={()=>resendToken()} style={{cursor: 'pointer'}} className='text-blue font-bold'>Resend OTP</span>
            </label>
          </div>
          <Button text='Submit' loading={loading} onclick={verifyAccount} containerClass={`w-full bg-blue text-yellow font-bold hover:opacity-75 mt-[10px] ${loading ? 'opacity-75' : ''}` } />
          {
            err !== "" ?
            <p className="text-red-700 text-center mt-2">{ err }</p> : ''
          }
          <div className="flex justify-center p-2 mt-[22px]">
              <Link to={'/brand/register'} className='font-bold font-primary text-blue hover:opacity-75'>Login</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OTPVerification