import React, { useState } from 'react'
import { AdminAside } from '../components/admin';
import instance from '../helpers/axios';
import { useTypedSelector } from '../hooks/useSelector';
import { chatFloat } from '../components/images';
import Input from '../components/input';
import Button from '../components/button';
import { MdClose } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
interface LayoutProps {
    Element : React.ReactNode;
  }

const AdminLayout: React.FC<LayoutProps> = ({Element}) => {
  const [chatMessage, setChatMessage] = useState("")
  const [showTyping, setShowTyping] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { loggedInAdmin } = useTypedSelector((state) => state.appReducer);

  const sendChat = () => {
    
    const headers = {
      'Authorization': `Bearer ${loggedInAdmin}`
  }
  instance({
      method: "POST",
      url: `/chat/send-to-all-users`,
      headers: headers,
      data : {
        message : chatMessage
      }
  }).then((response) => {
      toast.success("Chat sent successfully")
      setChatMessage("")
  }).catch((err) => {
    toast.error("An error occur")

  }).finally(() => {
      setIsSubmitting(false);
  })
  }
  return (
    <div className='flex relative font-primary bg-[#F0F0F4] min-h-[100vh] p-0 m-0 w-full'>
          <ToastContainer />
          {!showTyping && <div>
            <img onClick={()=> setShowTyping(true)} src={chatFloat} className="h-[76px] cursor-[pointer] fixed bottom-[50px] right-[10px] z-[51211]"/>
          </div>}
          {showTyping && 
          <div className="p-3  w-full max-w-[400px]  shadow bg-white fixed bottom-[10px] lg:right-[10px] z-[51211]">
            <div className="relative w-full h-full">
              <div className='p-2 rounded-[100%] bg-red-700 absolute top-[-20px] right-[-20px] z-[51211]'>
              <MdClose className="text-[13px] text-white cursor-[pointer] " onClick={()=> setShowTyping(false)} />
              </div>
            <Input placeholder="Type here" setValue={setChatMessage} value={chatMessage} type="textarea" />
            <div className="h-[10px]" />
            <Button onclick={()=>{
              if(chatMessage == "") return false;
              if(isSubmitting) return false;
              setIsSubmitting(true)
              sendChat()
            }} loading={isSubmitting} text="Send" containerClass="bg-yellow text-blue pr-2 rounded-0 w-full" />
            </div> 
          </div>
            }
        <AdminAside />
        <div className='flex-1 w-full'>
            { Element }
        </div>
    </div>
  )
}

export default AdminLayout


