import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../helpers/pageTitle';
import { ActionType } from '../../store/actions';
import posthog from 'posthog-js';
const AdminLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.removeItem('otp')
    posthog.reset()
    try {
    dispatch({
      type : ActionType.LOGOUT_ADMIN,
    })
    } catch (error) {
      
    }
    navigate("/admin/login")
  }, [])
  
  
  useDocumentTitle('BRAND LOGOUT ')
  return (
    <div className='h-[100vh] relative'>
    </div>
  )
}

export default AdminLogout