import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import { bgWhite, arrowBack, bgYellow } from "../../components/images";
import Input from '../../components/input';
import Steps from '../../components/signup/steps';
import useDocumentTitle from '../../helpers/pageTitle';
import { useTypedSelector } from '../../hooks/useSelector';
import { Country, State } from 'country-state-city';
import { generateToken, isValidUrl, validateEmail } from '../../helpers/helpers';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../store/actions';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import DropDown from '../../components/dropdown';
import { CoreDropdown } from '../../components';
// import { industries } from '../../data';


const BrandRegister1 = () => {

  const [country, setCountry] = useState<string>('')
  const [countryValue, setCountryValue] = useState({})
  const [countryErr, setCountryErr] = useState<string>('')
  const [socialMediaErr, setSocialMediaErr] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [cityErr, setCityErr] = useState<string>('')
  const [cityList, setCityList] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [stateErr, setStateErr] = useState<string>('')
  const navigate = useNavigate();
  const [socialMedia, setSocialMedia] = useState('')
  const [industry, setIndustry] = useState<string>('')
  const [showIndustryErr, setShowIndustryErr] = useState(false)
  const [iindustrErr, setIndustryErr] = useState<string>('')
  const [showCityErr, setShowCityErr] = useState(true)
  const [showCountryErr, setShowCountryErr] = useState(false)
  const [showSocialMediaErr, setShowSocialMediaErr] = useState(false)
  const { brandData }: any = useTypedSelector((state) => state.appReducer);
  const [category, setCategory] = useState("")
  const dispatch = useDispatch();
  const [gState, setGState] = useState("")
  const [showStateErr, setShowStateErr] = useState(false)
  const [industries, setIndustries] = useState<any>([])
  const [brand, setBrand] = useState<string>('')
  const [brandErr, setBrandErr] = useState<string>('')
  const [showBrandErr, setShowBrandErr] = useState(false)
  const [website, setWebsite] = useState<string>('')
  const [websiteErr, setWebsiteErr] = useState<string>('')
  const [showWebsiteErr, setShowWebsiteErr] = useState(false)
  const [name, setName] = useState<string>('')
  const [nameErr, setNameErr] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [phoneErr, setPhoneErr] = useState<string>('')
  const [showNameErr, setShowNameErr] = useState(false)
  const [showPhoneErr, setShowPhoneErr] = useState(false)
  const [checked, setChecked] = useState(false)
  const [showTermsErr, setShowTermsErr] = useState(false)
  const myCountries = (Country.getAllCountries()).map((item, i) => {
    return {
      id: i,
      key: i,
      label: item.name,
      value: item.name,
      ...item,
    }
  });

  useEffect(() => {
    instance({
      url: "/challenge/area-of-industry/all",
      method: "GET",
    }).then((response) => {
      // console.log(response)
      var cat: any[] = []
      var data = response.data.data;
      data.map((item: any, i: number) => {
        var n = item.name;
        cat = [...cat, { id: i + 1, label: n, value: n }]
      })

      setIndustries(cat)
    }).catch((err) => {

    })
    console.log(industries);
  }, [])

  const medias = [
    {
      id: 1,
      key: 1,
      label: 'Facebook',
      value: 'Facebook',
    },
    {
      id: 2,
      key: 2,
      label: 'Instagram',
      value: 'Instagram',
    },
    {
      id: 1,
      key: 1,
      label: 'Twitter',
      value: 'Twitter',
    },
  ]

  const countryChanged = (value: string) => {
    var selected = myCountries.find(c => c.name === value);

    if (!selected) {
      window.alert("invalid country");
      return false;
    }
    setCountryValue(selected)
    setCountry(value);
    const cities = (State.getStatesOfCountry(selected.isoCode)).map((item, i) => {
      return {
        id: i,
        key: i,
        label: item.name,
        value: item.name,
        ...item,
      }
    });

    setCityList(cities);
  }
  useEffect(() => {
    if (!brandData) {
      navigate("/brand/register");
    } else {
      var data = JSON.parse(brandData);
      // alert(data.category)
      // setCountry(data.country ? data.city : '')
      // setSocialMedia(data.socialMedia ? data.socialMedia : '')
      // setIndustry(data.industry ? data.industry : '')
      setCategory(data.category)
      if (data.country) {
        countryChanged(data.country)
      }
    }
  }, [brandData, navigate])

  const login = () => {
    if (loading) {
      return false;
    }
    let passed = true;
    setLoading(true)
    setShowIndustryErr(false)
    setShowSocialMediaErr(false)
    setShowCountryErr(false)
    setShowWebsiteErr(false)
    setShowBrandErr(false)
    setShowPhoneErr(false)
    setShowNameErr(false)
    setShowStateErr(false)
    setShowCityErr(false)
    setShowTermsErr(false)

    setTimeout(() => {
      if (brand === "") {
        setBrandErr("Please enter your brand name");
        setShowBrandErr(true)
        passed = false;
      }

      if (website === "") {
        setWebsiteErr("Please select your website");
        setShowWebsiteErr(true)
        passed = false;
      }

      if (country === "") {
        setCountryErr("Please select your country");
        setShowCountryErr(true)
        passed = false;
      }
      if (website === "") {
        // setShowWebsiteErr(true)
        // setWebsiteErr("Please input your official website.");
        // passed = false;
      } else if (!isValidUrl(website)) {
        setShowWebsiteErr(true)
        setWebsiteErr("Please a valid url e.g example.com.");
        passed = false;
      }

      if (category === "Government") {
        if (gState === "") {
          setStateErr("Please select your state");
          setShowStateErr(true)
          passed = false;
        }
      }
      if (city === "") {
        if (category === "Government") {
          setCityErr("Please enter your city");
        } else {
          setCityErr("Please select your city");
        }
        setShowCityErr(true)
        passed = false;
      }
      if (category === "Government") {
        if (gState === "") {
          setStateErr("Please select your state");
          setShowStateErr(true)
          passed = false;
        }
      }


      if (name === "") {
        setNameErr("Please enter your contact information");
        setShowNameErr(true)
        passed = false;
      }

      if (phone === "") {
        setPhoneErr("Please your mobile number");
        setShowPhoneErr(true)
        passed = false;
      }


      if (!passed) {
        setLoading(false)
        return false;
      }
      var d = JSON.parse(brandData);
      var postData = {
        "brandName": brand,
        "brandCategory": (d.category.toLowerCase()).includes('personal') ? 'personal' : (d.category).toLowerCase(),
        "website": website ? website : "beentos.com",
        "email": d.email,
        "country": country,
        "city": city,
        "state": city,
        "areaOfIndustry": [industry],
        "contactName": name,
        "contactPhoneNumber": phone,
        "corporateRegistrationNumber": d.reg,
        "socialMedia": d.socialMedia,
        "contactEmail": d.email,
        "password": d.password,
        "about": "",
        "address": `${city}, ${country}`,
      }
      if (!checked) {
        setShowTermsErr(true)
      }
      instance({
        url: '/brand/create',
        method: "POST",
        data: postData
      })
        .then((response) => {
          console.log(response)
          toast.success("Registration successful, please login");
          setTimeout(() => {
            navigate(`/otp/brand?token${generateToken(20)}=&u=${postData.email}`)
          }, 1000);
        }).catch((err) => {
          // show error
          console.log(err)
          toast.error(err.response.data.message);
        }).finally(() => {
          // unset variables
          setLoading(false)
        })
    }, 1000);



  }
  const handleChangeCity = (selectedOption: any, actionMeta: any) => {
    // setCountry(selectedOption)
    if (category === "Government") return setGState(selectedOption.label)
    setCity(selectedOption.label)
  };
  const handleChange = (selectedOption: any, actionMeta: any) => {
    // setCountry(selectedOption)
    countryChanged(selectedOption.label)
  }
  const socialhandleChange = (selectedOption: any, actionMeta: any) => {
    // setCountry(selectedOption)
    setSocialMedia(selectedOption)
  };
  const handleIndustryChange = (selectedOption: any, actionMeta: any) => {
    // setCountry(selectedOption)
    setIndustry(selectedOption.label)
  };

  // const handleChangeCity = (selectedOption : any, actionMeta : any) => {
  //   // setCountry(selectedOption)
  //   setCity(selectedOption.label)
  // };

  const handleInputChange = () => {

  }
  useDocumentTitle('BRAND SIGN UP - STEP 2')
  return (
    <div className='h-[100vh] relative'>
      <ToastContainer />
      <img src={bgYellow} className="h-[100vh] w-full object-fill" alt="" />
      <div className="h-[100vh] absolute top-0 left-0 flex w-full justify-center items-center">
        <div className="login-container h-[80vh] overflow-auto shadow-lg w-[95%] max-w-[820px] p-[40px] px-[30px] lg:px-[60px] text-center bg-white rounded-[20px] relative">

          <div className="absolute top-[10px] left-[10px]">
            <img src={arrowBack} className="h-[30px] object-contain" style={{ cursor: 'pointer' }} onClick={() => navigate("/brand/register")} alt="back" />
          </div>


          <h3 className="text-blue font-primarybold  text-[26px] ">Create an account</h3>
          <div className="flex items-center justify-center my-[20px]">
            <Steps s={1} iscurrent={false} islast={false} />
            <Steps s={2} iscurrent={true} islast={true} />
            {/* <Steps s={3} iscurrent={false} islast={true} /> */}
          </div>

          <div className=''>
            <div className='w-full text-left'>
              <h3 className="text-blue font-primarybold text-[16px] ">Brand details</h3>
            </div>
            <div className=''>

              <div className={`grid grid-cols-1 md:grid-cols-2 gap-2`}>
                <div className={`${showBrandErr ? 'mb-3' : ''}`}>
                  <Input showError={showBrandErr} err={brandErr} placeholder="Brand name" value={brand} setValue={setBrand} containerClass="mt-[10px] text-[1rem]" />
                </div>
                <div className={`${showWebsiteErr ? 'mb-3' : ''}`}>
                  <Input showError={showWebsiteErr} err={websiteErr} placeholder="Official Website" value={website} setValue={setWebsite} containerClass={`mt-[10px] text-[1rem]`} />
                </div>
              </div>

              <div className={`grid grid-cols-1 md:grid-cols-2 gap-2`}>
                <div className={`${showIndustryErr ? 'mb-0' : ''}`}>
                  {
                    category === "Government" ?
                      <Input type='text' prevErr={false} showError={showIndustryErr} err={iindustrErr} placeholder="Government Dept/Ministry" value={industry} setValue={setIndustry} containerClass={` mt-[12px] text-[1rem]`} />
                      :
                      <DropDown source='signup' value={industry} placeholder='Select Industry' showError={showIndustryErr} err={iindustrErr} isMulti={false} options={industries} handleChange={handleIndustryChange} handleInputChange={handleInputChange} />
                  }
                </div>
                <div className={`${showCountryErr ? 'mb-0' : ''}`}>
                  <DropDown source='signup' value={country} placeholder={country === "" ? 'Country' : country} showError={showCountryErr} err={countryErr} isMulti={false} options={myCountries} handleChange={handleChange} handleInputChange={handleInputChange} />
                </div>
              </div>

              {
                category === "Government" ?
                  <div className='m-0'>
                    <DropDown source='signup' value={gState} placeholder='State' showError={showStateErr} err={stateErr} isMulti={false} options={cityList} handleChange={handleChangeCity} handleInputChange={handleInputChange} />
                    <Input showError={showCityErr} err={cityErr} placeholder="City" value={city} setValue={setCity} containerClass="mt-[12px] text-[1rem]" />
                  </div>
                  :
                  <DropDown source='signup' value={city} placeholder='State/Province' showError={showCityErr} err={cityErr} isMulti={false} options={cityList} handleChange={handleChangeCity} handleInputChange={handleInputChange} />
              }
            </div>

            <div>
              <div className={`w-full text-left ${showCityErr ? 'mt-5' : 'mt-3'}`}>
                <h3 className="text-blue font-primarybold text-[16px] ">Contact Information</h3>
              </div>

              <Input showError={showNameErr} err={nameErr} placeholder="Contact person's name" value={name} setValue={setName} containerClass={`mt-[10px]  text-[1rem]`} />
              <Input showError={showPhoneErr} err={phoneErr} placeholder="Contact person's phone" value={phone} setValue={setPhone} containerClass={` ${showNameErr ? 'mt-[30px]' : 'mt-[15px]'}   text-[1rem]`} />
            </div>
          </div>
          {
            showPhoneErr && <div className='my-2 opacity-0'>s</div>
          }
          <div className="flex justify-start items-center p-1 mt-[12px] text-[10px] lg:text-[14px]">
            {
              !checked ?
                <div onClick={() => {
                  setChecked(true)
                }} className='border-2 cursor-[pointer] border-blue h-[18px] w-[18px]'></div>
                :
                <div onClick={() => {
                  setChecked(false)
                }} className='border-2 cursor-[pointer] flex rounded-[100%] items-center justify-center border-blue  h-[18px] w-[18px] checkedBoss'>
                  {/* <div className='bg-blue  h-[8px] w-[8px] checkedBoss'>
                                                       </div>  */}
                  <span className='font-bold'> &#10003; </span>
                </div>
            }
            {/* <label htmlFor="remember" className='flex items-center'> */}
            {/* <input defaultChecked={true} onChange={()=>setChecked(!checked)} type="checkbox" className='border-0 peer outline-0 bg-transparent' name="remember" id="remember" value={1} /> &nbsp; */}
            &nbsp;
            <span className="font-bold text-blue  opacity-50">By signing up, You agree to our &nbsp; </span>
            {/* </label> &nbsp; */}
            <Link target={'_blank'} className='font-bold font-primary text-blue' to={'/terms'}>Terms & Conditions</Link> &nbsp;
          </div>
          {
            showTermsErr ?
              <div className='px-2 w-full flex justify-start'>
                <p className="text-red-700"> <small>You must accept terms and conditions!</small> </p>
              </div> : ''
          }
          <div className="lg:flex lg:justify-end pt-[10px]">
            <Button onclick={login} text="Next" loading={loading} containerClass='w-full lg:w-[200px] bg-blue text-yellow font-bold hover:opacity-75 mt-[20px]' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandRegister1