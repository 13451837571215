import React, { useState } from 'react'
import { FiSearch, FiUpload } from 'react-icons/fi'
import { VscCloseAll } from 'react-icons/vsc'
import { toast, ToastContainer } from 'react-toastify'
import { brandCategories, superAdminUsersTypes } from '../../data'
import instance from '../../helpers/axios'
import ButtonLoader from '../../helpers/button_loader'
import { useTypedSelector } from '../../hooks/useSelector'
import { iCreate } from '../../models/model'
import Button from '../button'
import CoreDropdown from '../core_dropdown'
import { checkBox, checkBoxChecked, copy_icon1, Logo } from '../images'
import Input from '../input'
import { isValidUrl } from '../../helpers/helpers'

function AddBannerModal({ close, callback, brandId, challenge }: iCreate) {
    const [isLoading, setIsLoading] = useState(false)
    const [file, setFile] = useState<any>(null)
    const { loggedInBrand } = useTypedSelector((state: any) => state.appReducer);
    const [preview, setPreview] = useState<any>(null)
    const [link_, setLink] = useState("")
    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`,
    }
    const addPackage = () => {
        if (isLoading) return false;
        if (!file) {
            return toast.error("Please upload your banner")
        }
        if(link_ != ""){
            if(!isValidUrl(link_)){
                return toast.error("Banner url must be a valid url")
            }
        }
        setIsLoading(true)

        var formData = new FormData();
        formData.append("file", file)
        formData.append("fileIntent", challenge ? challenge._id : "advert");
        formData.append("fileRedirectUrl", link_);
        instance({
            method: 'post',
            data: formData,
            headers: headers,
            url: `/file/upload/brand-advert/advert-${brandId}`
        }).then((response) => {
            toast.success("Banner uploaded")
            callback()
        }).catch((err) => {
            return toast.error("Unable to upload file")
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const fileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFile(null)
        let file_ = e.target.files ? e.target.files[0] : null;
        if (file_) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    var h = img.height;
                    var w = img.width;
                    if (w != 2732 || h != 758) {
                        return toast.error("Banner image dimension must be 2732px by 758px")
                    } else {
                        setFile(file_)
                        setPreview(reader.result);
                    }
                };
                img.src = e.target?.result as string;
            };
            reader.readAsDataURL(file_);

        } else {
            setFile(null)
        }
    }

    return (
        <div>
            <ToastContainer />
            <div className='fixed top-0 left-0 w-full flex justify-center z-[999] h-[100vh]'>
                <div className="max-h-[80vh] h-[fit-content] w-full flex justify-center">
                    <div onClick={() => close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[670px] p-3 relative'>
                        <h3 className='text-[#1E2124] text-[20px]'>Add Banner</h3>
                        <div className="h-[3px]"></div>
                        <div className="h-[1px] my-3 bg-[#CDE1FF] w-full"></div>
                        <>
                            <div className=' rounded-[8px] w-full flex items-center py-1'>
                                <input accept={'image/*'} onChange={fileUploadChange} type="file" hidden name="uploadVideo" id="uploadFile111" />
                                <label htmlFor='uploadFile111' className='flex w-full h-[100px] justify-center items-center flex-col text-center'>
                                    {
                                        file ? <>
                                        <div className="text-center relative w-full">
                                            <img src={preview} className='w-full  cover' />
                                            <p className='text-white shadow absolute right-[10px] bottom-[10px]'>Click to change</p>
                                            </div>
                                        </>
                                            :
                                            <>
                                            <FiUpload style={{ fontSize: "31px", color: "#7E799F" }} />
                                                <p className='text-[15px] text-[#7E799F]'>Click to upload Image. <br /> Image size must be 2732px by 758px</p>
                                            </>
                                    }

                                </label>
                            </div>

                            <div className="h-[1px] my-4 bg-[#CDE1FF] w-full"></div>
                        </>
                        <div className="h-[3px]"></div>

                        <div className='bg-[#F0F0F4] rounded-[8px] w-full flex items-center py-1'>
                            <input value={link_} onChange={(e) => setLink(e.target.value)} type="text" className='bg-transparent ml-3 border-0 outline-0 w-full h-[40px]' placeholder='Enter link e.g yourwebsite.com' />
                        </div>
                        <br />
                        <Button loading={isLoading} onclick={addPackage} text={"Add Banner"} containerClass='bg-blue text-yellow border-0 w-[100px] lg:w-[150px]' h='40px' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddBannerModal