import { useEffect, useState } from "react"
import { ActionType } from "../../store/actions";
import { useDispatch } from "react-redux";
import Button from "../../components/button";
import { Footer, Hr } from "../../components";
import { AppSection } from "../../components/home";
import { ToastContainer } from "react-toastify";
import ClientUserForm from "../../components/clients/click_user_form";
import { useNavigate, useParams } from "react-router-dom";
import instance from "../../helpers/axios";
import { Logo } from "../../components/images";
import Loader from "../../helpers/loader";
import Input from "../../components/input";

export default function ClientEvent() {
    const [showForm1, setShowForm1] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [challenge, setChallenge] = useState<any>({});
    const [guidelines, setGuidelines] = useState<any>([])
    const [uploadedFiles, setUploadedFiles] = useState<any>([])
    const [brand, setBrand] = useState<any>({});
    const [avatar, setAvatar] = useState(Logo);
    const [brandColor, setBrandColor] = useState("#0b0149");

    const [email, setEmail] = useState("")
    const [lastName, setLastName] = useState("")
    const [instagramID, setInstagramID] = useState("")
    const [content, setContent] = useState("")
    const [firstName, setFirstName] = useState("")
    const [fname, setFname] = useState("")
    const [participate, setParticipate] = useState("video")
    const [phone, setPhone] = useState("")
    const [formError, setFormError] = useState("")
    const [contentError, setContentError] = useState("")
    const [formError1, setFormError1] = useState("")
    const [challengeId, setChallengeId] = useState(challenge || "")
    const [file, setFile] = useState<any>(null)

    const dispatch = useDispatch();
    const params = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "none"
        })

        if (!params.challengeCode) {

        }
        instance({
            method: "GET",
            url: `/challenge/all?challengeCode=${params.challengeCode}`,
        }).then((response) => {
            console.log("response", response.data)
            const b = response.data.data.docs[0];
            // if (response.data.data.totalDocs == 0) navigate("/not-found")
            // setChallenge(response.data.data.docs[0])
            // if (b.published != true) navigate("/not-found");
            // setChallenge(b)
            // setBrand(b.brand)
            // instance({
            //     method: "GET",
            //     url: `file?userId=${b.brand.brandId}&fileIntent=${b._id}&_searchBy[]=fileId&_keyword=advert`
            // }).then((response) => {
            //     setUploadedFiles(response.data.data.docs)
            // })

            // if (b.brand.avatar) {
            //     setAvatar(b.brand.avatar)
            //     if (b.brand.brandColor) setBrandColor(b.brand.brandColor)
            // }
            // var g = b.guidelines.split("~end-of-a-step~")
            // setGuidelines(g)
            setIsLoading(false)
        }).catch((e) => {
            setIsLoading(false)

        })
    }, [])

    const space = {
        marginBottom: "30px"
    }

    const space1 = {
        marginBottom: "10px"
    }
    return (
        <div className="bg-[#f8f9fa]">
            <ToastContainer />
            {
                isLoading ?
                    <Loader />
                    :
                    <div style={showForm1 ? {
                        overflow: "hidden",
                        height: "80vh"
                    } : {}}>
                        <div className="container">
                            <div className="space-y-10 divide-y divide-gray-900/10" style={{
                                height: "fit-content",
                                minHeight: "100vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%"
                            }}>
                                <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-2">
                                    <div className="px-4 py-2">
                                        <div className="brandLogo flex justify-center my-[20px] text-center">
                                            <img style={{
                                                height: "120px"
                                            }} src={"https://th.bing.com/th/id/R.ce282872ac79ff9e032afe843102f29c?rik=z7cN6xc8cTMTug&pid=ImgRaw&r=0"} />
                                        </div>
                                        <div className="challengeTitle mb-[40px] mt-4">
                                            <h3 style={{
                                                color: brandColor
                                            }} className={`text-[32px] text-center font-primarybold`}>{"Discover Elevate Events & Activities in Toronto"}</h3>
                                        </div>
                                        <div className="challengeDetails">
                                            <p><strong>About Event</strong></p>
                                            <div style={space1}></div>

                                            <p style={{ fontWeight: 500, textAlign: "justify", listStyleType: "disk" }}
                                                dangerouslySetInnerHTML={{ __html: "Elevate Festival unites trailblazing tech leaders, fearless creatives, and innovative minds in Toronto from Oct 1-3, 2024. Save up to $100 with our Last Chance" }}>
                                            </p>
                                        </div>
                                    </div>

                                    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl ">
                                        <div className="px-4 py-6 sm:p-8">
                                            <div className='w-full'>
                                                <label>Name *</label>
                                                <Input className='h-[20px] py-1' placeholder='E.g. Sam Oprah' value={firstName} setValue={setFirstName} />
                                            </div>
                                            <div className="my-3"></div>
                                            <div className='w-full'>
                                                <label>Email *</label>
                                                <Input type='email' placeholder='E.g. someone@example.com' value={email} setValue={setEmail} />
                                            </div>
                                           
                                            <div className="my-3"></div>

                                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                                <div className="text-center">
                                                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                        >
                                                            <span>Upload a file</span>
                                                            <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                        </label>
                                                        <p className="pl-1">or drag and drop</p>
                                                    </div>
                                                    <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                                </div>
                                            </div>
                                            <div className="text-center mt-3">
                                                <Button
                                                    style={{
                                                        background: brandColor
                                                    }} onclick={() => {}} loading={isLoading} text='Submit' containerClass='text-white border-0 w-[250px] lg:w-[fit-content] px-4 mb-3' h='40px' />
                                                </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {
                            uploadedFiles.length > 0 &&

                            <div className="container">
                                {
                                    uploadedFiles.length == 2
                                        ?
                                        <div className="grid md:grid-cols-2 ">
                                            {
                                                uploadedFiles.map((item: any, i: number) => (
                                                    <div>
                                                        <a target="_blank" href={`${item.fileRedirectUrl ? (item.fileRedirectUrl.includes("http") ? item.fileRedirectUrl : "//" + item.fileRedirectUrl) : "#?"}`}>
                                                            <img src={item.secureUrl} alt="" />
                                                        </a>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        :

                                        <><div>
                                            <a target="_blank" href={`${uploadedFiles[0].fileRedirectUrl ? (uploadedFiles[0].fileRedirectUrl.includes("http") ? uploadedFiles[0].fileRedirectUrl : "//" + uploadedFiles[0].fileRedirectUrl) : "#?"}`}>
                                                <img src={uploadedFiles[0].secureUrl} alt="" />
                                            </a>
                                        </div>
                                            <div className="grid md:grid-cols-2 ">
                                                {
                                                    uploadedFiles.map((item: any, i: number) => (
                                                        <>
                                                            {
                                                                i > 0 &&
                                                                <div>
                                                                    <a target="_blank" href={`${item.fileRedirectUrl ? (item.fileRedirectUrl.includes("http") ? item.fileRedirectUrl : "//" + item.fileRedirectUrl) : "#?"}`}>
                                                                        <img src={item.secureUrl} alt="" />
                                                                    </a>
                                                                </div>
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </div>
                                        </>
                                }

                            </div>
                        }
                        <br />
                        <Hr />
                        <div className="bg-whit">
                        <div className="mt-5">
                            <h3 className="text-[16px] text-center font-primarybold text-blue dark:text-white">Powered By BEENTOS:</h3>
                        </div>
                        <div className="text-center w-full flex justify-center mt-4">
                            {/* <img className="h-[95px]" src={Logo} /> */}
                        </div>
                        {/* <AppSection source="landing" /> */}
                        <Footer source="landing" />
                        </div>
                    </div>
            }
        </div>

    )
}