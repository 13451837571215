import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { beentos_ai, needHelp } from '../../../components/images';
import useDocumentTitle from '../../../helpers/pageTitle';
import { decryptData } from '../../../helpers/security';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import Steps from '../../../components/signup/steps';
import { AiModal, ChallengeSteps } from '../../../components/brand';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { brandCategories } from '../../../data';
import instance from '../../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import { isAdmin, isEdit } from '../../../helpers/helpers';
import BSTooltip from '../../../components/tooltip';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateEvent2: React.FC = () => {
    const { loggedInBrand, loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const [category, setCategory] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [description, setDescription] = useState('')
    const [challenge, setChallenge] = useState<any>({})
    const { challengeData }: any = useTypedSelector((state) => state.appReducer);
    const [loading, setLoading] = useState(false)
    const [descriptionErr, setDescriptionErr] = useState('')
    const [showDescriptionErr, setShowDescriptionErr] = useState(false)
    const [showAiModal, setShowAiModal] = useState(false)
    const params = useParams()
    useEffect(() => {
        if (window.location.href.includes("admin")) {
            dispatch({
                type: ActionType.UPDATE_LAYOUT,
                payload: "admin"
            })
        } else {
            dispatch({
                type: ActionType.UPDATE_LAYOUT,
                payload: "brand"
            })
        }
    }, [])

    // useEffect(() => {
    //     if(!challengeData) {
    //     }else{
    //       var data = JSON.parse(challengeData);
    //       setDescription(data.description)
    //     }
    //   }, [challengeData])
    useEffect(() => {
        if (window.location.href.includes("admin")) {
        } else {
            if (!loggedInBrand || loggedInBrand == "") {
                // nobody is logged in
                navigate("/brand/login");
            }
        }
    }, [loggedInBrand])

    useDocumentTitle('CREATE CHALLENGE ')
    const headers = {
        'Authorization': `Bearer ${window.location.href.includes("admin") ? loggedInAdmin : loggedInBrand}`
    }


    const proceed = (action = "save") => {
        let passed = true;
        setShowDescriptionErr(false)
        setLoading(true)
        setTimeout(() => {

            if (description == "") {
                setShowDescriptionErr(true)
                setDescriptionErr("Please enter challenge description")
                passed = false;
            }

            if (!passed) {
                setLoading(false)
            } else {

                var data: any = { description: description, challengeId: challengeId, creationStatus:  "complete", lastStep: 2, title: challenge.title }
                if (window.location.href.includes("admin")) {
                    data.brandId = params.brandid?.toString()
                }
                let url = window.location.href.includes("admin") ? '/challenge/admin/create' : '/challenge/create'
                let editUrl = isAdmin() ? '/challenge/admin/update' : "/challenge/update"

                instance({
                    url: isEdit() ? editUrl : url,
                    method: isEdit() ? "PUT" : "POST",
                    headers: headers,
                    data: data
                })
                    .then((response) => {
                        if (isEdit()) {

                        } else {
                            data.challengeId = response.data.data._id;
                            setChallengeId(response.data.data._id)
                        }

                        if (isEdit()) {
                            if (action == "exit") {
                                toast.success("Campaign updated")
                                setTimeout(() => {
                                    if (isAdmin()) {
                                        navigate(`/admin/campaign-management`)
                                    } else {
                                        navigate(`/brand/challenge/`)
                                    }
                                }, 2000);
                            } else {
                                if (isAdmin()) {
                                    navigate(`/admin/edit-challenge/${params.brandid}/edit-step-3?challenge=${(response.data.data._id)}`);
                                } else {
                                    navigate(`/brand/challenge/edit-step-3?challenge=${challenge._id}`);
                                }
                            }
                        } else {
                            if (window.location.href.includes("admin")) {
                                navigate(`/admin/events`)
                            } else {

                                navigate(`/brand/events`);
                            }


                        }
                    }).catch((err) => {
                        if (err.code == "ERR_NETWORK") {
                            alert("No internet connection")
                        }
                        if (err.response.data.statusCode === 403) {
                            navigate('/brand/login');
                            return false;
                        }
                        setLoading(false)
                        toast.error(err.response.data.message)
                    })
            }
        }, 2000);

    }

    const { pathname, search } = useLocation();
    const [challengeId, setChallengeId] = useState('')
    useEffect(() => {
        setLoading(true)
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('challenge=') !== false) {
                    const cha = s.replace('challenge=', '')
                    setChallengeId(cha)
                    const challengeurl = `/challenge/single/${cha}/0/1`
                    const defaultDesc = "Do you adore our products/services as much as we do? We'd love to hear all about it! 💖 Share your experience with us through a quick video or text testimonial. We're on the hunt for fantastic content to spice up our social media feed.";

                    instance({
                        method: isEdit() ? "POST" : "GET",
                        url: isEdit() ? challengeurl : `/challenge/draft/single/${cha}`,
                        headers: headers
                    }).then((response) => {
                        console.log("response", response)
                        if (isEdit()) {
                            var cha = response.data.data.challenge;
                        } else {
                            var cha = response.data.data;
                        }
                        setChallenge(cha)
                        // alert(cha.description)
                        setDescription(cha.description ? cha.description : defaultDesc)
                    }).catch((err) => {
                        if (err.code == "ERR_NETWORK") {
                            alert("No internet connection")
                        }
                        if (err.response.data.statusCode === 403) {
                            navigate('/brand/login');
                            return false;
                        }

                        toast.error(err.response.data.message)
                    }).finally(() => {
                        setLoading(false)
                    })
                } else setLoading(false)
            });
        } else setLoading(false)

    }, []);

    // const a = []
    return (
        <div className='px-[5px] py-[50px]'>
            {
                showAiModal ? <AiModal close={() => {
                    setShowAiModal(false)
                }} /> : ''
            }
            <div className="container mx-auto">
                <ToastContainer />
                <div className="flex items-center justify-between">
                    <Link className='text-blue font-bold' to={`/brand/events/create?challenge=${challengeId}`}>go back</Link>
                </div>
                <br />

                <div className="py-[40px] bg-white shadow-lg rounded ">
                    <div className="container">
                    </div>
                    <br />
                    <div className="lg:mt-[50px">
                        <div className="text-center my-[40px] w-full">
                            <div className="flex justify-center">
                                <h3 className='text-blue text-[24px] font-bold font-primary'>Tell your target participants more about your Event</h3>
                                <BSTooltip tooltip={"Give the full description of your Event."} />
                            </div>
                        </div>
                        <div className="mt-4 w-[80%] mx-auto">
                            <div>
                                <ReactQuill className='bg-[#F0F0F4]' style={{
                                    height: "300px",
                                    borderRadius: "10px",
                                    fontSize: "16px"
                                }} theme="snow" value={description} onChange={setDescription} />
                                {/* <Input err={descriptionErr} showError={showDescriptionErr} placeholder="Give more details or additional instructions" type='textarea' value={description} setValue={setDescription} containerClass="mt-[12px] bg-[#F0F0F4] border-graylight border-2 shadow-sm" /> */}
                            </div>
                            {
                                descriptionErr != ""
                                && <p className='text-red-700'>{descriptionErr}</p>
                            }
                            <br />
                            <div className="text-center flex justify-end w-full">
                                {
                                    isEdit() ?
                                        <div className="flex justify-center">
                                            <Button loading={loading} text="Save" onclick={() => proceed()} containerClass="w-[150px] bg-blue text-yellow mt-[30px]" />
                                            <div className="mx-2"></div>
                                            <Button loading={loading} text="Save and Exit" onclick={() => proceed("exit")} containerClass="w-[150px] bg-blue text-yellow mt-[30px]" />

                                        </div>
                                        :
                                        <Button loading={loading} text="Submit" onclick={() => proceed()} containerClass="w-[150px] bg-blue text-yellow mt-[30px]" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CreateEvent2