import React, { useState, useEffect } from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom'
import BlogCard from '../../components/admin/blog'
import { blog3, camera } from '../../components/images'
import { iPost } from '../../models/post'
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill'
import Input from '../../components/input'
import Button from '../../components/button'
import instance from '../../helpers/axios'
import { useTypedSelector } from '../../hooks/useSelector'
import { Loader } from 'rsuite'
import { ActionType } from '../../store/actions';
import { toast, ToastContainer } from 'react-toastify';
import { MdDelete } from 'react-icons/md';
import ConfirmModal from '../../components/brand/ask_modal';

export const links: iPost[] = [
    {
        title: "BLOG",
        image: blog3,
        slug: "/admin/blog"
    },
    {
        title: "FAQs",
        image: blog3,
        slug: "/admin/faqs"
    }
]

export const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        matchVisual: false,
    }
}
function AdminBlogView() {
    const params = useParams();
    const dispatch = useDispatch();
    const [value, setValue] = useState("")
    const [title, setTitle] = useState("")
    const { loggedInAdmin } = useTypedSelector((state:any) => state.appReducer);
    const [selectedUrl, setSelectedUrl] = useState('')
    const [fileLoading, setFileLoading] = useState(false)
    const [file, setFile] = useState<File>()
    const [fileSelected, setFileSelected] = useState<any>('')
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [blog, setBlog] = useState<any>(null)
    const [status, setStatus] = useState("draft")
    const navigate = useNavigate();
    const [isDelete, setIsDelete] = useState(false)
    const headers = {
        'Authorization': `Bearer ${loggedInAdmin}`,
    }

    useEffect(() => {
        if (!loggedInAdmin || loggedInAdmin == "") {
            // nobody is logged in
            navigate("/admin/login");
        }
    }, [loggedInAdmin])

    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "admin"
        })

    }, [])
    const unsetselected = () => {
        setFileSelected('')
    }

    useEffect(() => {
       fetchConstant()
    }, [])

    const fetchConstant = () =>{
        if(!params.id) return ;
        instance({
            method: 'get',
            headers : headers,
            url: `/blog/all?_id=${params.id}`
        }).then((response)=>{
            var b = response.data.data.docs[0]
            setTitle(b.title);
            setValue(b.content);
            setStatus(b.status);
            setBlog(response.data.data.docs)
            if(b.blogImage){
                setSelectedUrl(b.blogImage)
            }
            // console.log(response.data.data.docs)
        }).catch((err)=> {
            navigate("/admin/blog")
            console.log(err)
        }).finally(()=>{
        })
    }
    
    const changeStatus = (status = "draft") => {
        // setFile(e.target.files[0])
      
        setIsButtonLoading(true)
        var data = {
            // userId: userId,
            content: value,
            title: title,
            blogImage : selectedUrl,
            status : status
          }
          
        setTimeout(() => {
            instance({
                method: params.id ? "PUT" : 'POST',
                data: data,
                headers : headers,
                url: params.id ? `/blog/${params.id}` : '/blog/create'
            }).then((response)=>{
                if(params.id) {
                    toast.success("Blog updated successfully")
                    fetchConstant()
                }else{
                    toast.success("Blog added successfully")
                    navigate(`/admin/blog/update/${response.data.data._id}`)
                }
            }).catch((err)=> {
                try {
                    toast.error(err.response.data.message)
                } catch (error) {
                    toast.error("Something went wrong")
                }
            }).finally(()=>{
                setIsButtonLoading(false)
            })
        }, 100);
       
    }    
    const deleteThis = () => {
        // setFile(e.target.files[0])
      
        setTimeout(() => {
            instance({
                method: 'DELETE',
                data : {
                    property : "_id",
                    value : [params.id]
                },
                headers : headers,
                url: '/blog/delete'
            }).then((response)=>{
                setIsDelete(false)
                toast.success("Post deleted successfully")
                setTimeout(() => {
                    navigate("/admin/blog")
                }, 1000);
            }).catch((err)=> {
                try {
                    toast.error(err.response.data.message)
                } catch (error) {
                    toast.error("Something went wrong")
                }
            }).finally(()=>{
                setIsButtonLoading(false)
            })
        }, 100);
       
    }

    return (
        <>
            <div>
                <div className="max-w-[1445px] font-primary">
                    <ToastContainer />
                    {
                        isDelete ? 
                        <ConfirmModal
                        callback={()=>deleteThis()}
                            close={()=>setIsDelete(false)} title="Delete Post?" description='Are you sure you want to delete this post, this action cannot be revert' />
                        : ""
                    }
                    <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                        <div className="search gap-3 flex justify-end flex-col lg:flex-row items-center mb-4">
                            <Link to={'/admin/blog'} className='bg-yellow rounded-[45px] font-bold font-primary w-[50px] lg:w-[150px] text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]'> <div className="hidden lg:block mx-1"></div> <span className='hidden lg:flex'>Back</span> </Link >
                            <div className='flex gap-3'>
                                <Link to={'/admin/blog/update/'+params.id} className='bg-yellow rounded-[45px] font-bold font-primary w-[50px] lg:w-[150px] text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]'> <div className="hidden lg:block mx-1"></div> <span className='hidden lg:flex'>Update</span> </Link >
                                {
                                    status == "draft" ?
                                    <Link 
                                    onClick={()=>{
                                        changeStatus("published")
                                    }}
                                     to={'#?'} className='bg-blue rounded-[45px] font-bold text-yellow font-primary w-[50px] lg:w-[150px] text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]'> <div className="hidden lg:block mx-1"></div> <span className='hidden lg:flex'>Publish</span> </Link >
                                    :
                                    <Link onClick={()=>changeStatus()} to={'#?'} className='bg-blue rounded-[45px] font-bold text-yellow font-primary w-[50px] lg:w-[150px] text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]'> <div className="hidden lg:block mx-1"></div> <span className='hidden lg:flex'>Unpublish</span> </Link >
                                }
                            </div>
                        </div>
                        <div className="my-4 flex gap-3 items-center">
                            <h3 className="font-primaary font-bold text-lg text-blue">{params.id ? title : "Create Post" }</h3> 
                            <MdDelete style={{cursor : 'pointer'}} className=' text-red-700 text-[18px]' onClick={()=>setIsDelete(true)} />
                        </div>
                        <div className="bg-white p-3 rounded shadow-sm">
                        <div className="my-2 flex justify-end">
                        {
                            fileSelected === "" ?
                                <div className="h- relative">
                                    <label htmlFor='uploadDp' style={{ cursor: 'pointer' }} className="absolute rounded shadow-sm h-[150px] w-[150px] bg-[#00000099] flex justify-center items-center">
                                        <img src={camera} className="h-[24px] object-contain" alt="" />
                                    </label>
                                    <img src={selectedUrl === "" ? blog3 : selectedUrl} alt="" className='h-[150px] w-[150px] object-contain' />
                                </div>
                                :
                                (
                                    fileLoading ? <Loader /> :
                                <div className="h- relative">
                                    <img src={fileSelected} alt="" className='h-[150px] w-[150px] object-contain' />
                                    <div className="flex">
                                        <button onClick={unsetselected} className='btn p-2 rounded-[20px] bg-transparent broder-blue text-[12px] flex items-center justify-evenly' >Cancel</button> <div className="mx-2"></div>
                                    </div>
                                </div>
                                )
                        }

                    </div>

                            {/* <div className="my-2">
                                <label className='font-primary text-blue' htmlFor="">Short Description*</label>
                                <Input err={""} type="text" showError={false} value={title} setValue={setTitle} containerClass="h-[50px] bg-[#F0F0F4] border-graylight shadow-sm border-2" placeholder='Blog title' />
                            </div> */}

                            <div className="my-5 max-w-[700px] mx-auto">
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: value }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminBlogView