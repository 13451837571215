import React, {useState, useEffect, useRef} from 'react'
import { AiOutlineShareAlt } from 'react-icons/ai'
import { BiShare } from 'react-icons/bi'
import { toast, ToastContainer } from 'react-toastify';
import instance from '../../helpers/axios';
import ButtonLoader from '../../helpers/button_loader';
import { useTypedSelector } from '../../hooks/useSelector';
import { facebookSVG, instagramSVG, linkedinSVG, telegramSVG, twitterSVG, whatsappSVG } from '../images'
import Input from '../input';
import ConfirmModal from './ask_modal';


interface iConfirm {
    title : string;
    description? : string;
    close : any;
    callback : any;
    videoUrl : string
}

function PostToIGNow(data : iConfirm) {
    const [isLoading, setIsLoading] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [description, setDescription] = useState("")
    return (
        <div>
            <div className='fixed top-0 left-0 w-full z-[999] h-[100vh]'>
                <div className="max-h-[300px] min-h-[100px] w-full flex justify-center">
                    <div onClick={()=>data.close()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px] w-[95%] lg:w-[360px] p-3 relative'>
                        <div className="mt-[10px] mb-[15px] px-3 font-primary ">
                            <div className="" id="">
                                <h3 className="text-blue text-center text-uppercase text-[20px] font-bold mb-3">{data.title}</h3>
                                {
                                    showConfirm 
                                    &&
                                    <ConfirmModal callback={()=> {
                                        setShowConfirm(false)
                                        data.callback(description)
                                    }}
                                    close={()=> setShowConfirm(false)}
                                    title='Post on Instagram'
                                    description='Are you sure you want to post this on IG?'
                                     />

                                }
                                <div className="w-full">
                                    <label>Caption <small>[optional]</small></label>
                                    <Input type='textarea' textareaH='100px' placeholder='Caption here'  value={description} setValue={setDescription} />
                                </div>
                                   
                                <div className="mt-3 flex items-center justify-content-between">
                                    <button type="button" onClick={()=>setShowConfirm(true)} className="btn bg-blue text-white close-swl">Post</button>
                                    <button type="button" onClick={()=>data.close()} className="btn bg-red-700 text-white ml-10 close-swl">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default PostToIGNow;