import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { isEdit, isAdmin } from '../../helpers/helpers'
import Steps from '../signup/steps'
interface iCSteps {
  current: number,
  completed?: any[],
  challenge?: string,
}
function ChallengeSteps({ current, completed = [], challenge }: iCSteps) {
  const params = useParams()
  return (
    <>
      {
        isAdmin()
          ?
          <div className="steps flex justify-center">
            {
              current > 1 ?
                <Link to={`/admin/${isEdit() ? 'edit-challenge' : 'create-challenge'}/${params.brandid}?challenge=${challenge}`}>
                  <Steps isCompleted={completed.includes(1)} title="Title" s={1} iscurrent={current === 1 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={false} />
                </Link>
                :
                <Steps isCompleted={completed.includes(1)} title="Title" s={1} iscurrent={current === 1 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={false} />
            }

            {
              current > 2 ?
              <Link to={`/admin/${isEdit() ? 'edit-challenge' : 'create-challenge'}/${params.brandid}/${ isEdit() ? "edit-step-2" : "step-2"}?challenge=${challenge}`}>
                  <Steps isCompleted={completed.includes(2)} titleClass='left-[-10px]' title="Description" s={2} iscurrent={current === 2 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={false} />
                </Link>
                :
                <Steps isCompleted={completed.includes(2)} titleClass='left-[-10px]' title="Description" s={2} iscurrent={current === 2 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={false} />
            }
            {
              current > 3 ?
              <Link to={`/admin/${isEdit() ? 'edit-challenge' : 'create-challenge'}/${params.brandid}/${ isEdit() ? "edit-step-3" : "step-3"}?challenge=${challenge}`}>
                  <Steps isCompleted={completed.includes(3)} title="Campaign Guidelines" s={3} iscurrent={current === 3 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={false} />
                </Link>
                :
                <Steps isCompleted={completed.includes(3)} title="Campaign Guidelines" s={3} iscurrent={current === 3 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={isEdit() ? true : false} />
            }
            {
              !isEdit() &&
              <Steps isCompleted={completed.includes(4)} title="Checkout" s={4} iscurrent={current === 4 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={true} />
            }
            {/* <Steps isCompleted={completed.includes(5)} titleClass='min-w-[60px]' title="All done" s={5} iscurrent={current === 5 ? true : false } className='bg-[#E0DFE8] shadow-sm' islast={true} /> */}
          </div>
          :
          <div className="steps flex justify-center">
            {
              current > 1 ?
                <Link to={`/brand/challenge/${isEdit() ? 'edit' : 'create'}?challenge=${challenge}`}>
                  <Steps isCompleted={completed.includes(1)} title="Title" s={1} iscurrent={current === 1 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={false} />
                </Link>
                :
                <Steps isCompleted={completed.includes(1)} title="Title" s={1} iscurrent={current === 1 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={false} />
            }

            {
              current > 2 ?
                <Link to={`/brand/challenge/${isEdit() ? 'edit' : 'create'}-step-2?challenge=${challenge}`}>
                  <Steps isCompleted={completed.includes(2)} titleClass='left-[-10px]' title="Description" s={2} iscurrent={current === 2 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={false} />
                </Link>
                :
                <Steps isCompleted={completed.includes(2)} titleClass='left-[-10px]' title="Description" s={2} iscurrent={current === 2 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={false} />
            }
            {
              current > 3 ?
                <Link to={`/brand/challenge/${isEdit() ? 'edit' : 'create'}-step-3?challenge=${challenge}`}>
                  <Steps isCompleted={completed.includes(3)} title="Campaign Guidelines" s={3} iscurrent={current === 3 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={false} />
                </Link>
                :
                <Steps isCompleted={completed.includes(3)} title="Campaign Guidelines" s={3} iscurrent={current === 3 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={isEdit() ? true : false} />
            }
            {
              !isEdit() &&
              <Steps isCompleted={completed.includes(4)} title="Checkout" s={4} iscurrent={current === 4 ? true : false} className='bg-[#E0DFE8] shadow-sm' islast={true} />
            }
            {/* <Steps isCompleted={completed.includes(5)} titleClass='min-w-[60px]' title="All done" s={5} iscurrent={current === 5 ? true : false } className='bg-[#E0DFE8] shadow-sm' islast={true} /> */}
          </div>
      }
    </>
  )
}

export default ChallengeSteps